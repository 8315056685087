import { DataGrid, DataGridProps } from '@mui/x-data-grid';
import NoDataFound from '../NoDataFound';
import Pagination from '@mui/material/Pagination';
import React from 'react';

function CustomPagination({ onCustomPaginationChange, curPage, unlimited, ...props }) {
  return (
    <Pagination
      onChange={(e, p) => {
        onCustomPaginationChange && onCustomPaginationChange('', p);
      }}
      page={curPage}
      count={Math.ceil(props.rowCount / props.pageSize)}
      size={'large'}
      sx={{
        '& .MuiButtonBase-root': {
          color: '#8088A0',
        },
        '& .Mui-selected': {
          backgroundColor: 'transparent',
          color: props.variant === 'bright' ? 'black' : 'white',
          fontWeight: 'bold',
        },
      }}
    />
  );
}

interface Props extends DataGridProps {
  unlimited?: boolean;
  onCustomPaginationChange?: () => void;
  checkbox?: boolean;
  curPage?: number;
  leftBtn?: React.ReactNode;
  rightBtn?: React.ReactNode;
  variant?: string;
  styles?: React.CSSProperties;
}

const Table = ({ columns, rows, checkbox, onCustomPaginationChange, curPage, unlimited, ...props }: Props) => {
  let newColumns = [...columns];

  function CustomFooterComponent() {
    const containerStyle = {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 20,
    };

    if (unlimited !== true) {
      return (
        <div style={containerStyle}>
          <div>{props.leftBtn && props.leftBtn}</div>
          <div>{CustomPagination({ ...props, onCustomPaginationChange, curPage, unlimited })}</div>
          <div>{props.rightBtn && props.rightBtn}</div>
        </div>
      );
    } else {
      return null;
    }
  }

  return (
    // <>
    <DataGrid
      logLevel={'error'}
      columns={newColumns}
      rows={rows}
      style={{
        color: props.variant === 'bright' ? 'black' : 'white',
        height: '100%',
        border: 0,
        ...props.styles,
      }}
      pageSize={unlimited ? undefined : 10}
      checkboxSelection={checkbox && true}
      rowHeight={70}
      disableColumnMenu
      hideFooterSelectedRowCount
      hideFooterPagination
      components={{
        NoRowsOverlay: () => <NoDataFound variant={props.variant} />,
        NoResultsOverlay: () => <NoDataFound />,
        Footer: CustomFooterComponent,
      }}
      {...props}
      sx={{
        '& .MuiDataGrid-row:hover': {
          cursor: 'pointer',
          background: props.variant === 'bright' ? '#ddd !important' : 'linear-gradient(90.12deg, rgb(114 125 155) 0.02%, rgb(53 65 84) 79.25%) !important',
        },
        '& .MuiDataGrid-cell': {
          border: 0,
        },
        '& .MuiDataGrid-footerContainer': {
          border: 0,
        },
        '& .MuiDataGrid-columnHeaders': {
          border: 0,
        },
        '& .MuiSvgIcon-root': {
          color: 'white',
        },
        '& .MuiDataGrid-columnSeparator': {
          visibility: 'hidden',
        },
        '& .MuiDataGrid-cell:focus-within': {
          outline: 'none',
        },
        ...props.sx,
      }}
    />
    // </>
  );
};

export default Table;
