import React, { createRef } from 'react';
import { api_tta } from '../api/api_tta';
import AlertMessage from '../components/utilities/AlertMessage';
import LoadingComponent from '../components/utilities/LoadingComponent';
import { StorageUtils } from './StorageUtils';
import { WSC } from '../service/WSC';

export default class APIUtils extends React.Component {
  refLoadingComponent = createRef();

  constructor() {
    super();
  }

  logout = () => {
    // 브라우저에 저장된 JWT 토큰 삭제
    StorageUtils.removeAuthorization();
    // API 인스턴스에 저장된 Authorization 삭제
    api_tta.deleteHeader('Authorization');
    // Web Socket Client 연결 끊기
    WSC.getInstance().stop(true);
    try {
      typeof this.sessionStore?.reset === 'function' && this.sessionStore?.reset();
    } catch (e) {}
    window.location.href = '/sign-in';
  };

  fetchApi = (url, reqType, params): any => {
    this.refLoadingComponent?.current?.changeStatus(true);
    return new Promise((resolve, reject) => {
      api_tta[reqType](url, params && params?.formData ? params.formData : { ...params })
        .then((r) => {
          if (!r.ok) {
            if (r.data?.code === 'RESOURCE_NOT_FOUND') {
              AlertMessage.alertMessage('error', '이미 삭제 처리된 데이터입니다. 새로고침 해주세요');
              return reject('server error', r);
            }

            if (r.status === 401) {
              this.logout();
            }
            const message = r?.data?.code?.toString()?.includes?.('PASSWORD_MISMATCH') ? '현재 비밀번호가 일치하지 않습니다' : r?.data?.code?.toString()?.includes?.('SNAPSHOT_MAX_LIMIT_REACHED') ? <div>스냅샷을 100개 초과하여 보관할 수 없습니다.<div style={{margin: "3px 0"}}>사용하지 않는 스냅샷을 삭제 후</div>다시 시도해주세요.</div> : r?.data?.code || r?.data?.message || r?.problem;
            AlertMessage.alertMessage('error', message);
            return reject('server error', r);
          }
          resolve(r.data);
        })
        .catch((err) => {
          console.log('❌[catch err]\n', err);
          AlertMessage.alertMessage('error', '[CATCH ERR]\n' + err);
          return reject('catch err', err);
        })
        .finally(() => {
          this.refLoadingComponent?.current?.changeStatus(false);
        });
    });
  };

  renderLoading = (withBg) => {
    return <LoadingComponent ref={this.refLoadingComponent} withBg={withBg || false} />;
  };
}

export const fetchApi = (url, reqType, params) => {
  return new Promise((resolve, reject) => {
    api_tta[reqType](url, params && params?.formData ? params.formData : { ...params })
      .then((r) => {
        if (!r.ok) {
          if (r.data.code === 'RESOURCE_NOT_FOUND') {
            AlertMessage.alertMessage('error', '이미 삭제 처리된 데이터입니다. 새로고침 해주세요');
            return reject('server error', r);
          }
          console.log('❌[server response error]\n', r);
          const error = r?.data?.code?.includes?.('CLIENT_ERROR') ? '알 수 없는 오류입니다' : null;
          AlertMessage.alertMessage('error', r.status == '403' ? '권한이 없습니다' : error || r?.data?.message || r?.data?.code || r?.problem);
          return reject('server error', r);
        }
        resolve(r.data);
      })
      .catch((err) => {
        console.log('❌[catch err]\n', err);
        AlertMessage.alertMessage('error', '[CATCH ERR]\n' + err);
        return reject('catch err', err);
      });
  });
};
