import { useEffect, useState } from 'react';
import '../css/myPage/userManage.css';
import UserDetailModal from '../components/userManage/modals/UserDetailModal';
import HeaderComponent from '../components/groupManagement/HeaderComponent';
import RegiUserModal from '../components/userManage/modals/RegiUserModal';
import AlertMessage from '../components/utilities/AlertMessage';
import { accAccessOptions } from '../constants/SelectOptions';
import CommonHeader from '../components/HeaderComponent';
import APIUtils, { fetchApi } from '../utils/APIUtils';
import Container from '../components/Container';
import SelectBox from '../components/SelectBox';
import useSelect from '../hooks/useSelect';
import useInput from '../hooks/useInput';
import Table from '../components/Table/Table';
import Input from '../components/Input';
import { UserManageColumns } from '../components/Table/TableColumns';
import DeleteBtn from '../components/Table/DeleteBtn/DeleteBtn';
import RightBtn from '../components/Table/RightBtn/RightBtn';

const apiUtils = new APIUtils();
const PAGE_LIMIT = 6;

const UserManage = () => {
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [regiOpen, setRegiOpen] = useState(false);

  const [userDetailOpen, setUserDetailOpen] = useState(false);

  const [selectedRowData, setSelectedRowData] = useState({});

  const [rows, setRows] = useState([]);

  const [totalCount, setTotalCount] = useState(0);
  const [curPage, setCurPage] = useState(1);

  const searchInput = useInput('');
  const userTypeSelection = useSelect([]);
  const departmentSelection = useSelect([]);

  const [checkedRows, setCheckedRows] = useState([]);

  const [permissions, setPermissions] = useState([]);
  const [isPermissionFetched, setIsPermissionFetched] = useState(false);

  const handleRowDoubleClick = (params) => {
    setSelectedRowData(params.row);
    setUserDetailOpen(true);
  };

  const handleDeleteBtn = async () => {
    if (checkedRows.length < 1) return AlertMessage.alertMessage('error', '삭제하실 사용자를 선택해 주세요');

    if (checkedRows.length === 1) {
      const result = await AlertMessage.confirmMessage(`${checkedRows[0].username}(${checkedRows[0].profile?.name})을 삭제하시겠습니까? \n 삭제한 후에는 되돌릴 수 없습니다.`);
      if (!result) return;
      return checkedRows.map((e, idx) => {
        return fetchApi(`/v1/me/organization/user/${e.uuid}`, `delete`).then(() => {
          if (checkedRows.length - 1 === idx) {
            AlertMessage.alertMessage('success', '삭제가 성공적으로 완료되었습니다');
            fetchTable();
          }
        });
      });
    }

    const result = await AlertMessage.confirmMessage(`${checkedRows[0].username}(${checkedRows[0].profile?.name}) 외 ${checkedRows.length - 1}명을 삭제하시겠습니까? \n 삭제한 후에는 되돌릴 수 없습니다.`);
    if (!result) return;

    checkedRows.map((e, idx) => {
      return fetchApi(`/v1/me/organization/user/${e.uuid}`, `delete`).then(() => {
        if (checkedRows.length - 1 === idx) {
          AlertMessage.alertMessage('success', '삭제가 성공적으로 완료되었습니다');
          fetchTable();
        }
      });
    });
  };

  const handleRegiBtn = () => {
    setRegiOpen(true);
  };

  const rightBtn = () => {
    return <RightBtn onClick={handleRegiBtn}>사용자 추가</RightBtn>;
  };

  const fetchTable = (e = null, p = curPage) => {
    setCurPage(p);
    apiUtils
      .fetchApi('/v1/me/organization/user', 'get', {
        limit: PAGE_LIMIT,
        offset: p ? (p - 1) * PAGE_LIMIT : 0,
        ob: 'id',
        od: 'DESC',
        groupUuid: departmentSelection.value,
        type: userTypeSelection.value,
        q: searchInput.value,
        permissions: permissions.map((p) => p.organizationServiceId + '_' + p.type).join(','),
      })
      .then((r) => {
        setRows(r.rows);
        setTotalCount(r.count);
      });
  };

  useEffect(() => {
    fetchTable();
    setCurPage(1);
  }, [searchInput.value, userTypeSelection.value, departmentSelection.value, permissions]);

  return (
    <Container>
      <CommonHeader pageTitle={''} />
      <div className='user-container'>
        <div className='content'>
          <div className='right'>
            <HeaderComponent page={'user'} />
            <div className='search-title'>사용자 조회</div>
            <div className='search-container'>
              <div style={{ display: 'flex' }}>
                <Input
                  value={searchInput.value}
                  onChange={(e) => {
                    if (e.target.value.length > 30) {
                      return AlertMessage.alertMessage('error', '최대 30자까지 입력할 수 있습니다.');
                    }
                    searchInput.onChange(e);
                  }}
                  variant={'primary'}
                  width={300}
                  placeholder={'아이디 검색'}
                />
              </div>
            </div>
            <div style={{ width: '100%', height: '75%', position: 'relative' }}>
              <Table
                columns={UserManageColumns}
                rows={rows}
                checkbox
                leftBtn={DeleteBtn(handleDeleteBtn)}
                rightBtn={rightBtn()}
                onRowClick={handleRowDoubleClick}
                pageSize={PAGE_LIMIT}
                rowCount={totalCount}
                curPage={curPage}
                onCustomPaginationChange={fetchTable}
                disableSelectionOnClick
                onCellKeyDown={(params, event) => {
                  if (event.code === 'Enter') {
                    handleRowDoubleClick(params);
                  }
                }}
                componentsProps={{
                  row: {
                    style: {
                      background: 'linear-gradient(90.12deg, #313644 0.02%, #1E2530 79.25%)',
                      fontWeight: 'bold',
                      borderRadius: 10,
                      marginBottom: 10,
                    },
                  },
                }}
                onSelectionModelChange={(ids) => {
                  const selectedIDs = new Set(ids);
                  const selectedRows = rows.filter((row) => selectedIDs.has(row.id));
                  setCheckedRows(selectedRows);
                }}
              />
              {apiUtils.renderLoading()}
            </div>
          </div>
        </div>
        {/* 등록 - C */}
        <RegiUserModal open={regiOpen} setOpen={setRegiOpen} rowInfo={selectedRowData} fetchTable={fetchTable} />
        {/* 조회 - R */}
        <UserDetailModal open={userDetailOpen} setOpen={setUserDetailOpen} isDeleteOpen={setIsDeleteOpen} rowInfo={rows?.find((row) => row.uuid === selectedRowData?.uuid)} fetchTable={fetchTable} setIsPermissionFetched={setIsPermissionFetched} />
      </div>
    </Container>
  );
};

export default UserManage;
