import {WSSEvent} from "anuro-platform-core";
import ObjectUtils from "../../utils/ObjectUtils";

export enum EXECUTE_TYPE {
  ADD, MOD, DEL, IN_PROGRESS, TERMINATED
}

export default class HandlingSocketData {
  static update = (type: EXECUTE_TYPE, from: object[], to: object, key: string): object[] | null => {
    if (type === EXECUTE_TYPE.ADD) {
      return this.add(from ,to, key);
    } else if (type === EXECUTE_TYPE.MOD || type === EXECUTE_TYPE.TERMINATED) {
      return this.mod(from, to, key);
    } else if (type === EXECUTE_TYPE.DEL) {
      return this.del(from, to, key);
    } else {
      return this.mod(from, to, key);
    }
    return null;
  }

  private static add = (from: object[], to: object, key: string) => {
    let tempArr = [...from];
    tempArr.push(to);
    tempArr.sort((a: any, b: any) => ObjectUtils.getObjectByString(b, key) - ObjectUtils.getObjectByString(a, key));
    return tempArr;
  }

  private static mod = (from: object[], to: any, key: any) => {
    let tempEventArr = [...from];

    let idx = tempEventArr.findIndex((i: any) => {
      return  ObjectUtils.getObjectByString(i, key) === ObjectUtils.getObjectByString(to, key)
    });

    console.log(idx);

    if(idx !== -1) {
      tempEventArr[idx] = to;
    };
    return tempEventArr;
  }

  private static del = (from: object[], to: any, key: string) => {
    let tempEventArr = [...from];
    let idx = tempEventArr.findIndex((i: any) => ObjectUtils.getObjectByString(i, key) === ObjectUtils.getObjectByString(to, key));
    if(idx === -1) return null;
    tempEventArr.splice(idx, 1);
    return tempEventArr;
  }
}

