import React from 'react';
import {SETTING_PANEL, SETTING_TAB} from './SettingModal';
import { IAuthenticationLog } from 'anuro-platform-core/src/models/DbModels';
import APIUtils from '../../utils/APIUtils';
import moment from 'moment';
import { Tooltip } from '@mui/material';

type Props = {
  handleData: Function;
};

type States = {
  loginHistory: IAuthenticationLog[];
};

export default class LoginHistoryPanelComponent extends React.Component<Props, States> {
  apiUtils = new APIUtils();

  state = {
    loginHistory: [],
  };

  componentDidMount() {
    this.fetchLoginHistory();
  }

  // !!! 로그인 기록 호출
  fetchLoginHistory = () => {
    this.apiUtils.fetchApi('/v1/me/authentication-log', 'get', { ob: 'id', od: 'DESC', limit: 100 }).then((r) => {
      this.setState({ loginHistory: r.rows });
    });
  };

  // !!! 로그인 아이템 렌더링
  renderLoginHistoryItem = () => {
    const { loginHistory } = this.state;
    return loginHistory?.map((i: IAuthenticationLog, idx: number) => {
      return (
        <div
          key={i.id}
          style={{
            width: 'calc(100% - 4px)',
            padding: '14px 18px',
            borderRadius: 10,
            boxShadow: '0px 1px 4px rgb(0 0 0 / 35%)',
            backgroundColor: "#FFFFFF",
            margin: `${idx === 0 ? "2px" : "20px"} 2px ${idx === loginHistory.length - 1 ? "2px" : "20px"} 2px`,
          }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: "center" }}>
            <div>
              <Tooltip title={`접속 IP`} placement={'top'} arrow>
                <span>{i.ip.substring(i.ip.lastIndexOf(":") + 1, i.ip.length)}</span>
              </Tooltip>
              <Tooltip title={i.status === "SUCCESS" ? "성공" : i.status === "FAILED" ? "실패" : ""} placement={'top'} arrow>
                <span style={{width: 10, height: 10, marginLeft: 8, display: "inline-block", borderRadius: "50%", backgroundColor: i.status === "SUCCESS" ? "#07bc0c" : i.status === "FAILED" ? "#e74c3c" : "#333"}} />
              </Tooltip>
            </div>
            <div>
              <Tooltip title={'접속 환경'} placement={'top'} arrow>
                <span>{i.userAgent}</span>
              </Tooltip>
            </div>
          </div>
          <div
            style={{
              textAlign: 'right',
              color: '#AAA',
              fontSize: 12,
              marginTop: 3,
            }}>
            <Tooltip title={'접속일'} placement={'bottom'} arrow>
              <span>{i.createdAt ? moment(i.createdAt).format('YY년 MM월 DD일 HH시 mm분 ss초') : ''}</span>
            </Tooltip>
          </div>
        </div>
      );
    });
  };

  goBack = () => this.props.handleData(SETTING_PANEL.PROFILE, SETTING_TAB.PROFILE);

  render() {
    return (
      <div className={'right'} style={{ position: 'relative' }}>
        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 20, justifyContent: 'space-between', alignItems: 'center' }}>
          <div className={'cursor'} onClick={this.goBack}>
            <span style={{ marginRight: 10 }}>&#60;</span>
            로그인 기록
          </div>
        </div>
        <div
          style={{
            position: 'relative',
            height: 'calc(100% - 46px)',
            overflowY: 'auto',
          }}>
          {this.renderLoginHistoryItem()}
        </div>
        <div style={{fontSize: 12, color: "#AAAAAA", textAlign: "right", marginTop: 5}}>최대 100개의 기록만 보여집니다</div>
        {this.apiUtils.renderLoading()}
      </div>
    );
  }
}
