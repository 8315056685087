import React, {createRef, ReactEventHandler} from "react";
import CCTVStore from "../../../stores/CCTVStore";
import {inject} from "mobx-react";
import EventEmitter from "../../../utils/EventEmitter";
import { ReactComponent as VIDEO_OFF } from '../../../icons/video-off.svg';
import "./cctvItem.css";
import {loadPlayer, Player} from "rtsp-relay/browser";
import {api_tta} from "../../../api/api_tta";
import {IFacility} from "../../../models/IFacility";
import ReplayIcon from '@mui/icons-material/Replay';

interface Props {
  item: IFacility;
  cctvStore?: CCTVStore;
  handleModal: (isOpen: boolean, item: IFacility) => void;
  showCctvName?: boolean;
  clickable?: boolean;
  setIsStreaming?: (isStreaming: boolean) => void;
};
interface States {
  isStreaming: boolean;
  notWorking: boolean;
};

@inject("cctvStore")
export default class CctvItem extends React.Component<Props, States> {
  state: States = {
    isStreaming: false,
    notWorking: false,
  }

  cctvCanvasRef = createRef<HTMLCanvasElement>();
  emitter: EventEmitter | null = EventEmitter.getInstance();

  player2: Player | null = null;
  componentDidMount() {
    const {item} = this.props;
    if(!item.active) return ;
    loadPlayer({
      url: `${api_tta.getBaseURL().replace('http', 'ws')}/stream/${item?.facilityCctv?.id}?stream=${item?.facilityCctv?.rtspPath}`,
      canvas: this.cctvCanvasRef!.current!,
      preserveDrawingBuffer: true,
      disableWebAssembly: false,
      disconnectThreshold: 5000,
      // optional
      onPlay: (player: Player) => {
      },
      onDisconnect: () => {
        // this.player2?.destroy?.();
      },
    }).then(r => {
      this.player2 = r;
      this.setState({isStreaming: true});
      this.props.setIsStreaming && this.props.setIsStreaming(true);
    });
  }

  componentWillUnmount() {
    this.player2?.destroy?.();
  }

  renderNotAvailableLayout = (): React.ReactElement | null => {
    if(this.state.isStreaming) return null;

    const {item} = this.props;
    return (
      <div className={"disabled-cctv-overlay"}>
        <VIDEO_OFF />
        <p className={"not-active-text"}>
          {
            item?.active ?
              <>스트림 없음<br />No stream available</>
              : <>해당 CCTV는 현재 비활성 상태입니다</>
          }
        </p>
      </div>
    )
  }

  render() {
    const {item, showCctvName = true, clickable = true} = this.props;
    return (
      <div
        key={item?.uuid}
        className={'view-item cctv-item-container'}
        style={{cursor: clickable ? "pointer" : "default"}}
        onClick={() => clickable && this.props.handleModal(true, item)}
      >
        <div className={"cctv-overlay"}>
          {
            showCctvName &&
            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
              <div className={"cctv-name"}>
                <span style={{ marginRight: 3, color: item?.active ? (this.state.isStreaming ? '#78D700' : '#999999') : '#f65440'}}>
                  ●
                </span>
                {item?.name}
              </div>
            </div>
          }
        </div>
        <div style={{ width: '100%', height: '100%' }}>
          {
            !this.state.notWorking &&
            <canvas
              key={`panel-cctv-screen-${item.uuid}`}
              id={`panel-cctv-screen-${item.uuid}`}
              ref={this.cctvCanvasRef}
              className={!this.state.isStreaming ? 'cctv-canvas-display-none' : 'cctv-canvas-display-block'}
              style={{ objectFit: 'fill', width: '100%', height: '100%' }}
            />
          }

          {this.renderNotAvailableLayout()}
        </div>
      </div>
    );
  }
}
