import React, { useState } from 'react';
import '../../../css/groupManagement/user/userInfoEditModal.css';
import CompleteAndCloseModal from '../../CompleteAndCloseModal';
import AlertMessage from '../../utilities/AlertMessage';
import { FormProvider, useForm } from 'react-hook-form';
import APIUtils, { fetchApi } from '../../../utils/APIUtils';
import { Validator } from 'anuro-platform-core';
import BaseModal from '../../BaseModal';
import Divider from '../../Divider';
import Button from '../../Button';
import Input from '../../Input';
import CircularProgress from '@mui/material/CircularProgress';
import { api_tta } from '../../../api/api_tta';
import { ValidationText } from '../../../utils/ValidationText';
import PasswordPolicyComponent from '../../settings/PasswordPolicyComponent';
import StringUtils from '../../../utils/StringUtils';

const apiUtils = new APIUtils();

const RegiUserModal = ({ open, setOpen, fetchTable }) => {
  const refPasswordPolicy = React.useRef(null);
  const { register, watch, setValue, handleSubmit, reset, control, getValues, formState, ...rest } = useForm({
    defaultValues: {
      type: 'NORMAL',
    },
  });
  const password = watch('password');
  const [closeOpen, setCloseOpen] = useState(false);

  const [isUniqueId, setIsUniqueId] = useState(false);
  const [verifiedId, setVerifiedId] = useState('');
  const [loading, setLoading] = useState(false);
  const [pwd, setPwd] = useState('');

  const watchAllFields = watch();

  const onSubmit = handleSubmit(
    (data) => {
      if (loading) return;

      const permissions = [];
      for (const [key, value] of Object.entries(data)) {
        if (key.includes('_permission_')) {
          const [organizationServiceId, type] = key.split('_permission_');
          value &&
            permissions.push({
              organizationServiceId,
              type,
            });
        }
      }

      const containsNonNumeric = /[a-zA-Zㄱ-ㅎ가-힣]/.test(data.phoneNum);
      const containsSpecialCharacters = /[^0-9-]/.test(data.phoneNum);
      const sanitizedValue = data.phoneNum.replace(/[^0-9]/g, '');
      const isNumericLengthValid = sanitizedValue.length >= 8;

      // 이름 예외처리
      if (data.name.length === 0) {
        return AlertMessage.alertMessage('error', '사용자 이름을 입력해주세요');
      } else if (data.name.length >= 256) {
        return AlertMessage.alertMessage('error', '사용자 이름은 최대 255자입니다');
      }

      // 연락처 예외처리
      if (data.phoneNum === undefined || data.phoneNum === '') {
        return AlertMessage.alertMessage('error', '전화번호를 입력해주세요');
      } else if (containsNonNumeric) {
        return AlertMessage.alertMessage('error', '전화번호에 한글 또는 영문은 입력하실 수 없습니다');
      } else if (containsSpecialCharacters) {
        return AlertMessage.alertMessage('error', '- 를 제외한 특수문자는 들어갈 수 없습니다');
      } else if (!isNumericLengthValid) {
        return AlertMessage.alertMessage('error', '유효한 전화번호 포멧이 아닙니다');
      }

      // 아이디 예외처리
      if (data.userId.length === 0) {
        return AlertMessage.alertMessage('error', '아이디를 입력해주세요');
      } else if (data.userId.length > 31) {
        return AlertMessage.alertMessage('error', '아이디는 최대 30자 입니다');
      } else if (data.userId !== verifiedId) {
        return AlertMessage.alertMessage('error', '중복체크를 해주세요');
      }

      // 비밀번호 예외처리
      if (!refPasswordPolicy.current?.isAvailable) {
        const validateResult = Validator.validatePassword(pwd);
        if (validateResult.errors.length > 0) {
          const message = refPasswordPolicy.current?.getValidationText?.(validateResult.errors[0]) || validateResult.errors[0].toString();
          AlertMessage.alertMessage('error', message.includes('공백불가') ? '공백을 입력할 수 없습니다.' : `비밀번호는 ${message} 입력해야합니다`);
        } else {
          AlertMessage.alertMessage('error', '새로운 비밀번호가 올바르지 않습니다');
        }
        return;
      }

      setLoading(true);

      isUniqueId && verifiedId === data.userId
        ? fetchApi(`/v1/me/organization/user`, `post`, {
            groupUuid: data.groupUuid?.value,
            name: data.name,
            username: data.userId,
            password: data.password,
            phoneNumber: sanitizedValue,
            permissions: permissions,
            type: data.type,
          })
            .then((res) => {
              AlertMessage.alertMessage('success', '사용자 추가가 완료되었습니다');
              setOpen(false);
              fetchTable();
              reset();
            })
            .finally(() => {
              setLoading(false);
            })
            .catch((err) => AlertMessage.alertMessage('error', '알 수 없는 에러'))
        : AlertMessage.alertMessage('error', '중복체크를 해주세요');
    },
    (errors) => {
      console.log(errors);
    },
  );

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const checkDuplication = () => {
    const userId = getValues('userId');
    const isNameNotContainSpecialCharacters = /^[a-zA-Z0-9]*$/.test(userId);
    if (userId.length === 0) return AlertMessage.alertMessage('error', '아이디를 입력해주세요');
    if (!isNameNotContainSpecialCharacters) {
      return AlertMessage.alertMessage('error', '아이디에 특수문자는 들어갈 수 없습니다');
    }
    if (userId.toLowerCase().includes('system') || userId.toLowerCase().includes('admin') || userId.toLowerCase().includes('administrator') || userId.toLowerCase().includes('administration')) {
      return AlertMessage.alertMessage('error', '예약어는 들어갈 수 없습니다');
    }
    if (loading) return;
    setLoading(true);

    api_tta
      .post(`/v1/me/organization/user/check`, { username: userId })
      .then((res) => {
        if (res.ok) {
          setIsUniqueId(true);
          setVerifiedId(userId);
          AlertMessage.alertMessage('success', '사용 가능한 아이디입니다');
        } else if (res.status === 409) {
          AlertMessage.alertMessage('error', '중복된 아이디입니다');
        } else {
          AlertMessage.alertMessage('error', ValidationText?.[res?.data?.errors?.[0]] || res?.data?.errors?.[0] || res?.data?.message || '알 수 없는 오류 입니다.');
        }
      })
      .finally(() => {
        new Promise((res) => {
          setTimeout(() => res(), 500);
        }).then(() => setLoading(false));
      });
  };

  return (
    <BaseModal title={'사용자 추가'} width={630} height={420} open={open} leftBtnLabel={'취소'} rightBtnLabel={'추가'} closeIcon handleClose={handleClose} handleLeftBtn={handleClose} handleRightBtn={onSubmit}>
      <Divider marginBottom={20} color={'#999999'} />
      <form onSubmit={onSubmit}>
        <div className='user-edit-container'>
          <div style={{ width: '100%', position: 'relative' }}>
            <FormProvider getValues={getValues} register={register} handleSubmit={handleSubmit} reset={reset} {...rest}>
              {apiUtils.renderLoading()}
              <Input
                width={'100%'}
                name={'name'}
                {...register('name')}
                label={'이름*'}
                placeholder={'이름을 입력해주세요'}
                onChange={(e) => {
                  if (e.target.value.length > 255) {
                    AlertMessage.alertMessage('error', '사용자 이름은 최대 255자입니다');
                    setValue('name', e.target.value.slice(0, 255));
                  }
                }}
              />
              <Input
                width={'100%'}
                name={'phoneNum'}
                {...register('phoneNum', {
                  onChange: (event) => {
                    const checkIsKoreanIncluded = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
                    const checkIsEngIncluded = /[a-zA-Z]/;
                    const checkSpecialCharIncluded = /[!@#$%^&*()_+=\[\]{};':"\\|,.<>\/?]+/;
                    if (checkIsEngIncluded.test(event.target.value)) {
                      setValue('phoneNum', event.target.value.slice(0, event.target.value.length - 1));
                      return AlertMessage.alertMessage('error', '전화번호에 한글 또는 영문은 입력하실 수 없습니다.');
                    }
                    if (checkIsKoreanIncluded.test(event.target.value)) {
                      setValue('phoneNum', event.target.value.slice(0, event.target.value.length - 1));
                      return AlertMessage.alertMessage('error', '전화번호에 한글 또는 영문은 입력하실 수 없습니다.');
                    }
                    if (checkSpecialCharIncluded.test(event.target.value)) {
                      setValue('phoneNum', event.target.value.slice(0, event.target.value.length - 1));
                      return AlertMessage.alertMessage('error', '-를 제외한 특수문자는 들어갈 수 없습니다.');
                    }

                    if (event.target.value.length > 13) AlertMessage.alertMessage('error', '전화번호는 -(대시)를 포함하여 13자리까지 입력할 수 있습니다.');
                    event.target.value = StringUtils.formatPhoneNumber(event.target.value.replaceAll(/[-\s]/g, '').slice(0, 13)) || event.target.value.slice(0, 13);
                  },
                })}
                label={'전화번호*'}
                placeholder={'ex) 01012341234'}
              />
              <div style={{ display: 'flex' }}>
                <Input
                  width={'calc(100% - 20px)'}
                  name={'userId'}
                  {...register('userId')}
                  label={'아이디*'}
                  placeholder={'아이디를 입력해주세요'}
                  onChange={(e) => {
                    const checkIsKoreanIncluded = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g;
                    const checkEmptySpaceIncluded = /\s/g;
                    const checkSpecialCharIncluded = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
                    const checkCapitalLetterIncluded = /[A-Z]/;

                    if (checkIsKoreanIncluded.test(e.target.value)) {
                      // setValue('userId', e.target.value.slice(0, e.target.value.length - 1));
                      setValue('userId', e.target.value.replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g, ''));
                      return AlertMessage.alertMessage('error', '아이디에 한글, 특수문자, 공백, 영어(대문자)는 입력할 수 없습니다.');
                    }
                    if (checkEmptySpaceIncluded.test(e.target.value)) {
                      setValue('userId', e.target.value.slice(0, e.target.value.length - 1));
                      return AlertMessage.alertMessage('error', '아이디에 한글, 특수문자, 공백, 영어(대문자)는 입력할 수 없습니다.');
                    }
                    if (checkSpecialCharIncluded.test(e.target.value)) {
                      setValue('userId', e.target.value.slice(0, e.target.value.length - 1));
                      return AlertMessage.alertMessage('error', '아이디에 한글, 특수문자, 공백, 영어(대문자)는 입력할 수 없습니다.');
                    }
                    if (checkCapitalLetterIncluded.test(e.target.value)) {
                      setValue('userId', e.target.value.slice(0, e.target.value.length - 1));
                      return AlertMessage.alertMessage('error', '아이디에 한글, 특수문자, 공백, 영어(대문자)는 입력할 수 없습니다.');
                    }
                    if (e.target.value.length > 30) {
                      setValue('userId', e.target.value.slice(0, 30));
                      return AlertMessage.alertMessage('error', '아이디는 최대 30자 입니다');
                    }
                  }}
                />
                <Button type={'button'} disabled={loading} style={{ marginLeft: 14, height: 40, width: 100 }} variant={'primary'} onClick={checkDuplication}>
                  {loading ? <CircularProgress size={'20px'} /> : '중복체크'}
                </Button>
              </div>
              <Input
                width={'100%'}
                name={'password'}
                {...register('password')}
                type={'password'}
                label={'비밀번호*'}
                placeholder={'비밀번호를 입력해주세요'}
                onChange={(e) => {
                  setPwd(e.target.value);
                  if (e.target.value.length > 100) {
                    setValue('password', e.target.value.slice(0, 100));
                    return AlertMessage.alertMessage('error', '비밀번호는 최대 100자 입니다');
                  }
                }}
              />
              <div
                style={{
                  marginTop: 20,
                  marginBottom: 20,
                  position: 'relative',
                  borderRadius: 4,
                  border: '1px solid #ccc',
                  padding: '25px 20px 15px 20px',
                }}>
                <PasswordPolicyComponent ref={refPasswordPolicy} password={pwd} />
              </div>
            </FormProvider>
          </div>
        </div>
      </form>

      <CompleteAndCloseModal title={'수정완료'} detail={'사용자 추가가 정상적으로 처리되었습니다'} open={closeOpen} setOpen={setCloseOpen} />
    </BaseModal>
  );
};

export default RegiUserModal;
