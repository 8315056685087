import React, { useEffect, useState } from 'react';
import '../../../css/facilities/facilityRegiModal.css';
import AddressInput from '../../AddressInput';
import RadioGroup from '../../RadioGroup';
import BaseModal from '../../BaseModal';
import { fetchApi } from '../../../utils/APIUtils';
import Divider from '../../Divider';
import Input from '../../Input';
import Radio from '../../Radio';
import { useForm } from 'react-hook-form';
import AlertMessage from '../../utilities/AlertMessage';

const RegiFacilityModal = ({ open, setOpen, fetchTable }) => {
  const [typeList, setTypeList] = useState([]);
  const [addressValue, setAddressValue] = useState('');

  useEffect(() => {
    fetchApi('/v1/me/organization/facility-type', 'get').then((data) => setTypeList(data));
  }, []);

  const firstIndexFacilityId = typeList?.[0]?.organizationFacilityType.id;

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      active: '1',
    },
  });

  const onSubmit = (data) => {
    // 코드 에러
    if (data.code === '' || data.code === undefined) {
      return AlertMessage.alertMessage('error', '코드를 입력해주세요');
    } else if (data.code.length >= 101) {
      return AlertMessage.alertMessage('error', '코드는 최대 100자까지 입력할 수 있습니다');
    }
    // 이름 에러
    if (data.name === '' || data.name === undefined) {
      return AlertMessage.alertMessage('error', '이름을 입력해주세요');
    } else if (data.name.length >= 101) {
      return AlertMessage.alertMessage('error', '이름은 최대 100자까지 입력할 수 있습니다');
    }
    // 주소 에러
    if (data.longitude === '' || data.longitude === undefined || data.longitude === null) {
      return AlertMessage.alertMessage('error', '주소를 입력해주세요');
    }
    // 상세 주소 에러
    if (data.addressStreetDetail.length > 50) {
      return AlertMessage.alertMessage('error', '상세주소는 50자까지 작성할 수 있습니다');
    }
    // 비고 에러
    if (data.detail.length > 2000) {
      return AlertMessage.alertMessage('error', '비고란은 2000자까지 작성할 수 있습니다');
    }
    // RTSP HOST 에러
    if (data.rtspHost === '' || data.rtspHost === undefined) {
      return AlertMessage.alertMessage('error', 'RTSP HOST를 입력해주세요');
    } else if (data.rtspHost.length > 1000) {
      return AlertMessage.alertMessage('error', 'RTSP HOST는 최대 1000자까지 입력할 수 있습니다');
    }
    // RTSP PORT 에러
    if (data.rtspPort === '' || data.rtspPort === undefined) {
      return AlertMessage.alertMessage('error', 'RTSP PORT 입력해주세요');
    } else if (data.rtspPort > 65535) {
      return AlertMessage.alertMessage('error', 'RTSP PORT는 최대 65535까지 입력할 수 있습니다');
    }
    // RTSP PATH 에러
    if (data.rtspPath.length > 255) {
      return AlertMessage.alertMessage('error', 'RTSP PATH는 최대 255자까지 입력할 수 있습니다');
    }
    // RTSP ID 에러
    if (data.rtspAuthId.length > 100) {
      return AlertMessage.alertMessage('error', 'RTSP 아이디는 최대 100자까지 입력할 수 있습니다');
    }
    // RTSP PWD 에러
    if (data.rtspAuthPw.length > 100) {
      return AlertMessage.alertMessage('error', 'RTSP 비밀번호는 최대 100자까지 입력할 수 있습니다');
    }
    // RTSP FLAG 에러
    if (data.rtspFlags?.length > 255) {
      return AlertMessage.alertMessage('error', 'RTSP FLAG는 최대 255자까지 입력할 수 있습니다');
    }
    // ONVIF HOST 에러
    if (data.onvifHost.length > 1000) {
      return AlertMessage.alertMessage('error', 'ONVIF HOST는 최대 1000자까지 입력할 수 있습니다');
    }
    // ONVIF PORT 에러
    if (data.onvifPort > 65535) {
      return AlertMessage.alertMessage('error', 'ONVIF PORT는 최대 65535까지 입력할 수 있습니다');
    }
    // ONVIF PATH 에러
    if (data.onvifPath.length > 255) {
      return AlertMessage.alertMessage('error', 'ONVIF PATH는 최대 255자까지 입력할 수 있습니다');
    }
    // ONVIF ID 에러
    if (data.onvifAuthId.length > 100) {
      return AlertMessage.alertMessage('error', 'ONVIF 아이디는 최대 100자까지 입력할 수 있습니다');
    }
    // ONVIF PWD 에러
    if (data.onvifAuthPw.length > 100) {
      return AlertMessage.alertMessage('error', 'ONVIF 비밀번호는 최대 100자까지 입력할 수 있습니다');
    }

    fetchApi(`/v1/me/organization/facility/cctv`, `post`, {
      code: data?.code,
      active: data.active,
      status: data.status,
      typeId: firstIndexFacilityId,
      detail: data.detail,
      name: data.name,
      latitude: data.latitude,
      longitude: data.longitude,
      addressStreet: addressValue,
      addressStreetDetail: data.addressStreetDetail,
      rtspHost: data.rtspHost,
      rtspPort: data.rtspPort,
      rtspAuthId: data.rtspAuthId,
      rtspAuthPw: data.rtspAuthPw,
      rtspPath: data.rtspPath,
      rtspFlags: data.rtspFlags,
      onvifHost: data.onvifHost,
      onvifPort: data.onvifPort,
      onvifAuthId: data.onvifAuthId,
      onvifAuthPw: data.onvifAuthPw,
      onvifPath: data.onvifPath,
    })
      .then(() => {
        AlertMessage.alertMessage('success', 'CCTV 생성이 완료되었습니다');
        fetchTable();
        setOpen(false);
      })
      .catch((err) => console.log('[Line:45 err]', err));
  };

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const originalStyle = {
    width: '50%',
    marginRight: 20,
  };

  const extendedStyle = {
    width: '50%',
    borderLeft: '1px solid #DDDDDD',
    paddingLeft: 20,
  };

  const titleStyle = {
    textAlign: 'left',
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 6,
  };

  const hostContainerStyle = {
    display: 'flex',
    alignItems: 'center',
  };

  return (
    <BaseModal width={1000} height={830} title={'CCTV 등록'} open={open} rightBtnLabel={'등록'} leftBtnLabel={'취소'} closeIcon handleClose={handleClose} handleLeftBtn={handleClose} handleRightBtn={handleSubmit(onSubmit)}>
      <Divider marginBottom={20} color={'#999999'} />
      <div className='modal-user-edit-container'>
        <div style={originalStyle}>
          <Input
            name={'code'}
            {...register('code')}
            label={'코드*'}
            placeholder={'CCTV 코드를 입력해주세요'}
            onChange={(e) => {
              if (e.target.value.length > 100) {
                AlertMessage.alertMessage('error', '코드는 최대 100자까지 입력할 수 있습니다');
              }
              setValue('code', e.target.value.slice(0, 100));
            }}
          />
          <Input
            name={'facility_name'}
            {...register('name')}
            label={'이름*'}
            placeholder={'CCTV 이름을 입력해주세요'}
            onChange={(e) => {
              if (e.target.value.length > 100) {
                AlertMessage.alertMessage('error', '이름은 최대 100자까지 입력할 수 있습니다');
              }
              setValue('name', e.target.value.slice(0, 100));
            }}
          />
          <div className='each-detail'>
            <div className='title'>주소*</div>
            <AddressInput
              padding={10}
              placeholder={'지번 또는 도로명주소를 입력해주세요'}
              cn={'input'}
              radius={5}
              width={'calc(100% - 110px)'}
              height={'40px'}
              onChange={(addressInfo) => {
                setAddressValue(addressInfo.roadAddr);
                setValue('latitude', addressInfo.latitude);
                setValue('longitude', addressInfo.longitude);
              }}
            />
          </div>
          <Input
            name={'addressStreetDetail'}
            {...register('addressStreetDetail')}
            label={'상세주소'}
            placeholder={'상세주소를 입력해주세요'}
            onChange={(e) => {
              if (e.target.value.length > 50) {
                AlertMessage.alertMessage('error', '상세주소는 최대 50자까지 입력할 수 있습니다');
              }
              setValue('addressStreetDetail', e.target.value.slice(0, 50));
            }}
          />
          <div style={{ display: 'none', width: '100%' }}>
            <div style={{ width: '60%' }}>
              <Input name={'latitude'} {...register('latitude')} label={'위도/경도'} placeholder={'위도'} />
            </div>
            <div style={{ width: '40%', marginLeft: 15 }}>
              <Input width={'100%'} name={'longitude'} {...register('longitude')} placeholder={'경도'} />
            </div>
          </div>
          <RadioGroup labelwidth={160} label={'활성 상태'}>
            <Radio value={'1'} {...register('active')}>
              활성
            </Radio>
            <Radio value={'0'} {...register('active')}>
              비활성
            </Radio>
          </RadioGroup>
          <Input
            textarea
            height={120}
            width={'calc(100% - 130px)'}
            label={'비고'}
            placeholder='메모를 입력해주세요'
            {...register('detail')}
            onChange={(e) => {
              if (e.target.value.length > 2000) {
                AlertMessage.alertMessage('error', '비고란은 최대 2000자까지 입력할 수 있습니다');
              }
              setValue('detail', e.target.value.slice(0, 2000));
            }}
          />
        </div>

        <div style={extendedStyle}>
          <div style={titleStyle}>RTSP</div>
          <div style={hostContainerStyle}>
            <div style={{ width: '80%' }}>
              <Input
                extended
                name={'rtspHost'}
                {...register('rtspHost')}
                label={'Host*'}
                placeholder={'Host를 입력해주세요'}
                onChange={(e) => {
                  if (e.target.value.length > 1000) {
                    AlertMessage.alertMessage('error', 'RTSP Host는 최대 1000자까지 입력할 수 있습니다');
                  }
                  setValue('rtspHost', e.target.value.slice(0, 1000));
                }}
              />
            </div>
            <div style={{ width: '20%', marginLeft: 15 }}>
              <Input
                width={'100%'}
                name={'rtspPort'}
                {...register('rtspPort')}
                placeholder={'Port'}
                type={'number'}
                onChange={(e) => {
                  if (e.target.value > 65535) {
                    setValue('rtspPort', watch('rtspPort').slice(0, 6));

                    return AlertMessage.alertMessage('error', 'RTSP Port는 최대 65535까지 입력할 수 있습니다');
                  } else {
                    setValue('rtspPort', e.target.value);
                  }
                }}
              />
            </div>
          </div>
          <Input
            extended
            name={'rtspPath'}
            {...register('rtspPath')}
            label={'Path'}
            placeholder={'Path를 입력해주세요'}
            onChange={(e) => {
              if (e.target.value.length > 255) {
                AlertMessage.alertMessage('error', 'RTSP Path는 최대 255자까지 입력할 수 있습니다');
              }
              setValue('rtspPath', e.target.value.slice(0, 255));
            }}
          />
          <Input
            extended
            name={'rtspAuthId'}
            {...register('rtspAuthId')}
            label={'아이디'}
            placeholder={'아이디를 입력해주세요'}
            onChange={(e) => {
              if (e.target.value.length > 100) {
                AlertMessage.alertMessage('error', 'RTSP 아이디는 최대 100자까지 입력할 수 있습니다');
              }
              setValue('rtspAuthId', e.target.value.slice(0, 100));
            }}
          />
          <Input
            type={'password'}
            extended
            name={'rtspAuthPw'}
            {...register('rtspAuthPw')}
            label={'비밀번호'}
            placeholder={'비밀번호를 입력해주세요'}
            onChange={(e) => {
              if (e.target.value.length > 100) {
                AlertMessage.alertMessage('error', 'RTSP 비밀번호는 최대 100자까지 입력할 수 있습니다');
              }
              setValue('rtspAuthPw', e.target.value.slice(0, 100));
            }}
          />
          <Input
            extended
            name={'rtspFlags'}
            {...register('rtspFlags')}
            label={'Flags'}
            placeholder={'Flags를 입력해주세요'}
            onChange={(e) => {
              if (e.target.value.length > 255) {
                AlertMessage.alertMessage('error', 'RTSP Flags는 최대 255자까지 입력할 수 있습니다');
              }
              setValue('rtspFlags', e.target.value.slice(0, 255));
            }}
          />

          <div style={titleStyle}>ONVIF</div>
          <div style={hostContainerStyle}>
            <div style={{ width: '80%' }}>
              <Input
                extended
                name={'onvifHost'}
                {...register('onvifHost')}
                label={'Host'}
                placeholder={'Host를 입력해주세요'}
                onChange={(e) => {
                  if (e.target.value.length > 1000) {
                    AlertMessage.alertMessage('error', 'ONVIF Host는 최대 1000자까지 입력할 수 있습니다');
                  }
                  setValue('onvifHost', e.target.value.slice(0, 1000));
                }}
              />
            </div>
            <div style={{ width: '20%', marginLeft: 15 }}>
              <Input
                width={'100%'}
                name={'onvifPort'}
                {...register('onvifPort')}
                type={'number'}
                placeholder={'Port'}
                onChange={(e) => {
                  if (e.target.value > 65535) {
                    setValue('onvifPort', watch('onvifPort').slice(0, 6));
                    return AlertMessage.alertMessage('error', 'ONVIF Port는 최대 65535까지 입력할 수 있습니다');
                  } else {
                    setValue('onvifPort', e.target.value);
                  }
                }}
              />
            </div>
          </div>
          <Input
            extended
            name={'onvifPath'}
            {...register('onvifPath')}
            label={'Path'}
            placeholder={'Path를 입력해주세요'}
            onChange={(e) => {
              if (e.target.value.length > 255) {
                AlertMessage.alertMessage('error', 'ONVIF Path는 최대 255자까지 입력할 수 있습니다');
              }
              setValue('onvifPath', e.target.value.slice(0, 255));
            }}
          />
          <Input
            extended
            name={'onvifAuthId'}
            {...register('onvifAuthId')}
            label={'아이디'}
            placeholder={'아이디를 입력해주세요'}
            onChange={(e) => {
              if (e.target.value.length > 100) {
                AlertMessage.alertMessage('error', 'ONVIF 아이디는 최대 100자까지 입력할 수 있습니다');
              }
              setValue('onvifAuthId', e.target.value.slice(0, 100));
            }}
          />
          <Input
            type={'password'}
            extended
            name={'onvifAuthPw'}
            {...register('onvifAuthPw')}
            label={'비밀번호'}
            placeholder={'비밀번호를 입력해주세요'}
            onChange={(e) => {
              if (e.target.value.length > 100) {
                AlertMessage.alertMessage('error', 'ONVIF 비밀번호는 최대 100자까지 입력할 수 있습니다');
              }
              setValue('onvifAuthPw', e.target.value.slice(0, 100));
            }}
          />
        </div>
      </div>
    </BaseModal>
  );
};

export default RegiFacilityModal;
