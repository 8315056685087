import React from "react";

const styles = {
	messageBox: {
		container: {
			width: "100%",
			height: "auto",
			backgroundColor: "#FFFFFF",
			borderRadius: 5,
			color: "#496DED",
			fontSize: 18,
			fontWeight: 700,
			padding: "10px 0",
			marginBottom: 15,
			textAlign: "center",
		}
	}
}

export default class MessageBoxContainer extends React.Component {
  timer = null;
  state = {
    open: true,
  };
  componentDidMount() {
    this.timer = setTimeout(() => {
      this.setState({ open: false });
    }, this.props.duration - 1000);
  }

  componentWillUnmount() {
		clearTimeout(this.timer);
		this.timer = null;
	}

  render() {
    return (
      <div
        className={`messageBox ${this.state.open ? 'open' : 'close'}`}
        style={{
          ...styles.messageBox.container,
          ...this.props?.style,
        }}>
        {this.props.message}
      </div>
    );
  }
}
