import { USER } from 'anuro-platform-core';

export const ValidationText = {
  MIN_LENGTH: `아이디는 ${USER.USERNAME_MIN_LENGTH}자 이상으로 입력해야 합니다.`,
  MAX_LENGTH: `아이디는 ${USER.USERNAME_MAX_LENGTH}자 이하로 입력해야 합니다.`,
  STARTS_WITH_ALPHABET: '아이디는 영문으로 시작해야 합니다.',
  NUMBER_MIN_COUNT: `아이디는 숫자 ${USER.USERNAME_NUMBER_MIN_COUNT}개 이상으로 입력해야 합니다.`,
  ALPHABET_MIN_COUNT: `아이디는 영문 ${USER.USERNAME_ALPHABET_MIN_COUNT}자 이상으로 입력해야 합니다.`,
  SPECIAL_CHARACTER_MIN_COUNT: `아이디는 특수문자 ${USER.USERNAME_SPECIAL_CHARACTER_MIN_COUNT}개 이상으로 입력해야 합니다.`,
  EMPTY_SPACE_MAX_LENGTH: USER.USERNAME_EMPTY_SPACE_MAX_COUNT > 0 ? `아이디는 공백 ${USER.USERNAME_EMPTY_SPACE_MAX_COUNT}개 이하로 입력해야 합니다.` : '아이디는 공백을 포함할 수 없습니다.',
  CHARACTER_MAX_COUNT: USER.USERNAME_SPECIAL_CHARACTER_MIN_COUNT > 0 ? `아이디는 특수문자 ${USER.USERNAME_SPECIAL_CHARACTER_MIN_COUNT}개 이하로 입력해야 합니다.` : '아이디는 특수문자를 포함할 수 없습니다.',

  PASSWORD_MIN_LENGTH: `${USER.PASSWORD_MIN_LENGTH}자 이상으로 입력해야 합니다.`,
  PASSWORD_MAX_LENGTH: `${USER.PASSWORD_MAX_LENGTH}자 이하로 입력해야 합니다.`,
  PASSWORD_NO_EMPTY_SPACE: `공백을 포함할 수 없습니다.`,
  PASSWORD_NUMBER_MIN_COUNT: `숫자 ${USER.PASSWORD_NUMBER_MIN_COUNT}개 이상으로 입력해야 합니다.`,
  PASSWORD_ALPHABET_MIN_COUNT: `영문 ${USER.PASSWORD_ALPHABET_MIN_COUNT}개 이상으로 입력해야 합니다.`,
  PASSWORD_SPECIAL_CHARACTER_MIN_COUNT: `특수문자 ${USER.PASSWORD_SPECIAL_CHARACTER_MIN_COUNT}개 이상으로 입력해야 합니다.`,
  PASSWORD_EMPTY_SPACE_MAX_LENGTH: USER.PASSWORD_EMPTY_SPACE_MAX_COUNT > 0 ? `공백 ${USER.PASSWORD_EMPTY_SPACE_MAX_COUNT}개 이하로 입력해야 합니다.` : '공백을 포함할 수 없습니다.',

  // MIN_LENGTH: `6자 이상으로 입력해야 합니다.`,
  // MAX_LENGTH: `30자 이하로 입력해야 합니다.`,
};
