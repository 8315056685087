import React, { HTMLAttributes } from 'react';
import styles from './RightBtn.module.css';
import { Button, styled } from '@mui/material';

interface Props extends HTMLAttributes<HTMLButtonElement> {}

const RightBtn = ({ className, ...props }: Props) => {
  return (
    <button className={styles.button + ' ' + className} {...props}>
      {props.children}
    </button>
  );
};

export const RightCustomB = styled(Button)({
  color: 'red',
});

export default RightBtn;
