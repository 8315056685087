import React from 'react';
import '../css/resetPasswordModal.css';
import AlertMessage from './utilities/AlertMessage';
import BaseModal from './BaseModal';
import Divider from './Divider';
import APIUtils from '../utils/APIUtils';
import { api_tta } from '../api/api_tta';
import { logWithTs } from 'anuro-platform-core';
import { StorageUtils } from '../utils/StorageUtils';
import { WSC } from '../service/WSC';
import type SessionStore from '../stores/SessionStore';
import { withRouter } from 'react-router-dom';
import PasswordPolicyComponent from './settings/PasswordPolicyComponent';

interface Props {
  open: boolean;
  history: any;
  sessionStore: SessionStore;
}

interface State {
  currentPwdValue: string;
  newPwdValue: string;
}

class ResetPasswordModal extends React.Component<Props, State> {
  apiUtils = new APIUtils();

  state = {
    pwdValue: '' || null,
    pwdConfirmValue: '' || null,
  };

  handleChangePwd = async () => {
    const { currentPwdValue, newPwdValue } = this.state;
    if (currentPwdValue.length === 0) return AlertMessage.alertMessage('error', '현재 비밀번호를 입력해주세요');
    else if (newPwdValue.length === 0) return AlertMessage.alertMessage('error', '새 비밀번호를 입력해주세요');

    this.apiUtils
      .fetchApi('/v1/me/password', 'patch', {
        before: currentPwdValue,
        after: newPwdValue,
      })
      .then((r) => {
        console.log('[Line:44 r]', r);
        this.logout();
      })
      .catch((e) => {
        console.log('[Line:49 e]', e);
      });
  };

  // !!! 로그아웃
  logout = async () => {
    try {
      const res = await api_tta.delete('/v1/auth/session');
      if (res.ok) {
        logWithTs('세션삭제 성공');
      } else {
        logWithTs('세션삭제 실패', res);
      }
    } catch (err) {
      logWithTs(err);
    }

    // 브라우저에 저장된 JWT 토큰 삭제
    StorageUtils.removeAuthorization();

    // API 인스턴스에 저장된 Authorization 삭제
    api_tta.deleteHeader('Authorization');

    // Web Socket Client 연결 끊기
    WSC.getInstance().stop(true);

    try {
      typeof this.props.sessionStore?.reset === 'function' && this.props.sessionStore?.reset();
    } catch (e) {}

    this.props.history.replace('/sign-in');
  };

  handleCurrentPwdChange = (event) => {
    this.setState({
      currentPwdValue: event.target.value,
    });
  };

  handleNewPwdChange = (event) => {
    this.setState({
      newPwdValue: event.target.value,
    });
  };

  render() {
    const { open } = this.props;
    const { currentPwdValue, newPwdValue } = this.state;
    return (
      <BaseModal title={'비밀번호 변경'} width={400} height={320} open={open} leftBtnLabel={'뒤로가기'} rightBtnLabel={'확인'} handleLeftBtn={this.logout} handleRightBtn={this.handleChangePwd}>
        <Divider marginBottom={20} color={'#999999'} />
        <div className='reset-pwd-modal-container'>
          <form>
            <div className={'input-container'} style={{}}>
              <div className={'title'}>현재 비밀번호</div>
              <input className={'input'} type={'password'} value={currentPwdValue} onChange={this.handleCurrentPwdChange} />
            </div>
            <div className={'input-container'}>
              <div className={'title'}>새 비밀번호</div>
              <input className={'input'} value={newPwdValue} type={'password'} onChange={this.handleNewPwdChange} />
            </div>
            <div
              style={{
                marginTop: 10,
                marginBottom: 20,
                position: 'relative',
                borderRadius: 4,
                border: '1px solid #ccc',
                padding: '25px 20px 15px 20px',
              }}>
              <PasswordPolicyComponent password={this.state.newPwdValue} />
            </div>
          </form>
        </div>
      </BaseModal>
    );
  }
}

export default withRouter(ResetPasswordModal);
