import React from 'react';
import AlertMessage from '../../utilities/AlertMessage';
import BaseModal from '../../BaseModal';
import APIUtils from '../../../utils/APIUtils';

const apiUtils = new APIUtils();

const DeleteSms = ({ open, setOpen, setPrevModal, deleteData, fetchData }) => {
  const contentStyle = {
    marginBottom: 40,
    lineHeight: 1.5,
  };
  const strongStyle = {
    fontWeight: 'bold',
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleDel = async () => {
    const result = await AlertMessage.confirmMessage(`삭제한 후에는 되돌릴 수 없습니다. \n 선택한 SMS를 정말 삭제하시겠습니까?`);
    if (!result) return;

    deleteData.map((i, idx) => {
      apiUtils.fetchApi(`/v1/message-service/${i.uuid}`, 'delete');
      if (deleteData.length - 1 === idx) {
        setTimeout(() => {
          fetchData();
          setOpen(false);
          AlertMessage.alertMessage('success', '삭제가 성공적으로 완료되었습니다');
        }, 1000);
      }
    });
  };
  return null;
  // return (
  //   <BaseModal width={420} height={250} title={'SMS 삭제'} open={open} rightBtnLabel={'삭제'} leftBtnLabel={'취소'} handleRightBtn={handleDel} handleLeftBtn={handleClose}>
  //     <div style={contentStyle}>
  //       삭제한 후에는 되돌릴 수 없습니다. <br /> 선택한 <strong style={strongStyle}>SMS</strong>를 정말 삭제하시겠습니까?
  //     </div>
  //   </BaseModal>
  // );
};

export default DeleteSms;
