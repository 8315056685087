import moment from 'moment/moment';
import { useEffect, useRef, useState } from 'react';
import { loadPlayer, Player } from 'rtsp-relay/browser';
import { api_tta } from '../../api/api_tta';
import Tooltip from '@mui/material/Tooltip';
import { SERVICE, SocketParser } from 'anuro-platform-core';
import { EVENT_STATUS } from '../../constants/Event';
import { ReactComponent as EVENT_ICON } from '../../icons/eventIcon/event_icon2.svg';
import StringUtils from '../../utils/StringUtils';

/*******************************************************************************
 * 시설물 페이지 테이블 / Facility
 * ****************************************************************************/

const CctvStatus = ({ facility }) => {
  const ref = useRef(null);
  const [condition, setCondition] = useState('로딩중...');
  const disabledCondition = condition === '이상';

  let player: Player | null = null;
  let isLoading: NodeJS.Timeout | null = null;
  useEffect(() => {
    const facilityCCTV = facility.facilityCctv;
    if (!facility.active) return setCondition('비활성');
    if (!ref.current) return;

    if (isLoading !== null) {
      clearTimeout(isLoading);
      isLoading = null;
    }

    isLoading = setTimeout(() => {
      if (!ref.current) return;
      loadPlayer({
        url: `${api_tta.getBaseURL().replace('http', 'ws')}/stream/${facilityCCTV.id}?stream=${facilityCCTV.rtspPath}`,
        canvas: ref?.current,
        onDisconnect() {
          setCondition('이상');
        },
      }).then((r) => {
        player = r;
        setCondition('정상');
      });
    }, 300);

    return () => {
      console.log('cctv destroy');
      player && player?.destroy?.();
      player = null;
    };
  }, [ref, facility]);

  return (
    <div style={{ color: disabledCondition ? 'red' : 'white' }}>
      <canvas ref={ref} className='cctv-display-none' />
      {condition}
    </div>
  );
};

export const FacilityColumns = [
  {
    field: 'id',
    headerName: '식별번호',
    width: 80,
    sortable: false,
  },
  {
    field: 'organizationFacilityType',
    headerName: '종류',
    width: 80,
    valueFormatter: (params) => {
      return params.value.facilityType.name;
    },
    sortable: false,
  },
  {
    field: 'name',
    headerName: 'CCTV 이름',
    flex: 2,
    sortable: false,
  },
  {
    field: 'addressStreet',
    headerName: '위치',
    flex: 3,
    sortable: false,
    valueGetter: (params) => {
      return params.value + ' / ' + params.row.addressStreetDetail;
    },
  },
  {
    field: 'code',
    headerName: '코드',
    flex: 1.2,
    sortable: false,
    valueFormatter: (params) => {
      if (params.value === null) return '미지정';
      return params.value;
    },
  },
  {
    field: 'active',
    headerName: '활성 상태',
    flex: 1,
    sortable: false,
    valueFormatter: (params) => {
      if (params.value === 1) return '활성';
      return '비활성';
    },
  },
  {
    field: 'status',
    headerName: '동작 상태',
    flex: 1,
    sortable: false,
    renderCell: ({ row }) => <CctvStatus facility={row} />,
  },
  {
    field: 'createdAt',
    headerName: '등록일',
    flex: 2,
    sortable: false,
    valueFormatter: (params) => {
      return moment(params.value).format('YY년 MM월 DD일 HH시 mm분 ss초');
    },
  },
];

/*******************************************************************************
 * 이벤트 페이지 테이블 / Event
 * ****************************************************************************/
export const EventColumns = [
  {
    field: 'id',
    headerName: '식별번호',
    width: 80,
    sortable: false,
  },
  {
    field: 'code',
    headerName: '코드',
    flex: 1.8,
    sortable: false,
  },
  {
    field: 'issuedAt',
    headerName: '발생일시',
    flex: 2,
    sortable: false,
    valueGetter: (params) => moment(params.value).format('YY년 MM월 DD일 HH시 mm분 ss초'),
  },
  {
    field: 'addressStreet',
    headerName: '위치',
    flex: 2,
    sortable: false,
    renderCell: (params) => (
      <div>
        <div>{params.value}</div>
        <div style={{ color: '#8088A0', fontSize: 12 }}>
          {params.row.latitude}/{params.row.longitude}
        </div>
      </div>
    ),
  },
  {
    field: 'event_type',
    headerName: '이벤트 종류',
    flex: 1,
    sortable: false,
    renderCell: (params) => {
      return (
        <Tooltip title={params.row.organizationEventCategory?.organizationService?.name}>
          <img src={params.row.organizationEventCategory?.organizationService?.iconUrl} alt={'icon'} className={'event-type'} />
        </Tooltip>
      );
    },
  },
  {
    field: 'event_category',
    headerName: '유형',
    sortable: false,
    valueGetter: (params) => params.row.organizationEventCategory?.eventCategory.name,
  },
  {
    field: 'in_charge_id',
    headerName: '전송자/담당자',
    flex: 2,
    sortable: false,
    valueGetter: (params) => {
      // TODO Type 변경
      const parsingData: any = params?.row.raw ? SocketParser.parse(SERVICE[params?.row.organizationEventCategory?.eventCategory?.service?.code], params.row.raw) : '';
      const sender = parsingData ? parsingData.body?.SENDER_ID : params.row?.reportUser?.username;
      const inCharge = params.row?.chargeUser?.username ? params.row?.chargeUser?.username : '미배정';
      return `${sender} / ${inCharge}`;
    },
  },
  {
    field: 'status',
    headerName: '상태',
    flex: 1,
    sortable: false,
    renderCell: (params) => {
      const eventStatus = EVENT_STATUS[params.value].name;
      if (eventStatus === '상황발생') {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <EVENT_ICON />
            <div style={{ color: 'red', marginLeft: 4, fontWeight: 'bold' }}>{eventStatus}</div>
          </div>
        );
      } else if (eventStatus === '처리중') {
        return <div style={{ marginLeft: 4, fontWeight: 'bold' }}>{eventStatus}</div>;
      }
      return <div style={{ marginLeft: 4, fontWeight: 'bold', color: '#8088A0' }}>{eventStatus}</div>;
    },
  },
];

/*******************************************************************************
 * 사용자 페이지 테이블 / User
 * ****************************************************************************/
export const UserManageColumns = [
  {
    field: 'id',
    headerName: '식별번호',
    width: 80,
    sortable: false,
  },
  {
    field: `name`,
    headerName: '이름',
    flex: 1,
    sortable: false,
    valueGetter: (params) => `${params?.row?.profile?.name}`,
  },
  {
    field: `username`,
    headerName: '아이디',
    flex: 1,
    sortable: false,
  },
  {
    field: 'type',
    headerName: '유형',
    width: 150,
    sortable: false,
    valueGetter: (params) => {
      const MASTER = params.value === 'ACCOUNT_HOLDER';
      const SUPER_USER = params.value === 'SUPER_USER';
      if (SUPER_USER) return '관리자';
      if (MASTER) return '계정소유자';
      return '일반';
    },
  },
  {
    field: 'phone',
    headerName: '전화번호',
    flex: 1,
    sortable: false,
    valueGetter: (params) => {
      const { phoneNumber } = params.row?.profile;
      if (!phoneNumber) {
        return '';
      }

      return StringUtils.formatPhoneNumber(phoneNumber);
    },
  },
];

/*******************************************************************************
 * SMS 전송목록 테이블 / SMS
 * ****************************************************************************/

export const SmsSendListColumns = [
  {
    field: 'id',
    headerName: '식별번호',
    width: 100,
    sortable: false,
  },
  {
    field: 'createdAt',
    headerName: '발송일',
    width: 240,
    sortable: false,
    valueGetter: (params) => moment(params.value).format('YY년 MM월 DD일 HH시 mm분 ss초'),
  },
  {
    field: 'to',
    headerName: '수신자',
    width: 150,
    sortable: false,
    valueGetter: (params) => {
      let tos = [];
      try {
        tos = JSON.parse(params.value);
      } catch (e) {
        console.error('To value is not valid json object');
      }

      if (tos.length > 1) {
        return `${tos.length}명의 수신자`;
      } else if (tos.length === 1) {
        return tos[0];
      } else {
        return '';
      }
    },
  },

  {
    field: 'message',
    headerName: '발송내용',
    flex: 1,
    sortable: false,
  },
];

export const SmsTempSavedListColumns = [
  {
    field: 'id',
    headerName: '식별번호',
    width: 100,
    sortable: false,
  },
  {
    field: 'message',
    headerName: '작성내용',
    flex: 1,
    sortable: false,
  },
  {
    field: 'to',
    headerName: '수신자',
    width: 150,
    sortable: false,
    valueGetter: (params) => {
      let tos = [];
      try {
        tos = JSON.parse(params.value);
      } catch (e) {
        console.error('To value is not valid json object');
      }

      if (tos.length > 1) {
        return `${tos.length}명의 수신자`;
      } else if (tos.length === 1) {
        return tos[0];
      } else {
        return '';
      }
    },
  },
  {
    field: 'createdAt',
    headerName: '작성일자',
    width: 240,
    sortable: false,
    valueGetter: (params) => moment(params.value).format('YY년 MM월 DD일 HH시 mm분 ss초'),
  },
];
