import React from 'react';
import '../../css/event/eventDetailListComponent.css';
import moment from 'moment';

const EventDetailListComponent = ({ commentList, status }) => {
  const isHistory = 'code' in commentList;

  const renderStatus = ((e) => {
    switch (e) {
      case 'STANDBY':
        return '상황발생';
      case 'IN_PROGRESS':
        return '처리중';
      case 'TERMINATED':
        return '상황종료';
      case 'MODIFY':
        return '이벤트 및 전문 수정';
      default:
        return "-";
    }
  });

  return (
    <div className={'list-container'} style={{ backgroundColor: isHistory ? '#FFE6E6' : 'white' }}>
      <div className={'title'}>
        {isHistory ? <div style={{ fontSize: 12, fontWeight: 'bold', marginRight: 14 }}>{commentList.status === "MODIFY" ? "정보수정" : "상태변경"}</div> : <div style={{ fontSize: 12, fontWeight: 'bold', marginRight: 14 }}>{commentList?.user?.username}</div>}

        <div
          style={{
            fontSize: 12,
            color: '#AAAAAA',
          }}>
          {isHistory ? moment(commentList?.createdAt).format('YY년 MM월 DD일 HH시 mm분 ss초') : moment(commentList?.createdAt).format('YY년 MM월 DD일 HH시 mm분 ss초')}
        </div>
      </div>
      {isHistory ? <div className={'detail'}>{renderStatus(commentList.status)}</div> : <div className={'detail'}>{commentList?.message}</div>}
    </div>
  );
};

export default EventDetailListComponent;
