import React from 'react';
import '../css/completeAndCloseModal.css';
import { Box, Modal } from '@mui/material';
import ModalStyle from './ModalStyle';

const CompleteAndCloseModal = ({ title, detail, open, setOpen, closePrevModal }) => {
	const handleClose = () => {
		setOpen(false);
		if (closePrevModal !== undefined) {
			closePrevModal(false);
		}
	};

	return (
		<Modal
			open={open}
			// onClose={handleClose}
		>
			<Box sx={ModalStyle(450, 220, 8)}>
				<div className='complete-and-close-modal-container'>
					<div className='header'>{title}</div>
					<div className='confirm'>{detail}</div>
					<div className='btn-container'>
						<button className='btn-close' onClick={handleClose}>
							확인
						</button>
					</div>
				</div>
			</Box>
		</Modal>
	);
};

export default CompleteAndCloseModal;
