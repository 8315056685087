import React from 'react';
import { api_tta } from '../api/api_tta';
import AlertMessage from '../components/utilities/AlertMessage';

interface Props {
  limit?: number;
  offset?: number;
  ob?: string;
  od?: string;

  [key: string]: any;
}

const DEFAULT_PROPS = {
  limit: 10,
  offset: 0,
  ob: 'id',
  od: 'DESC',
};

const useAsyncList = <T>(url: string, props?: Props) => {
  const [data, setData] = React.useState<T | null>(null);
  const [loading, setLoading] = React.useState(true);

  const fetch = React.useCallback(() => {
    setLoading(true);
    api_tta
      .get<T>(url, { ...DEFAULT_PROPS, ...props })
      .then((r) => {
        if (!r.ok || !r.data) return;
        setData(r.data);
      })
      .finally(() => setLoading(false));
  }, [props]);

  React.useEffect(() => {
    fetch();
  }, [fetch]);

  return {
    loading,
    data,
    setData,
  };
};

export default useAsyncList;
