import React from 'react';
import Divider from '../../../Divider';
import styles from '../../../../css/event/eventDetailModal/DetailInfo.module.css';

interface Props {
  title: string;
  detail: string;
  margin?: number;
}

const DetailItem = ({ title, detail, margin }: Props) => {
  return (
    <>
      <Divider marginTop={margin} marginBottom={margin} color={'#DDDDDD'} width={'90%'} />
      <div className={`${styles.item_container}`}>
        <div className={styles.title}>{title}</div>
        <div className={styles.detail}>{detail}</div>
      </div>
    </>
  );
};

export default DetailItem;
