import React, {ChangeEvent, InputHTMLAttributes} from "react";
import AlertMessage from "../utilities/AlertMessage";

interface Props extends InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  maxLength?: number | undefined;
  label?: string;
  errorMessage?: string;
}
interface States {}

export default class Input extends React.Component<Props, States> {

  onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {onChange, maxLength, label, placeholder, errorMessage} = this.props;
    let _placeholder = label || placeholder?.substring(0, placeholder.search(/[을|를]/) + 1) || "";

    if(maxLength && e.target.value.length > maxLength) {
      return AlertMessage.alertMessage("error", errorMessage ? errorMessage : `${_placeholder} ${maxLength}자 이내로 입력해주세요.`);
    }
    onChange && onChange(e);
  }

  render() {
    const {type} = this.props;
    if(type === "textarea") {
      return <textarea {...this.props} maxLength={undefined} onChange={this.onChange}/>
    } else {
      return <input {...this.props} maxLength={undefined} onChange={this.onChange}/>;
    }
  }
}
