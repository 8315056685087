import React from 'react';
import { ReactComponent as VALID } from '../../icons/setting/password_valid.svg';
import { ReactComponent as INVALID } from '../../icons/setting/password_invalid.svg';
import { USER, Validator } from 'anuro-platform-core';
import VALIDATION = Validator.VALIDATION;

const styles = {
  passwordPolicy: {
    item: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 10,
    },
    text: {
      marginLeft: 7,
    },
    textOrange: {
      color: '#FF9800',
    },
    textGreen: {
      color: '#4CAF50',
    },
  },
};

type Props = {
  password: string;
};

type PasswordPolicyProps = {
  status: boolean;
  message: string;
  policyName: string;
  reg: RegExp;
};

export default class PasswordPolicyComponent extends React.Component<Props, any> {
  public get isAvailable(): boolean {
    const { password } = this.props;
    const validateResult = Validator.validatePassword(password);
    return validateResult.result;
  }

  public getValidationText = (validation: Validator.VALIDATION) => {
    switch (validation) {
      case VALIDATION.MIN_LENGTH:
        return `길이 ${USER.PASSWORD_MIN_LENGTH}자 이상`;
      case VALIDATION.MAX_LENGTH:
        return `길이 ${USER.PASSWORD_MAX_LENGTH}자 이하`;
      case VALIDATION.SPACE_MIN_COUNT:
        return `공백 ${USER.PASSWORD_EMPTY_SPACE_MIN_COUNT}자 이상`;
      case VALIDATION.SPACE_MAX_COUNT:
        if (USER.PASSWORD_EMPTY_SPACE_MAX_COUNT) {
          return `공백 ${USER.PASSWORD_EMPTY_SPACE_MAX_COUNT}자 이하`;
        } else {
          return `공백불가`;
        }
      case VALIDATION.NUMBER_MIN_COUNT:
        return `숫자 ${USER.PASSWORD_NUMBER_MIN_COUNT}자 이상`;
      case VALIDATION.NUMBER_MAX_COUNT:
        return `숫자 ${USER.PASSWORD_NUMBER_MIN_COUNT}자 이하`;
      case VALIDATION.ALPHABET_MIN_COUNT:
        return `알파벳 ${USER.PASSWORD_ALPHABET_MIN_COUNT}자 이상`;
      case VALIDATION.ALPHABET_MAX_COUNT:
        return `알파벳 ${USER.PASSWORD_ALPHABET_MAX_COUNT}자 이하`;
      case VALIDATION.ALPHABET_LOWER_CASE_MIN_COUNT:
        return `알파벳(소문자) ${USER.PASSWORD_ALPHABET_LOWERCASE_MIN_COUNT}자 이상`;
      case VALIDATION.ALPHABET_LOWER_CASE_MAX_COUNT:
        return `알파벳(소문자) ${USER.PASSWORD_ALPHABET_LOWERCASE_MAX_COUNT}자 이하`;
      case VALIDATION.ALPHABET_UPPER_CASE_MIN_COUNT:
        return `알파벳(대문자) ${USER.PASSWORD_ALPHABET_UPPERCASE_MIN_COUNT}자 이상`;
      case VALIDATION.ALPHABET_UPPER_CASE_MAX_COUNT:
        return `알파벳(대문자) ${USER.PASSWORD_ALPHABET_UPPERCASE_MAX_COUNT}자 이하`;
      case VALIDATION.SPECIAL_CHARACTER_MIN_COUNT:
        return `특수문자 ${USER.PASSWORD_SPECIAL_CHARACTER_MIN_COUNT}자 이상`;
      case VALIDATION.SPECIAL_CHARACTER_MAX_COUNT:
        return `특수문자 ${USER.PASSWORD_SPECIAL_CHARACTER_MAX_COUNT}자 이하`;
      case VALIDATION.STARTS_WITH_ALPHABET:
        return `영문자로 시작`;
      case VALIDATION.STARTS_WITH_NUMBER:
        return `숫자로 시작`;
      case VALIDATION.STARTS_WITH_SPECIAL_CHARACTER:
        return `특수문자로 시작`;
    }
  };

  render() {
    const { password } = this.props;
    const validateResult = Validator.validatePassword(password);

    return (
      <>
        <div style={{ position: 'absolute', top: -8, left: 12, backgroundColor: '#FFF', padding: '0 4px' }}>비밀번호 정책</div>
        {validateResult.errors.map((errorValidation, idx) => this.renderValidation('ERROR', errorValidation, idx))}

        {validateResult.passes.map((passValidation, idx) => this.renderValidation('PASS', passValidation, idx))}
      </>
    );
  }

  private renderValidation = (type: 'ERROR' | 'PASS', validation: Validator.VALIDATION, idx) => {
    return (
      <div style={styles.passwordPolicy.item} key={idx}>
        {type === 'PASS' ? <VALID /> : <INVALID />}
        <span style={{ ...styles.passwordPolicy.text, ...(type === 'PASS' ? { ...styles.passwordPolicy.textGreen } : { ...styles.passwordPolicy.textOrange }) }}>{this.getValidationText(validation)}</span>
      </div>
    );
  };
}
