import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import '../css/nav.css';
import { ReactComponent as Setting } from '../icons/sideNavi/setting.svg';
import SettingModal from './settings/SettingModal';
import EventEmitter from '../utils/EventEmitter';
import { ReactComponent as GisOn } from '../icons/sideNavi/gis_on.svg';
import { ReactComponent as GisOff } from '../icons/sideNavi/gis_off.svg';
import { ReactComponent as CctvOn } from '../icons/sideNavi/cctv_on.svg';
import { ReactComponent as CctvOff } from '../icons/sideNavi/cctv_off.svg';
import { ReactComponent as EventOn } from '../icons/sideNavi/event_on.svg';
import { ReactComponent as EventOff } from '../icons/sideNavi/event_off.svg';
import { ReactComponent as UserManagementOn } from '../icons/sideNavi/user-management_on.svg';
import { ReactComponent as UserManagementOff } from '../icons/sideNavi/user-management_off.svg';
import { ReactComponent as DisasterOn } from '../icons/sideNavi/disaster_on.svg';
import { ReactComponent as DisasterOff } from '../icons/sideNavi/disaster_off.svg';
import { IconButton, Tooltip } from '@mui/material';

const menuItem = [
  {
    name: 'gis',
    IconOn: GisOn,
    IconOff: GisOff,
    title: '주요 상황관리',
  },
  {
    name: 'disaster',
    IconOn: DisasterOn,
    IconOff: DisasterOff,
    title: '재난 상황관리',
  },
  {
    name: 'facility',
    IconOn: CctvOn,
    IconOff: CctvOff,
    title: 'CCTV 관리',
  },
  {
    name: 'event',
    IconOn: EventOn,
    IconOff: EventOff,
    title: '이벤트 내역',
  },
  {
    name: 'user-management',
    IconOn: UserManagementOn,
    IconOff: UserManagementOff,
    title: '사용자 관리',
  },
];

const MenuList = ({ isSuperUser }) => {
  const location = useLocation();

  return menuItem.map(({ name, IconOn, IconOff, title }, idx) => {
    // SUPER_USER 가 아닐 시 사용자 관리탭 렌더링 안함
    if (name === 'user-management' && !isSuperUser) {
      return null;
    }
    return (
      <Tooltip key={idx} title={title} placement={'right'}>
        <NavLink key={idx} to={`${name}`} className={`link ${location.pathname.includes(name) ? 'active' : ''}`}>
          {location.pathname.includes(name) ? <IconOn /> : <IconOff />}
        </NavLink>
      </Tooltip>
    );
  });
};

const SideNavigationComponent = (props) => {
  const emitter = EventEmitter.getInstance();
  const [settingModalOpen, setSettingModalOpen] = useState(false);

  // !!! 맵이 렌더링 되고 처음 시작
  useEffect(() => {
    emitter.on('openSettingModal', openSettingModal);
    return () => {
      emitter.off('openSettingModal', openSettingModal);
    };
  }, []);

  const openSettingModal = () => setSettingModalOpen(true);

  return (
    <>
      <div className={'side-container'}>
        <div className='menu-container'>
          <div className='menu'>
            <MenuList isSuperUser={props.isSuperUser} />
          </div>

          <div>
            <Tooltip title={'설정'} placement={'right'}>
              <IconButton className='icon' onClick={() => setSettingModalOpen(true)}>
                <Setting />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>
      {settingModalOpen && <SettingModal key='setting' modalHandler={setSettingModalOpen} />}
    </>
  );
};

export default SideNavigationComponent;
