import moment from "moment";
import {StorageUtils} from "./StorageUtils";
import jwt_decode from "jwt-decode";

export class JWTUtils {

	// @ts-ignore
	static getPayload = (token: string = StorageUtils.getAuthorization()): any | null => {
		if (!token) return null;
		try {
			return jwt_decode(token);
		} catch (e) {
			console.error(e);
		}
		return null;
	};

	static checkExp(payload = JWTUtils.getPayload()) {
		return payload.exp > moment().unix();
	};

	static checkNbf(payload = JWTUtils.getPayload()) {
		return payload.nbf <= moment().unix();
	};

	static isAuthenticated() {
		const jwt = JWTUtils.getPayload();
		return jwt && JWTUtils.checkNbf(jwt) && JWTUtils.checkExp(jwt);
	};
}
