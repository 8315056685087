import React, { createRef } from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import ReactModal from 'react-modal-resizable-draggable';
import { inject, observer } from 'mobx-react';
import EventEmitter from '../../utils/EventEmitter';
import { MODE_WRITE } from './EventListPanelComponent';
import moment from 'moment';
import CCTVControlComponent from './CCTVControlComponent';
import APIUtils from '../../utils/APIUtils';
import ImageUtils from '../../utils/ImageUtils';
import AlertMessage from '../utilities/AlertMessage';
import { ReactComponent as CAPTURE } from '../../icons/camera_with_round.svg';
import { ReactComponent as FULLSCREEN } from '../../icons/fullscreen.svg';
import { ReactComponent as EXCLAMATION } from '../../icons/exclamation.svg';
import { WSSEvent } from 'anuro-platform-core';
import Tooltip from '@mui/material/Tooltip';
import { WSC } from '../../service/WSC';
import CctvItem from './cctv/CctvItem';
import { IconButton } from '@mui/material';

export const MODE = {
  normal: 'norma',
  delete: 'delete',
};

@observer
@inject('cctvStore')
export default class CCTVViewModal extends React.Component {
  emitter = EventEmitter.getInstance();
  apiUtils = new APIUtils();
  cctvItemRef = createRef();
  modalRef = createRef();

  player = null;

  state = {
    mode: MODE.normal,
    capturedImageList: [],
    selectAll: false,
    extendStorageImage: true,
    isVideoFullScreen: false,
    numberOfSelectedImg: 0,
    deleteProcess: { idx: 0, total: 0 },
    isStreaming: false,
  };

  componentDidMount() {
    WSC.getInstance().socket.on(WSSEvent[WSSEvent.FACILITY_CCTV_SNAPSHOT_ADD], this.onWSSEventOnSnapshotAdd);
    WSC.getInstance().socket.on(WSSEvent[WSSEvent.FACILITY_CCTV_SNAPSHOT_DEL], this.onWSSEventOnSnapshotDel);
    this.emitter.on('fetchCapturedImage', this.fetchCapturedImage);
    this.fetchCapturedImage();
    this.bringToFront();

    this.modalRef?.focus();
  }

  componentWillUnmount() {
    WSC.getInstance().socket.off(WSSEvent[WSSEvent.FACILITY_CCTV_SNAPSHOT_ADD], this.onWSSEventOnSnapshotAdd);
    WSC.getInstance().socket.off(WSSEvent[WSSEvent.FACILITY_CCTV_SNAPSHOT_DEL], this.onWSSEventOnSnapshotDel);
    this.emitter.off('fetchCapturedImage', this.fetchCapturedImage);
    try {
      console.log('[cctvViewModal Destroy]', this.props.selectedCCTV?.facilityCctv.id);
      this.player?.destroy?.();
    } catch (err) {
      console.log('[CCTVViewModal.js] player destroy catch => ', this.props.selectedCCTV?.facilityCctv?.id, err);
    }
  }

  onWSSEventOnSnapshotAdd = (event) => {
    this.setState((prev) => {
      return {
        capturedImageList: [event, ...prev.capturedImageList],
      };
    });
  };

  onWSSEventOnSnapshotDel = (event) => {
    const capturedImageList = [...this.state.capturedImageList];
    const idx = capturedImageList.findIndex((i) => i.uuid === event.uuid);
    if (idx <= -1) return;
    capturedImageList.splice(idx, 1);
    this.setState({
      capturedImageList: capturedImageList,
    });
  };

  // !!! 캡처된 이미지 목록 호출 API
  fetchCapturedImage = () => {
    const selectedCCTV = this.props.selectedCCTV;
    this.apiUtils
      .fetchApi(`/v1/me/organization/facility/cctv/${selectedCCTV?.facilityCctv?.id}/snapshot`, 'get', {
        ob: 'id',
        od: 'DESC',
      })
      .then((r) => {
        this.setState({
          capturedImageList: r.rows,
        });
      });
  };

  // !!! 이미지 캡처 버튼 누를 시
  onCapture = () => {
    if (!this.cctvItemRef?.current?.cctvCanvasRef?.current) return null;
    // if (this.state.capturedImageList.length > 99) return AlertMessage.alertMessage("error", "스냅샷을 100개 이상 보관할 수 없습니다.")
    const img = this.cctvItemRef?.current?.cctvCanvasRef?.current?.toDataURL('image/jpeg', 1);
    const selectedCCTV = this.props.selectedCCTV;

    const formData = new FormData();
    formData.append('ts', moment().unix());
    formData.append('image', ImageUtils.dataURLtoFile(img));

    this.apiUtils.fetchApi(`/v1/me/organization/facility/cctv/${selectedCCTV.facilityCctv.id}/snapshot`, 'post', { formData });
  };

  createEvent = (capturedImageList) => {
    const checkedImage = capturedImageList.length > 0 ? capturedImageList?.filter((i) => i.checked) : [];
    if (checkedImage.length > 0) {
      this.emitter.emit('changeEventListMode', { mode: MODE_WRITE, data: checkedImage });
    } else {
      this.emitter.emit('changeEventListMode', { mode: MODE_WRITE });
    }
  };

  countSelectedImg = () => {
    let cnt = this.state.capturedImageList?.filter((i) => i.checked)?.length;
    this.setState({
      numberOfSelectedImg: cnt,
      selectAll: this.state.capturedImageList?.length > 0 && cnt === this.state.capturedImageList?.length ? true : false,
    });
  };

  selectImg = (index, value) => {
    let tempArr = [...this.state.capturedImageList];
    tempArr[index].checked = value.target.checked;
    this.setState({ capturedImageList: tempArr }, () => this.countSelectedImg());
  };

  selectAll = (value) => {
    let tmpArr = this.state.capturedImageList?.map((i) => {
      return { ...i, checked: value };
    });
    this.setState(
      {
        capturedImageList: [...tmpArr],
      },
      () => this.countSelectedImg(),
    );
  };

  // !!! 캡처 이미지 삭제 시
  deleteCapturedImage = async () => {
    const result = await AlertMessage.confirmMessage(`${this.state.numberOfSelectedImg}개의 스냅샷 이미지를\n 삭제 하시겠습니까?`);
    if (!result) return null;
    this.setState(
      {
        mode: MODE.delete,
        deleteProcess: { idx: 1, total: this.state.numberOfSelectedImg },
      },
      () => {
        const numberOfSelectedImg = this.state.numberOfSelectedImg;
        let currentDeleteIdx = 1;

        this.state.capturedImageList?.map((i, idx) => {
          if (i.checked) {
            this.apiUtils.fetchApi(`/v1/me/organization/facility/cctv/${i.cctvId}/snapshot/${i.uuid}`, 'delete').then((r) => {
              if (this.state.deleteProcess) {
                this.setState({
                  deleteProcess: {
                    total: numberOfSelectedImg,
                    idx: currentDeleteIdx++,
                  },
                });
              }
            });
          }

          if (this.state.capturedImageList?.length - 1 === idx) {
            setTimeout(() => {
              this.fetchCapturedImage();
              this.setState({
                mode: MODE.normal,
              });
            }, 1000);
          }
        });
      },
    );
  };

  bringToFront = () => {
    let modalElement = document.getElementsByClassName('gis-cctv-modal');
    for (let i = 0; i < modalElement.length; i++) {
      if (Object.values(modalElement.item(i).classList).includes(`gis-cctv-modal-${this.props.index}`)) {
        modalElement.item(i).classList.add('bring-to-front');
      } else {
        modalElement.item(i).classList.remove('bring-to-front');
      }
    }
  };

  setIsStreaming = (isStreaming: boolean) => this.setState({ isStreaming: isStreaming });

  // !!! 삭제 시 진행바 렌더링
  renderProgressBar = () => {
    let progress = (this.state.deleteProcess.idx / this.state.deleteProcess.total) * 100;
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ marginRight: 8 }}>
            <img src={require('../../icons/refresh.png')} />
          </div>
          <div style={{ fontWeight: 'bold' }}>삭제중...</div>
        </div>
        <div
          style={{
            background: `linear-gradient(to right, #558B2F ${progress.toFixed(0) || 1}%, #757575 ${progress.toFixed(0) || 99}%)`,
            width: 'calc(100% - 100px)',
            height: 30,
            borderRadius: 5,
            lineHeight: '30px',
            textAlign: 'center',
            fontWeight: 'bold',
          }}>
          {`${this.state.deleteProcess.idx}/${this.state.deleteProcess.total}(${((this.state.deleteProcess.idx / this.state.deleteProcess.total) * 100).toFixed(1)})`}
        </div>
      </div>
    );
  };

  render() {
    const { selectedCCTV } = this.props;
    return (
      <ReactModal
        key={selectedCCTV.uuid}
        className={`gis-cctv-modal ${this.state.isVideoFullScreen ? 'unset' : ''} gis-cctv-modal-${this.props.index}`}
        initWidth={530}
        minWidth={530}
        minHeight={650}
        maxHeight={800}
        disableResize
        initHeight={'fit-content'}
        onFocus={() => this.bringToFront()}
        top={window.innerHeight / 5}
        left={window.innerWidth / 2}
        onRequestClose={() => {}}
        isOpen={true}>
        <div
          ref={(e) => (this.modalRef = e)}
          className={'body'}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === 'Escape') {
              this.props.modalHandler(false, this.props.selectedCCTV);
              this.selectAll(false);
              // this.props.modalHandler(selectedCCTV, false);
            }
          }}>
          <div className={'header'}>
            <div className={'place'}>{selectedCCTV?.name || ''}</div>
            <div
              className={'close'}
              onClick={() => {
                this.props.modalHandler(false, this.props.selectedCCTV);
                this.selectAll(false);
              }}>
              &times;
            </div>
          </div>
          <div className={'address'}>
            <FaMapMarkerAlt style={{ marginRight: 5 }} />
            <span>{selectedCCTV?.addressStreet || ''}</span>
          </div>
          <div className={'view-panel'}>
            <div className={`view ${this.state.isVideoFullScreen ? 'full-screen' : ''}`} id={'view'}>
              {this.state.isStreaming && (
                <>
                  <div className={`live ${this.state.isVideoFullScreen ? 'full-screen' : ''}`}>
                    LIVE <span style={{ marginLeft: 4, fontSize: 12 }}>●</span>
                  </div>
                  <div className={`video-btn ${this.state.isVideoFullScreen ? 'full-screen' : ''}`}>
                    <Tooltip title={'전체화면'} placement={'top'} arrow>
                      <div style={{ marginRight: 7 }} onClick={() => this.setState({ isVideoFullScreen: !this.state.isVideoFullScreen })}>
                        <FULLSCREEN />
                      </div>
                    </Tooltip>
                    <Tooltip title={'스냅샷'} placement={'top'} arrow>
                      <div key={selectedCCTV.cctvId} onClick={this.onCapture}>
                        {this.state.capturedImageList?.length >= 100 && (
                          <span style={{ position: 'absolute', left: 0, top: 0 }}>
                            <EXCLAMATION />
                          </span>
                        )}
                        <CAPTURE />
                      </div>
                    </Tooltip>
                  </div>
                </>
              )}

              <CctvItem key={this.props.selectedCCTV.uuid} ref={this.cctvItemRef} clickable={false} item={this.props.selectedCCTV} handleModal={this.props.modalHandler} showCctvName={false} setIsStreaming={this.setIsStreaming} />
            </div>

            {/* !!! CCTV 컨트롤 패널 */}
            <CCTVControlComponent cctv={selectedCCTV.facilityCctv} />
          </div>

          <div className={'place-name'}>
            <div>
              <img src={require('../../icons/cctv2.png')} style={{ margin: '0 8px' }} />
              <span style={{ fontWeight: 'bold', marginRight: 3, color: '#999999' }}>CCTV</span>
              <span
                style={{
                  fontSize: 12,
                  color: '#999999',
                  fontWeight: 'bold',
                }}>
                {selectedCCTV?.facilityCctv?.rtspPath ? `(/${selectedCCTV?.facilityCctv?.rtspPath})` : ''}
              </span>
            </div>
          </div>
          <div className={'divide'} />
          <div style={{ fontSize: 14, color: '#999999', textAlign: 'right', marginBottom: 10 }}>최대 100개까지의 스냅샷만 등록할 수 있습니다.</div>
          <div className={'bottom'}>
            <button
              disabled
              onClick={() => {
                this.setState({ extendStorageImage: !this.state.extendStorageImage });
              }}>
              스냅샷 보관함 <span style={{ fontWeight: 700, margin: '0 5px 0 3px' }}>{this.state.capturedImageList?.length || 0}</span>
            </button>
            <div>
              <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <input type={'checkbox'} id={`select-all-${selectedCCTV.cctvId}`} checked={this.state.selectAll} onChange={(e) => this.selectAll(e.target.checked)} />
                  <label style={{ color: '#999999', fontSize: 14 }} htmlFor={`select-all-${selectedCCTV.cctvId}`}>
                    전체선택
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div style={{ height: 'calc(50% - 50px)' }}>
            <div className={'capture-img scroll-b'}>
              {this.state.capturedImageList?.map((item, index) => {
                return (
                  <label htmlFor={`cctv-${item.cctvId}-${item.id}`} key={index}>
                    <input
                      id={`cctv-${item.cctvId}-${item.id}`}
                      type={'checkbox'}
                      checked={item.checked}
                      onChange={(value) => {
                        this.selectImg(index, value);
                      }}
                    />
                    <img key={`cctv-${item.cctvId}-${item.id}`} src={item.url} />
                    {item.checked && <div className={'cover'} />}
                  </label>
                );
              })}
            </div>
            {this.props.isActiveAttachmentBtn && <div style={{ fontSize: 11, color: '#999999', textAlign: 'right', padding: '10px 0' }}>최대 8개의 스냅샷을 첨부할 수 있습니다.</div>}
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              {this.state.mode === MODE.normal ? (
                <>
                  <Tooltip title={'삭제하기'} placement={'top'} arrow>
                    <IconButton onClick={this.deleteCapturedImage}>
                      <span>
                        <img src={require('../../icons/trash.png')} />
                      </span>
                    </IconButton>
                  </Tooltip>
                  {this.props.isActiveAttachmentBtn && (
                    <button
                      className={'create'}
                      style={{ margin: 0 }}
                      onClick={() => {
                        this.setState({});
                        this.createEvent(this.state.capturedImageList);
                      }}>
                      <img src={require('../../icons/attachment2.png')} style={{ marginRight: 9, objectFit: 'fill' }} />
                      {this.state.numberOfSelectedImg}개 첨부하기
                    </button>
                  )}
                </>
              ) : (
                this.renderProgressBar()
              )}
            </div>
          </div>
          {/*)}*/}
        </div>
        {this.apiUtils.renderLoading()}
      </ReactModal>
    );
  }
}
