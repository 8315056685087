import '../css/myPage/deleteFacility.css';

const Button = ({ variant, width, height, ...rest }) => {
	const primary = variant === 'primary';
	const style = {
		width: width ? width : '150px',
		height: height ? height : '45px',
		backgroundColor: primary ? '#242834' : 'white',
		color: primary ? 'white' : '#242834',
		minHeight: '40px',
		border: '1px solid #242834',
		borderRadius: '5px',
		fontSize: 16,
		fontWeight: 'bold',
		...rest.style,
	};
	return (
		<button {...rest} style={style} type={rest.type}>
			{rest.children}
		</button>
	);
};

export default Button;
