export default class EventEmitter {

	static emitter = null;

	constructor() {
		this.events = {};
	}

	static getInstance() {
		if(this.emitter === null) {
			this.emitter = new EventEmitter();
		}
		return this.emitter;
	}

	on(event, listener) {
		if (!this.events[event]) {
			this.events[event] = []
		}

		this.events[event].push(listener);
	}

	off(event) {
		delete this.events[event];
	}

	clearAll() {
		this.events = {};
	}

	emit(event, payload) {
		if (this.events[event]) {
			this.events[event].forEach(listener => {
				listener(payload)
			})
		}
	}
}
