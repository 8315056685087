import React from 'react';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import PermissionRoute from './PermissionRoute';
import ScrollToTop from '../components/ScrollToTop';
import SignIn from '../screens/auth/SignIn';
import GIS from '../screens/GIS';
import Event from '../screens/Event';
import UserManage from '../screens/UserManage';
import FacilityManage from '../screens/FacilityManage';
import Disaster from '../screens/Disaster';
import {WSC} from '../service/WSC';
import {logWithTs,WSSEvent} from 'anuro-platform-core';
import AlertMessage, {NotificationMessage} from '../components/utilities/AlertMessage';
import ChangePassword from '../screens/auth/ChangePassword';
import NotFoundScreen from '../screens/not-found/NotFoundScreen';
import ChangeOldPassword from '../screens/auth/ChangeOldPassword';
import TimeoutScreen, { TIMEOUT_TYPE } from '../screens/timeout/TimeoutScreen';
import { api_tta } from '../api/api_tta';
import { StorageUtils } from '../utils/StorageUtils';
import { inject } from 'mobx-react';
import WSHandler from "../components/base/WSHandler";

const REDIRECT_SIGN_IN = '/sign-in';

const unauthorizedOnly = {
  unauthorized: true,
  redirect: '/gis',
};

const timeToNone = 540000;
const timeToWarning = 60000;

@inject('sessionStore')
class Router extends React.Component {
  state = {
    isTimeout: false,
    timeoutType: TIMEOUT_TYPE.NONE,
    timeoutTime: timeToWarning,
  };
  path = '';
  timer = null;
  isCalled = false;
  wTimer = null;
  secondTimer = null;

  componentDidMount() {
    this.clearTimer();
    document.addEventListener('mousemove', (event, path) => this.clearTimer(event, path));
    document.addEventListener('keydown', (event, path) => this.clearTimer(event, path));

    WSC.getInstance().socket.on(WSSEvent[WSSEvent.SHUTDOWN], this.onServerShutdown);
    WSC.getInstance().socket.on(WSSEvent[WSSEvent.USER_DEL], this.onUserDelete);
  }

  componentWillUnmount() {
    this.clearTimer();
    document.removeEventListener('mousemove', (event, path) => this.clearTimer(path, event));
    document.removeEventListener('keydown', (event, path) => this.clearTimer(path, event));

    WSC.getInstance().socket.off(WSSEvent[WSSEvent.SHUTDOWN], this.onServerShutdown);
    WSC.getInstance().socket.off(WSSEvent[WSSEvent.USER_DEL], this.onUserDelete);
  }

  onServerShutdown = () => {
    AlertMessage.serverMessage(NotificationMessage.Server.shutdown);
  }

  onUserDelete = async (user) => {
    if(this.props.sessionStore.user.username === user.username) {
      // 브라우저에 저장된 JWT 토큰 삭제
      StorageUtils.removeAuthorization();

      // API 인스턴스에 저장된 Authorization 삭제
      api_tta.deleteHeader('Authorization');

      // Web Socket Client 연결 끊기
      WSC.getInstance().stop(true);

      try {
        typeof this.props.sessionStore?.reset === 'function' && this.props.sessionStore?.reset();
      } catch (e) {}

      this.forceUpdate();

      setTimeout(() => {
        AlertMessage.alertMessage("error", <div>
          <div style={{marginBottom: 5}}>관리자에 의해 삭제된 계정으로</div>
          <div>해당 계정을 더이상 사용할 수 없습니다.</div>
        </div>);
      }, 300);
    }
  }

  setPath = (path: string) => {
    this.path = path;
    this.clearTimer();
  };

  clearTimer = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }

    if (this.path === '/sign-in' || this.path === '/change-password' || this.path === '/change-old-password') this.isCalled = false;
    if (this.isCalled || this.path === '/sign-in' || this.path === '/change-password' || this.path === '/change-old-password') return;
    if (this.state.timeoutType !== TIMEOUT_TYPE.NONE) return;

    this.timer = setTimeout(() => {
      this.setState(
        {
          isTimeout: true,
          timeoutType: TIMEOUT_TYPE.WARNING,
          timeoutTime: timeToWarning,
        },
        () => {
          this.timer = null;
          this.warningTimer();
          // this.logout();
        },
      );
      this.isCalled = true;
    }, timeToNone); // 1분 세팅
  };

  warningTimer = () => {
    if (this.wTimer) {
      clearTimeout(this.wTimer);
      this.wTimer = null;
    }
    if (this.secondTimer) {
      clearInterval(this.secondTimer);
      this.secondTimer = null;
    }

    this.secondTimer = setInterval(() => {
      this.setState((prev) => {
        let t = prev.timeoutTime;
        if (t < 1000) t = 0;
        return {
          timeoutTime: t - 1000,
        };
      });
    }, 1000);
    this.wTimer = setTimeout(() => {
      this.setState(
        {
          timeoutType: TIMEOUT_TYPE.TIMEOUT,
        },
        () => {
          this.logout();
        },
      );
    }, timeToWarning);
  };

  onClose = () => {
    this.setState({ isTimeout: false, timeoutType: TIMEOUT_TYPE.NONE }, () => {
      clearTimeout(this.wTimer);
      clearTimeout(this.secondTimer);
      this.secondTimer = null;
      this.wTimer = null;
      this.isCalled = false;
      this.clearTimer();
    });
  };

  logout = async () => {
    try {
      const res = await api_tta.delete('/v1/auth/session');
      if (res.ok) {
        logWithTs('세션삭제 성공');
      } else {
        logWithTs('세션삭제 실패', res);
      }
    } catch (err) {
      logWithTs(err);
    }
  };

  render() {
    return (
      <BrowserRouter>
        <ScrollToTop />

        <WSHandler/>

        <Switch atEnter={{ opacity: 0 }} atLeave={{ opacity: 0 }} atActive={{ opacity: 1 }} className='switch-wrapper'>
          <PermissionRoute title={'로그인'} path={`/sign-in`} component={SignIn} {...unauthorizedOnly} setPath={this.setPath} />

          <PermissionRoute title={'비밀번호 변경'} path={`/change-password`} exact component={ChangePassword} authorized setPath={this.setPath} />
          <PermissionRoute title={'비밀번호 변경'} path={`/change-old-password`} exact component={ChangeOldPassword} authorized setPath={this.setPath} redirect={REDIRECT_SIGN_IN} />
          <PermissionRoute title={'GIS'} path={`/gis`} exact component={GIS} authorized redirect={REDIRECT_SIGN_IN} setPath={this.setPath} />
          <PermissionRoute title={'CCTV 관리'} path={`/facility`} exact component={FacilityManage} authorized redirect={REDIRECT_SIGN_IN} setPath={this.setPath} />
          <PermissionRoute title={'이벤트'} path={`/event`} exact component={Event} authorized redirect={REDIRECT_SIGN_IN} setPath={this.setPath} />
          <PermissionRoute title={'사용자 관리'} path={`/user-management`} exact component={UserManage} authorized redirect={REDIRECT_SIGN_IN} allowType={['SUPER_USER']} setPath={this.setPath} />
          <PermissionRoute title={'재난정보'} path={`/disaster`} exact component={Disaster} authorized redirect={REDIRECT_SIGN_IN} setPath={this.setPath} />

          {/* my-page */}
          {/*<PermissionRoute title={'My Page'} path={`/my-page`} exact component={MyPage} authorized redirect={REDIRECT_SIGN_IN} />*/}

          <Route path={'/404'} component={NotFoundScreen} />
          <Redirect path={'/'} to={'/gis'} />
          {/*<Route component={NotFound} />*/}
        </Switch>
        {this.state.isTimeout && <TimeoutScreen onClose={this.onClose} type={this.state.timeoutType} logout={this.logout} leftTime={this.state.timeoutTime} />}
      </BrowserRouter>
    );
  }
}

export default Router;
