import React, {createRef} from 'react';
import SettingBaseModal from './SettingBaseModal';
import APIUtils from '../../utils/APIUtils';
import {ReactComponent as LOCK} from '../../icons/lock.svg';
import {ReactComponent as UNLOCK} from '../../icons/unlock.svg';
import {Validator} from "anuro-platform-core";
import AlertMessage from "../utilities/AlertMessage";
import PasswordPolicyComponent from "./PasswordPolicyComponent";
import Input from "../input/Input";


export default class ChangePasswordModal extends React.Component {
  refPasswordPolicy = createRef();

  apiUtils = new APIUtils();

  state = {
    focusKey: 'BeforePassword',
    isBeforePasswordHide: true,
    isNewPasswordHide: true,
    beforePassword: "",
    newPassword: "",
  };

  componentDidMount() {
    setTimeout(() => {
      document.getElementById("change-password-modal2").focus();
    }, 300);
  }

  // !!! 비밀번호 입력 폼 렌더링
  passwordInputComponent = (key: 'BeforePassword' | 'NewPassword') => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          borderRadius: 4,
          padding: "15px 20px",
          margin: '20px 0',
          boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.35)',
          backgroundColor: this.state.focusKey === key ? '#3F4452' : '#FFFFFF',
        }}
        onFocus={() => {
          this.setState({ focusKey: key });
        }}>
        {this.state.focusKey === key ? <UNLOCK /> : <LOCK />}
        <Input
          id={key}
          maxLength={100}
          value={key === "BeforePassword" ? this.state.beforePassword : this.state.newPassword}
          placeholder={key === 'BeforePassword' ? '현재 비밀번호 입력' : '변경할 비밀번호 입력'}
          onChange={(e) => {this.setState({[key === "BeforePassword" ? "beforePassword" : "newPassword"]: e.target.value})}}
          style={{
            width: '100%',
            margin: '0 10px',
            color: this.state.focusKey === key ? '#FFFFFF' : '#333333',
            backgroundColor: this.state.focusKey === key ? '#3F4452' : '#FFFFFF',
          }}
          type={this.state['is' + key + 'Hide'] ? 'password' : 'text'}
          onKeyDown={e => {
            // if(e.code === "Escape" && key === "BeforePassword" && this.props.onClose) this.props.onClose();
            if(e.code === "Enter" && key === "NewPassword") this.patchChangePassword()
          }}
        />
      </div>
    );
  };

  // !!! 비밀번호 변경 api
  patchChangePassword = async () => {
    const {beforePassword, newPassword} = this.state;
    if(beforePassword.length === 0) return AlertMessage.alertMessage("error", "현재 비밀번호를 입력해주세요");

    if (!this.refPasswordPolicy.current?.isAvailable) {
      const validateResult = Validator.validatePassword(newPassword);
      if (validateResult.errors.length > 0) {
        const message = this.refPasswordPolicy.current?.getValidationText?.(validateResult.errors[0]) || validateResult.errors[0].toString();
        AlertMessage.alertMessage("error", message.includes("공백불가") ? "공백을 입력할 수 없습니다." : `${message} 입력해야합니다`);
      } else {
        AlertMessage.alertMessage("error", '새로운 비밀번호가 올바르지 않습니다');
      }
      return;
    }

    const result = await AlertMessage.confirmMessage("정말 비밀번호를 변경하시겠습니까?");
    if(!result) return null;

    this.apiUtils.fetchApi("/v1/me/password", "patch", {
      before: beforePassword,
      after: newPassword
    }).then(r => {
      AlertMessage.alertMessage("success", "비말번호가 변경되었습니다.");
      this.props.onClose();
    });
  }

  render() {
    const { onClose } = this.props;
    return (
      <SettingBaseModal id="change-password-modal2" open={true} onClose={() => onClose(false)} title={this.props.title} width={460} withBackground>
        <div className={'change-password-modal'} >
          <div>
            {this.passwordInputComponent('BeforePassword')}
            {this.passwordInputComponent('NewPassword')}
            <div style={{marginTop: 40, position: "relative", borderRadius: 4, border: "1px solid #ccc", padding: "25px 20px 15px 20px"}}>
              <PasswordPolicyComponent ref={this.refPasswordPolicy} password={this.state.newPassword} />
            </div>
          </div>
          <div className={'btn-wrapper'}>
            <div className={'btn2 cursor'} onClick={() => onClose(false)} tabIndex={0} onKeyDown={(e) => {e.code === "Enter" && onClose(false)}}>
              취소
            </div>
            <div className={'btn2 close cursor'} onClick={this.patchChangePassword} tabIndex={0} onKeyDown={(e) => {e.code === "Enter" && this.patchChangePassword()}}>
              변경
            </div>
          </div>
        </div>
        {this.apiUtils.renderLoading()}
      </SettingBaseModal>
    );
  }
}
