const ModalStyle = (width, height, padding) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: width,
    minHeight: height,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: padding,
    color: 'black',
    backgroundColor: 'white',
    borderRadius: '15px',
    transition: 'width 0.8s, height 0.8s',
  };
  return style;
};

export default ModalStyle;
