import React, { createRef } from 'react';
import CCTVViewModal from './CCTVViewModal';
import { inject, observer } from 'mobx-react';
import EventEmitter from '../../utils/EventEmitter';
import APIUtils from '../../utils/APIUtils';
import {WSSEvent} from 'anuro-platform-core';
import Pagination from '@mui/material/Pagination';
import {MODE_WRITE} from "./EventListPanelComponent";
import {WSC} from "../../service/WSC";
import CctvItem from "./cctv/CctvItem";
import {IFacility} from "../../models/IFacility";
import AlertMessage from "../utilities/AlertMessage";

const LIMIT_CCTV_NUMBER = 6;

@inject('cctvStore')
@observer
export default class CCTVSideComponent extends React.Component {
  emitter = EventEmitter.getInstance();
  apiUtils = new APIUtils();
  cctvCanvasRef = [];

  playerList = [];

  state = {
    isOpenCCTV: [],
    captureImg: [],

    totalPage: 1,
    currentPage: 1,
    isActiveAttachmentBtn: false,
  };

  componentDidMount() {
    this.emitter.on('fetchCCTVList', this.fetchCCTVList);
    this.emitter.on('activeAttachment', this.activeAttachment);
    this.emitter.on("closeAllCctvModal", this.closeAllCctvModal);
    WSC.getInstance().socket.on(WSSEvent[WSSEvent.FACILITY_DEL], this.onWSSEventOnFacilityDel);
    WSC.getInstance().socket.on(WSSEvent[WSSEvent.FACILITY_CCTV_SNAPSHOT_ADD], (event) => this.onWSSEventOnSnapshot(event, "ADD"));
    WSC.getInstance().socket.on(WSSEvent[WSSEvent.FACILITY_CCTV_SNAPSHOT_DEL], (event) => this.onWSSEventOnSnapshot(event, "DEL"));

    this.fetchCCTVList();
    this.props.cctvStore.fetchCapturedImage();
  }

  componentWillUnmount() {
    this.emitter.off('fetchCCTVList', this.fetchCCTVList);
    this.emitter.off('activeAttachment', this.activeAttachment);
    this.emitter.off("closeAllCctvModal", this.closeAllCctvModal);
    WSC.getInstance().socket.off(WSSEvent[WSSEvent.FACILITY_DEL], this.onWSSEventOnFacilityDel);
    WSC.getInstance().socket.off(WSSEvent[WSSEvent.FACILITY_CCTV_SNAPSHOT_ADD]);
    WSC.getInstance().socket.off(WSSEvent[WSSEvent.FACILITY_CCTV_SNAPSHOT_DEL]);
  }

  onWSSEventOnFacilityDel = (facility: IFacility) => {
    AlertMessage.alertMessage("info", `${facility.id}번 CCTV가 삭제되어 목록이 업데이트 됩니다.`);
    this.fetchCCTVList(this.state.currentPage);
    this.handleModal(false, facility);
  }

  onWSSEventOnSnapshot = (event, type: "ADD" | "DEL") => {
    const {cctvStore} = this.props;
    if (type === "ADD") {
      cctvStore.addCaptureImageList(event);
    } else if (type === "DEL") {
      cctvStore.deleteCaptureImage(event.id);
    }
  }

  activeAttachment = (listMode) => {
    this.setState({
      isActiveAttachmentBtn: listMode === MODE_WRITE
    })
  }

  closeAllCctvModal = () => {
    this.setState({ isOpenCCTV: [] });
  }

  // !!! CCTV 목록 호출 함수
  fetchCCTVList = (page = 1) => {
    this.playerList = [];
    this.props.cctvStore.workingCCTVList = [];
    this.apiUtils.fetchApi('/v1/me/organization/facility', 'get', {
      limit: LIMIT_CCTV_NUMBER,
      offset: page ? (page - 1) * LIMIT_CCTV_NUMBER : 0,
      ob: 'id',
      od: 'DESC',
    }).then((r) => {
      this.setState({
        totalPage: Math.ceil(r.count / LIMIT_CCTV_NUMBER),
        currentPage: page,
      });
      this.props.cctvStore.setCCTVList = r.rows.map((i) => {
        this.cctvCanvasRef.push(createRef());
        return { ...i, click: '', isOpen: false };
      });
      this.emitter.emit('onChangeMarkerData', { key: 'cctvList', value: this.props.cctvStore.cctvList });
    });
  };


  handleModal = (isOpen: boolean, item: IFacility) => {
    const isOpened = this.state.isOpenCCTV.findIndex(i => i.uuid === item.uuid);
    const _isOpen = isOpened > -1 ? false: isOpened;

    this.setState((prev) => {
      let tempOpenCctv = [...prev.isOpenCCTV];
      if(_isOpen) {tempOpenCctv.push(item)}
      else {
        const idx = tempOpenCctv.findIndex(i => i?.uuid === item?.uuid);
        if(idx === -1) return ;
        tempOpenCctv.splice(idx, 1);
      }
      return {
        isOpenCCTV: [...tempOpenCctv]
      }
    }, () => {
      isOpen && this.emitter.emit('moveTo', { lat: item.latitude, lng: item.longitude });
    })
  }

  renderCCTVItems = () => {
    return this.props.cctvStore.getCCTVList.map((item, index) => {
      return (
        <div key={item.uuid} style={{width: 255, height: 130, marginBottom: 10}}>
          {
            this.props.open && <CctvItem key={item.uuid} item={item} handleModal={this.handleModal}/>
          }
        </div>
      );
    });
  }


  renderEmpty = () => (
    <div className={'view-item'} style={{ minWidth: 255, color: '#FFFFFF', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      CCTV 목록이 없습니다
    </div>
  );

  render() {
    const { cctvStore } = this.props;
    return (
      <>
        <div className={this.props.open ? 'cctv-monitoring-bar open' : 'cctv-monitoring-bar close'}>
          {cctvStore.cctvList.length > 0 &&
            <div className={'cctv-list scroll-c'} style={{overflowX: "hidden"}}>{this.renderCCTVItems()}</div>
          }
          {
            cctvStore.cctvList.length > 0 &&
            <Pagination
              onChange={(e, p) => this.fetchCCTVList(p)} count={this.state.totalPage} page={this.state.currentPage}
              shape='rounded'
              siblingCount={0}
              style={{ justifyContent: "center !important", color: '#FFFFFF !important' }}
              sx={{
                button: {
                  color: '#FFFFFF',
                  fontSize: 12,
                  margin: '0 2px',
                  backgroundColor: '#FFFFFF30',
                },
              }}
            />
          }
          {this.apiUtils.renderLoading()}
        </div>
        {cctvStore.cctvList.length === 0 && this.renderEmpty()}

        {this.state.isOpenCCTV.map((item, index) => {
          return <CCTVViewModal key={index} index={index} selectedCCTV={item} modalHandler={this.handleModal} fetchCCTVList={this.fetchCCTVList} isActiveAttachmentBtn={this.state.isActiveAttachmentBtn}/>;
        })}
      </>
    );
  }
}
