import React, {useState} from 'react';
import '../../../css/myPage/userDetail.css';
import ProfileIcon from '../../../icons/profile_icon.png';
import BaseModal from '../../BaseModal';
import {fetchApi} from '../../../utils/APIUtils';
import Divider from '../../Divider';
import EditInfoModal from './EditInfoModal';
import AlertMessage from '../../utilities/AlertMessage';
import StringUtils from "../../../utils/StringUtils";

const renderType = (rowInfo) => {
  let MASTER = rowInfo?.groups && rowInfo?.groups[0]?.ThroughGroupUser?.master === 1;
  if (MASTER) {
    return '관리자';
  } else {
    return '일반';
  }
};

const UserDetailModal = ({ open, setOpen, rowInfo, isDeleteOpen, fetchTable, setIsPermissionFetched }) => {
  const [editOpen, setEditOpen] = useState(false);

  const margin = 15;

  const handleClose = () => {
    setOpen(false);
  };

  const openDeleteModal = async () => {
    const result = await AlertMessage.confirmMessage(`${rowInfo.username}(${rowInfo.profile?.name})을 삭제하시겠습니까? \n 삭제한 후에는 되돌릴 수 없습니다.`);
    if (!result) return;
    return fetchApi(`/v1/me/organization/user/${rowInfo.uuid}`, `delete`).then(() => {
      AlertMessage.alertMessage('success', '삭제가 성공적으로 완료되었습니다');
      fetchTable();
      setOpen(false);
    });
  };

  const openEditModal = () => {
    setEditOpen(true);
  };

  return (
    <BaseModal title={''} width={520} height={480} open={open} leftBtnLabel={'삭제'} rightBtnLabel={'정보 수정'} closeIcon handleLeftBtn={openDeleteModal} handleClose={handleClose} handleRightBtn={openEditModal}>
      <div className='user-detail-modal-container'>
        <div className='header-container'>
          <img className='icon' src={ProfileIcon} alt={'profile'} />
          <div className='name'>{rowInfo?.profile?.name}</div>
          <div className='department'>{rowInfo?.groups ? rowInfo.groups[0]?.name : null}</div>
        </div>
        <div className='detail-container'>
          <Divider marginTop={margin} marginBottom={margin} />
          <div className='each-detail'>
            <div className='title'>아이디</div>
            <div className={'detail'}>{rowInfo?.username}</div>
          </div>
          <Divider marginTop={margin} marginBottom={margin} />
          <div className='each-detail'>
            <div className='title'>전화번호</div>
            <div className={'detail'}>{rowInfo?.profile?.phoneNumber ? StringUtils.formatPhoneNumber(rowInfo.profile.phoneNumber) : ''}</div>
          </div>
          <Divider marginTop={margin} marginBottom={margin} />
          <div className='each-detail'>
            <div className='title'>계정 권한</div>
            <div className={'detail'}>{renderType(rowInfo)}</div>
          </div>
          <Divider marginTop={margin} marginBottom={margin} />
        </div>
      </div>
      {/* 수정 - U */}
      <EditInfoModal open={editOpen} setOpen={setEditOpen} rowInfo={rowInfo} fetchTable={fetchTable} setIsPermissionFetched={setIsPermissionFetched} />
    </BaseModal>
  );
};

export default UserDetailModal;
