import axios from "axios";
import {AddressSearchComfmKey, AddressToCoorComfmKey} from "../constants/Constants";

const searchAddrUrl = "https://www.juso.go.kr/addrlink/addrLinkApi.do";
const addrToCoordUrl = "https://www.juso.go.kr/addrlink/addrCoordApi.do";

export default class Address {
	address_name: string;
	category_group_code: string;
	category_group_name: string;
	category_name: string;
	distance: string;
	id: string;
	phone: string;
	place_name: string;
	place_url: string;
	road_address_name: string;
	x: string;
	y: string;

	constructor(address) {
		Object.assign(this, address);
	}

	static searchAddress = async (keyword): Address[] => {
		const result = await axios.get("https://dapi.kakao.com/v2/local/search/keyword.json", {
			params: {
				format: "json",
				query: keyword,
			},
			headers: {
				Authorization: "KakaoAK 20318e0b5a5752f9496543a97fe7cb00"
			}
		});

		let tempAddrList = [];
		let tempResult = [];

		result.data.documents.map(i => {
			if (!tempAddrList.includes(i?.road_address_name)) {
				tempAddrList.push(i.road_address_name);
				tempResult.push(i);
			}
		});
		return tempResult;
	}

	static addrToCoord = async (admCd, rnMgtSn, buldMnnm, buldSlno) => {
		const result = await axios.get(addrToCoordUrl, {
			params: {
				confmKey: AddressToCoorComfmKey,
				admCd: admCd,
				rnMgtSn: rnMgtSn,
				udrtYn: 0,
				buldMnnm: buldMnnm,
				buldSlno: buldSlno,
				resultType: "json",
			}
		});
		return result;
	}
}
