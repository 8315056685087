import React, { useEffect, useState } from 'react';
import '../css/myPage/userManage.css';
import CommonHeader from '../components/HeaderComponent';
import APIUtils from '../utils/APIUtils';
import Container from '../components/Container';
import Table from '../components/Table/Table';
import { ReactComponent as REFRESH } from '../icons/refresh2.svg';
import '../css/statistics.css';
import Pagination from "@mui/material/Pagination";

const apiUtils = new APIUtils();

const LIMIT_ROWS_NUMBER = 10;

const tableNames = {
  ELIKMAINFORM: { en: 'ELIKMAINFORM,', ko: '기상특보' },
  ELIKMAEARTHINFM: { en: 'ELIKMAEARTHINFM', ko: '지진현황' },
};

const FIELD = {
  ELIKMAEARTHINFM: [
    {no: 1, column: "SEQ_NO", label: "연번", width: 70},
    {no: 2, column: "LK_INFO_ID", label: "연계정보아이디", width: 130},
    {no: 3, column: "RCV_YMD_HMS", label: "수신일자시각", width: 170},
    {no: 4, column: "OCR_YMDHMS", label: "발생시분초", width: 170},
    {no: 5, column: "POINT_X", label: "좌표X", width: 100},
    {no: 6, column: "POINT_Y", label: "좌표Y", width: 100},
    {no: 7, column: "LOC", label: "위치", width: 280},
    {no: 8, column: "EARTH_INFM_SCLE", label: "지진규모", width: 100},
    {no: 9, column: "EARTH_INFM_NO_ORD", label: "지진등급", width: 100},
    {no: 10, column: "EARTH_INFM_NO_REF", label: "지진참고번호", width: 100},
    {no: 11, column: "EARTH_INFM_CD_STN", label: "지진지점번호", width: 100},
  ],
  ELIKMAINFORM: [
    {no: 1, column: "SEQ_NO", label: "연번", width: 70},
    {no: 2, column: "LK_INFO_ID", label: "연계정보아이디", width: 130},
    {no: 3, column: "RCV_YMD_HMS", label: "수신일자시작", width: 170},
    {no: 4, column: "KMA_YMDHMS", label: "발표일시분초", width: 170},
    {no: 5, column: "KMA_SEQ_NO", label: "발표연번", width: 100},
    {no: 6, column: "KMA_TITLE", label: "발표제목", width: 200},
    {no: 7, column: "KMA_STAT_CTNT", label: "발표내용", width: 400},
    {no: 8, column: "KMA_SECT_AREA", label: "지역", width: 300},
    {no: 9, column: "KMA_PW_VL", label: "예비특보", width: 300},
    {no: 10, column: "KMA_CD_STN", label: "지점번호", width: 100},
  ]
}

const REFRESH_STATUS = {
  NOT_START: '자동갱신',
  START: '5초 뒤 갱신',
  ING: '갱신중...',
  FAILED: '갱신실패',
};

const Disaster = () => {
  const [datas, setDatas] = useState({});
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [selectedTable, setSelectedTable] = useState('ELIKMAINFORM');
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const [refreshTxt, setRefreshTxt] = useState(REFRESH_STATUS.NOT_START);
  const [isAutoRefresh, setIsAutoRefresh] = useState(false);

  let refreshStatus = REFRESH_STATUS.NOT_START;
  let timer = null;

  useEffect(() => {
    fetchDisasterData();
    return () => {
      timer && clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    fetchDisasterData(1);
  }, [selectedTable]);

  const fetchDisasterData = (page = 1) => {
    if (refreshStatus === REFRESH_STATUS.ING) {
      setRefreshTxt(REFRESH_STATUS.ING);
      clearInterval(timer);
    }
    apiUtils
      .fetchApi('/v1/me/organization/disaster', 'get', {
        ob: 'SEQ_NO',
        od: 'DESC',
        limit: LIMIT_ROWS_NUMBER,
        offset: page ? (page - 1) * LIMIT_ROWS_NUMBER : 0,
      })
      .then((r) => {
        setTimeout(() => {
          setDatas(r);
          fetchRows(r, page);
          if (refreshStatus === REFRESH_STATUS.ING) {
            refresh();
          }
        }, 1000);
      })
      .catch((err) => {
        if (refreshStatus === REFRESH_STATUS.ING) {
          setRefreshTxt(REFRESH_STATUS.FAILED);
          setTimeout(() => {
            setIsAutoRefresh(false);
            setRefreshTxt(REFRESH_STATUS.NOT_START);
          }, 2000);
        }
      });
  };

  const fetchRows = (data, page = 1) => {
    const r = data[selectedTable];

    if (r?.rows?.length > 0) {
      const objKeys = Object?.keys(data[selectedTable]?.rows?.[0]);
      let tempColumns = [];
      objKeys.map((i, idx) => {
        const table = FIELD?.[selectedTable];
        const findIdx = FIELD?.[selectedTable].findIndex(j => j.column === i);
        if(!table) return null;
        if(findIdx < 0) return null;
        tempColumns.push({
          no: table[findIdx]?.no,
          field: i,
          headerName: table[findIdx]?.label || '',
          // minWidth: 150,
          sortable: false,
          width: FIELD?.[selectedTable]?.[findIdx]?.width,
        });
      });
      setColumns(tempColumns.sort((a,b) => a.no - b.no));
      setTotalPage(Math.ceil(data[selectedTable].count / LIMIT_ROWS_NUMBER));
      setCurrentPage(page);
      setRows(
        data[selectedTable].rows.map((i, idx) => {
          return { no: idx, ...i };
        }),
      );
    } else {
      setColumns([]);
    }
  };

  const renderSelect = () => {
    return Object.keys(tableNames).map((i, idx) => {
      return (
        <option key={idx} value={i}>
          {tableNames[i].ko} ({i})
        </option>
      );
    });
  };

  useEffect(() => {
    if (isAutoRefresh) {
      refreshStatus = REFRESH_STATUS.ING;
      refresh();
    } else {
      setRefreshTxt(REFRESH_STATUS.NOT_START);
      timer = null;
    }
    return () => {
      clearInterval(timer);
    };
  }, [isAutoRefresh]);

  const refresh = () => {
    let cnt = 5;
    setRefreshTxt(cnt-- + '초 뒤 갱신');
    timer = setInterval(() => {
      setRefreshTxt(cnt-- + '초 뒤 갱신');
      if (cnt === -1) {
        refreshStatus = REFRESH_STATUS.ING;
        fetchDisasterData();
      }
    }, 1000);
  };

  return (
    <Container>
      <CommonHeader pageTitle={''} />

      <div className='user-container disaster-container'>
        <div className='content'>
          <div className='right'>
            <h3 className={'header'} style={{ color: 'white' }}>
              재난 상황관리
            </h3>
            <div className='search-title' style={{marginBottom: 10}}>상황 조회</div>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <select
                style={{ padding: 10, borderRadius: 4, width: 300 }}
                value={selectedTable}
                onChange={(e) => {
                  setIsAutoRefresh(false);
                  // setRows([]);
                  setSelectedTable(e.target.value);
                }}>
                {renderSelect()}
              </select>
              <div
                onClick={() => {
                  fetchDisasterData();
                  setIsAutoRefresh(!isAutoRefresh);
                }}
                style={{
                  color: 'white',
                  width: 135,
                  borderRadius: 5,
                  backgroundColor: refreshTxt === REFRESH_STATUS.NOT_START ? '#496DED' : refreshTxt === REFRESH_STATUS.FAILED ? '#EC3900' : '#1C4F7E',
                  cursor: 'pointer',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  padding: '10px 12px',
                }}>
                <REFRESH className={refreshTxt === '갱신중...' ? 'indicator run' : 'indicator stop'} style={{ marginRight: 10 }} />
                {refreshTxt}
              </div>
            </div>
            <div style={{ width: '100%', height: '70%', position: 'relative' }}>
              <Table
                columns={columns}
                rows={rows}
                unlimited
                getRowId={(row: any) => row.no}
                componentsProps={{
                  row: {
                    style: {
                      background: 'linear-gradient(90.12deg, #313644 0.02%, #1E2530 79.25%)',
                      fontWeight: 'bold',
                      borderRadius: 10,
                      marginBottom: 10,
                    },
                  },
                }}
              />

              {
                rows.length > 0 &&
                <div style={{display: "flex", justifyContent: "center"}}>
                  <Pagination
                    onChange={(e, p) => fetchDisasterData(p)} count={totalPage} page={currentPage}
                    shape='rounded'
                    siblingCount={0}
                    style={{ justifyContent: "center !important", color: '#FFFFFF !important' }}
                    sx={{
                      button: {
                        color: '#FFFFFF',
                        fontSize: 12,
                        margin: '0 2px',
                        backgroundColor: '#FFFFFF30',
                      },
                    }}
                  />
                </div>
              }
              {apiUtils.renderLoading()}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Disaster;
