import { useState } from 'react';

const useInput = (initialValue) => {
	const [value, setValue] = useState(initialValue);

	const onChangeInput = (e) => {
		const targetValue = e.target.value;
		setValue(targetValue);
	};

	return { value, onChange: onChangeInput };
};

export default useInput;
