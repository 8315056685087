import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { JWTUtils } from '../utils/JWTUtils';
import { inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { api_tta } from '../api/api_tta';
import { logWithTs } from 'anuro-platform-core';
import { StorageUtils } from '../utils/StorageUtils';
import { WSC } from '../service/WSC';

// noinspection DuplicatedCode
class PermissionRoute extends React.PureComponent {
  static defaultProps = {
    title: '스마트시티 플랫폼',
    authorized: false,
    unauthorized: false,
    redirect: '/sign-in',
  };

  static propTypes = {
    path: PropTypes.string.isRequired,
    exact: PropTypes.bool,
    title: PropTypes.string,
    authorized: PropTypes.bool, // Has accessible permission to Authorized session
    unauthorized: PropTypes.bool, // Has accessible permission to UnAuthorized session
    allowType: PropTypes.array,
    redirect: PropTypes.string,
    component: PropTypes.any,
    render: PropTypes.func,
    setPath: PropTypes.func,
  };

  renderRedirect = (path: string = '', from: string, params: Object) => {
    console.log('Redirect to ', path, 'from', from);
    return <Redirect to={{ pathname: path, state: { from, ...params } }} />;
  };

  render() {
    const title: string = this.props.title;
    this.props.setPath && this.props.setPath(this.props.path);

    if (title) {
      document.querySelector('title').innerText = title; // HTML <title></title> 내용을 변경
    }

    if (this.props?.history?.action === 'POP' && (this.props.path === '/change-password' || this.props.path === '/change-old-password')) {
      // alert('올바르지 않은 접근법입니다');
      return this.renderRedirect(this.props.redirect);
    }

    /** Check session is accessible to this route */
    if (!this.props.authorized || !this.props.unauthorized) {
      // 인증된 / 인증되지 않은 세션에서 접근 불가능할 경우
      const authenticated = JWTUtils.isAuthenticated();
      const sessionStore = this.props.sessionStore;
      if (!this.props.authorized) {
        // 인증된 세션에서 접근 불가능할 때
        if (authenticated) {
          // 인증된 세션일 때
          return this.renderRedirect(this.props.redirect);
        }
      }

      if (!this.props.unauthorized) {
        if (!this.props.path.includes('change-password') && sessionStore.user?.shouldChangePassword === 1) {
          return this.renderRedirect('/change-password');
        }
        // 인증되지 않은 세션에서 접근 불가능한 경우
        if (!authenticated) {
          console.log('[Line:56 !authenticated]', !authenticated);
          // 인증되지 않은 세션일 때
          return this.renderRedirect(this.props.redirect);
        }
      }
    }

    if (this.props.allowType) {
      if (Array.isArray(this.props.allowType)) {
        const sessionStore = this.props.sessionStore;
        if (!this.props.allowType.includes(sessionStore?.user?.type)) {
          return this.renderRedirect('/sign-in');
        }
      }
    }

    const { component: Component } = this.props;
    const render = this.props.render;
    return (
      <Route
        {...this.props}
        render={(props) => {
          if (render) return render(props); // render 함수가 있을 경우
          return <Component {...props} />;
        }}
      />
    );
  }
}

export default withRouter(inject('sessionStore')(PermissionRoute));
