import React, { createRef } from 'react';
import '../../css/changePassword.css';
import { withRouter } from 'react-router-dom';
import PasswordPolicyComponent from '../../components/settings/PasswordPolicyComponent';
import { WSC } from '../../service/WSC';
import { api_tta } from '../../api/api_tta';
import { StorageUtils } from '../../utils/StorageUtils';
import { logWithTs, Validator } from 'anuro-platform-core';
import type SessionStore from '../../stores/SessionStore';
import APIUtils from '../../utils/APIUtils';
import AlertMessage from '../../components/utilities/AlertMessage';
import Footer from '../../components/Footer';

interface Props {
  history: any;
  sessionStore: SessionStore;
}

interface State {
  currentPwdValue: string;
  newPwdValue: string;
}

class ChangePassword extends React.Component<Props, State> {
  refPasswordPolicy: any = createRef();
  apiUtils = new APIUtils();

  state = {
    currentPwdValue: '',
    newPwdValue: '',
  };

  componentDidMount() {
    // 페이지를 떠날 때 사용자에게 경고 메시지를 표시
    window.addEventListener('beforeunload', this.handleBeforeUnload);
  }

  componentWillUnmount() {
    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  }

  handleBeforeUnload = (event) => {
    // 사용자에게 표시할 경고 메시지
    event.preventDefault();
    event.returnValue = '변경사항을 저장하지 않았습니다. 정말로 페이지를 떠나시겠습니까?';

    // 경고 메시지를 반환하면 브라우저가 경고 창을 표시
    return this.logout();
  };

  handleChangePwd = async () => {
    const { currentPwdValue, newPwdValue } = this.state;
    if (currentPwdValue.length === 0) return AlertMessage.alertMessage('error', '현재 비밀번호를 입력해주세요');
    else if (newPwdValue.length === 0) return AlertMessage.alertMessage('error', '새 비밀번호를 입력해주세요');

    if (!this.refPasswordPolicy.current?.isAvailable) {
      const validateResult = Validator.validatePassword(newPwdValue);
      if (validateResult.errors.length > 0) {
        const message = this.refPasswordPolicy.current?.getValidationText?.(validateResult.errors[0]) || validateResult.errors[0].toString();
        AlertMessage.alertMessage('error', message.includes('공백불가') ? '공백을 입력할 수 없습니다.' : `${message} 입력해야합니다`);
      } else {
        AlertMessage.alertMessage('error', '새로운 비밀번호가 올바르지 않습니다');
      }
      return;
    }

    if (currentPwdValue === newPwdValue) {
      return AlertMessage.alertMessage('error', '현재 비밀번호와 새 비밀번호는 동일할 수 없습니다');
    }

    this.apiUtils
      .fetchApi('/v1/me/password', 'patch', {
        before: currentPwdValue,
        after: newPwdValue,
      })
      .then((r) => {
        console.log('[Line:44 r]', r);
        AlertMessage.alertMessage('success', '비밀번호가 성공적으로 변경되었습니다');
        this.logout();
      })
      .catch((e) => {
        console.log('[Line:49 e]', e);
      });
  };

  // !!! 로그아웃
  logout = async () => {
    try {
      const res = await api_tta.delete('/v1/auth/session');
      if (res.ok) {
        logWithTs('세션삭제 성공');
      } else {
        logWithTs('세션삭제 실패', res);
      }
    } catch (err) {
      logWithTs(err);
    }

    // 브라우저에 저장된 JWT 토큰 삭제
    StorageUtils.removeAuthorization();

    // API 인스턴스에 저장된 Authorization 삭제
    api_tta.deleteHeader('Authorization');

    // Web Socket Client 연결 끊기
    WSC.getInstance().stop(true);

    try {
      typeof this.props.sessionStore?.reset === 'function' && this.props.sessionStore?.reset();
    } catch (e) {}

    this.props.history.replace('/sign-in');
  };

  handleCurrentPwdChange = (event) => {
    this.setState({
      currentPwdValue: event.target.value,
    });
  };

  handleNewPwdChange = (event) => {
    this.setState({
      newPwdValue: event.target.value,
    });
  };

  render() {
    const { newPwdValue, currentPwdValue } = this.state;
    return (
      <div>
        <div className='change-pwd-container'>
          <div className={'main-title'}>비밀번호 변경</div>
          <div className={'sub-title'}>보안을 위해 새로운 비밀번호 설정 후 이용 가능합니다</div>
          <form>
            <div className={'input-container'} style={{}}>
              <div className={'title'}>현재 비밀번호</div>
              <input className={'input'} type={'password'} value={currentPwdValue} onChange={this.handleCurrentPwdChange} />
            </div>
            <div className={'input-container'}>
              <div className={'title'}>새 비밀번호</div>
              <input className={'input'} type={'password'} value={newPwdValue} onChange={this.handleNewPwdChange} />
            </div>
            <div
              style={{
                marginTop: 10,
                marginBottom: 20,
                position: 'relative',
                borderRadius: 4,
                border: '1px solid #ccc',
                padding: '25px 20px 15px 20px',
              }}>
              <PasswordPolicyComponent ref={this.refPasswordPolicy} password={newPwdValue} />
            </div>
          </form>
          <div className={'btn-container'}>
            <button className={'btn back'} onClick={this.logout}>
              뒤로가기
            </button>
            <button className={'btn'} onClick={this.handleChangePwd}>
              변경하기
            </button>
          </div>
        </div>
        <Footer styles={{ position: 'absolute', width: '100%', bottom: 20, color: '#000' }} />
        {AlertMessage.renderNotification()}
      </div>
    );
  }
}

export default withRouter(ChangePassword);
