import { useEffect, useRef, useState } from 'react';
import '../../../css/event/sms/smsEditModal.css';
import { Controller, useForm } from 'react-hook-form';
import APIUtils from '../../../utils/APIUtils';
import { Box, Modal } from '@mui/material';
import ModalStyle from '../../ModalStyle';
import Divider from '../../Divider';
import Input from '../../Input';
import moment from 'moment';
import AlertMessage from '../../utilities/AlertMessage';
import StringUtils from '../../../utils/StringUtils';

const apiUtils = new APIUtils();

const SmsEditModal = ({ rowInfo, open, setOpen, setTabValue }) => {
  const { handleSubmit, setValue, reset, control, register } = useForm();
  const [eventInfo, setEventInfo] = useState({});
  const [numberLists, setNumberLists] = useState([]);
  const handleClose = () => {
    setEventInfo({});
    setOpen(false);
  };

  const onSubmit = (data) => {
    if (numberLists.length === 0) {
      return AlertMessage.alertMessage('error', '최소 1명 이상의 수신자를 입력해주세요');
    } else if (data.message === null || data.message === undefined || data.message === '') {
      return AlertMessage.alertMessage('error', '전달내용을 입력해주세요');
    } else if (data.message.length >= 2000) {
      return AlertMessage.alertMessage('error', '내용을 2000자 이내로 입력해주세요');
    }
    apiUtils
      .fetchApi(`/v1/message-service`, `post`, {
        temp: 0,
        message: data.message,
        to: numberLists.map((e) => e),
        eventUuid: rowInfo?.eventUuid,
      })
      .then(() => {
        apiUtils.fetchApi(`/v1/message-service/${rowInfo?.uuid}`, 'delete');
      })
      .then(() => {
        AlertMessage.alertMessage('success', '전송이 성공적으로 완료되었습니다');
        setTabValue('2');
      })
      .catch((err) => console.log('[Line:27 err]', err));
  };

  useEffect(() => {
    const receivers = rowInfo && JSON.parse(rowInfo?.to);
    const selectDefault = receivers?.map((e) => e);
    setNumberLists(selectDefault);
    reset({
      message: rowInfo?.message,
    });
  }, []);

  useEffect(() => {
    const fetchEventInfo = () => {
      apiUtils
        .fetchApi(`/v1/me/organization/event/${rowInfo?.eventUuid}`, 'get')
        .then((res) => {
          setEventInfo(res);
        })
        .catch((err) => console.log('[Line:24 err]', err));
    };

    rowInfo?.eventUuid && fetchEventInfo();
  }, [rowInfo]);

  const ReceiverOptions = () => {
    const numberAddOnClick = ({ field }) => {
      const containsNonNumeric = /[a-zA-Zㄱ-ㅎ가-힣]/.test(field.value);
      const containsSpecialCharacters = /[^0-9-]/.test(field.value);
      const sanitizedValue = field.value.replace(/[^0-9]/g, '');
      const isNumericLengthValid = StringUtils.formatPhoneNumber(sanitizedValue);
      const isValueAlreadyExists = numberLists.includes(sanitizedValue);

      // 수신자 번호 에러 처리
      if (field.value === undefined || field.value === '') {
        return AlertMessage.alertMessage('error', '전화번호 입력 후 추가해주세요');
      } else if (containsNonNumeric) {
        return AlertMessage.alertMessage('error', '전화번호에 한글 또는 영문은 입력하실 수 없습니다');
      } else if (containsSpecialCharacters) {
        return AlertMessage.alertMessage('error', '- 를 제외한 특수문자는 들어갈 수 없습니다');
      } else if (!isNumericLengthValid) {
        return AlertMessage.alertMessage('error', '유효한 전화번호 포멧이 아닙니다');
      } else if (isValueAlreadyExists) {
        return AlertMessage.alertMessage('error', '해당 번호는 이미 존재합니다');
      } else if (numberLists.length >= 10) {
        return AlertMessage.alertMessage('error', '최대 10명의 수신자를 입력하실 수 있습니다');
      } else {
        setNumberLists((prevLists) => [...prevLists, sanitizedValue]);
      }
      reset({});
    };
    const buttonRef = useRef(null);
    return (
      <Controller
        name='to'
        control={control}
        defaultValue=''
        render={({ field }) => {
          if (field.value.length > 13) AlertMessage.alertMessage('error', '전화번호는 -(대시)를 포함하여 13자리까지 입력할 수 있습니다.');
          return (
            <div style={{ width: '600px', border: '1px solid #ddd', borderRadius: '5px', backgroundColor: '#FFF' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  style={{ borderRadius: 5, padding: 10, width: '100%' }}
                  value={field.value || ''}
                  className='select'
                  name={'to'}
                  placeholder={'전화번호를 입력해주세요'}
                  onChange={(field.value = StringUtils.formatPhoneNumber(field.value.replaceAll(/[-\s]/g, '').slice(0, 13)) || field.value.slice(0, 13))}
                  {...field}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      buttonRef.current.click();
                    }
                  }}
                />
                <button
                  ref={buttonRef}
                  style={{
                    backgroundColor: '#1b2649',
                    color: 'white',
                    padding: 10,
                    borderRadius: 5,
                    width: '10%',
                  }}
                  onClick={() => numberAddOnClick({ field })}>
                  +
                </button>
              </div>
              {numberLists.length >= 1 && (
                <div
                  style={{
                    width: '100%',
                    backgroundColor: 'white',
                    borderTop: '1px solid #ddd',
                    borderBottomLeftRadius: 5,
                    borderBottomRightRadius: 5,
                    padding: 5,
                  }}>
                  <div style={{ display: 'flex', flexWrap: 'wrap', rowGap: 10, width: '100%' }}>
                    {numberLists.map((e, idx) => {
                      const handleRemove = () => {
                        const updatedList = numberLists.filter((_, index) => index !== idx);
                        setNumberLists(updatedList);
                      };
                      return (
                        <div
                          key={idx}
                          style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            border: '1px solid #ddd',
                            borderRadius: 50,
                            padding: '3px 5px',
                            marginRight: 10,
                          }}>
                          <div style={{ fontSize: 12 }}>{StringUtils.formatPhoneNumber(e.replaceAll(/[-\s]/g, '').slice(0, 13)) || e.slice(0, 13)}</div>
                          <button style={{ backgroundColor: 'white' }} onClick={handleRemove}>
                            x
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          );
        }}
      />
    );
  };

  return (
    <Modal open={open} onClose={handleClose} className='sms-modal-container'>
      <Box sx={ModalStyle(800, 500, 5)}>
        <div className='sms-edit-container'>
          <div className='header'>임시저장 상세내용</div>
          <Divider marginTop={15} marginBottom={20} color={'#999999'} />
          <div className='each-row'>
            <div className='title'>이벤트</div>
            {eventInfo?.organizationEventCategory?.organizationService?.service?.name ? (
              <div className='detail'>
                <div className='main'>
                  {eventInfo?.organizationEventCategory?.organizationService?.service?.name} ({eventInfo?.organizationEventCategory?.eventCategory?.name})
                </div>
                <div className='sub'>
                  발생위치: {eventInfo?.addressStreet} {eventInfo?.addressStreetDetail}
                </div>
                <div className='sub wrapper'>
                  <div className='left'>이벤트 아이디: {eventInfo?.code}</div>
                  <div>발생일시: {moment(eventInfo?.issuedAt).format('YY년 MM월 DD일 HH시 mm분 ss초')}</div>
                </div>
              </div>
            ) : (
              '미지정'
            )}
          </div>
          <div className='each-row'>
            <div className='title'>수신자*</div>
            <ReceiverOptions />
          </div>
          <Input
            width={580}
            // width={'calc(100% - 110px)'}
            textarea
            height={120}
            label={'내용*'}
            placeholder='전달내용을 입력해주세요'
            {...register('message')}
            onChange={(e) => {
              if (e.target.value.length > 2000) {
                setValue('message', e.target.value.slice(0, 2000));
                AlertMessage.alertMessage('error', '내용을 2000자 이내로 입력해주세요');
              }
            }}
          />
          <div className='btn-container'>
            <button className='btn-close btn' onClick={handleClose}>
              닫기
            </button>
            <button className='btn-send btn' onClick={handleSubmit(onSubmit)}>
              전송
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default SmsEditModal;
