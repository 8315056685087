import React from 'react';
import {withRouter} from 'react-router-dom';
import {WSC} from "../../service/WSC";
import {StorageUtils} from "../../utils/StorageUtils";
import CryptoJS from 'crypto-js';
import {inject} from "mobx-react";
import SessionStore from "../../stores/SessionStore";
import {api_tta} from "../../api/api_tta";
import AlertMessage from "../utilities/AlertMessage";

interface Props {
  history?: any;
  sessionStore?: SessionStore;
}

interface State {

}

@inject('sessionStore')
class WSHandler extends React.Component<Props, State> {
  componentDidMount() {
    WSC.getInstance().socket.on('TOKEN_EXPIRED', this.onSessionExpired);
    WSC.getInstance().socket.on('MINIMUM_FREE_SPACE_REACHED_WARNING', this.onSpaceReachedLimit);
  }

  componentWillUnmount() {
    WSC.getInstance().socket.off('TOKEN_EXPIRED', this.onSessionExpired);
    WSC.getInstance().socket.off('MINIMUM_FREE_SPACE_REACHED_WARNING', this.onSpaceReachedLimit);
  }

  private onSpaceReachedLimit = (leftSpace: number) => {
    //leftSpace 단위는 GB
    AlertMessage.alertMessage("warning", <div>
      <div style={{marginBottom: 5}}>남은 서버 용량이 {leftSpace}GB 이하 입니다.</div>
      <div>시스템 관리자에게 문의해주세요.</div>
    </div>);
  }


  private getAuthorizationHash = (): string | null => {
    try {
      const authorization = StorageUtils.getAuthorization();
      if (!authorization) {return null;}

      return CryptoJS.SHA512(authorization).toString(CryptoJS.enc.Hex);
    } catch (e) {
      console.error(e);
    }

    return null;
  };

  private onSessionExpired = (tokenHash: string | null, isSelfLogout: boolean) => {
    try {
      const authorizationHash = this.getAuthorizationHash();
      if (authorizationHash !== tokenHash) {
        console.log('Authorization hash is not equal to tokenHash', authorizationHash, tokenHash);
        return;
      }
      // 브라우저에 저장된 JWT 토큰 삭제
      StorageUtils.removeAuthorization();

      // API 인스턴스에 저장된 Authorization 삭제
      api_tta.deleteHeader('Authorization');

      // Web Socket Client 연결 끊기
      WSC.getInstance().stop(true);

      // Reset SessionStore
      this.props.sessionStore?.reset?.();

      // 어떤 세션이 만료된 경우
      if (this.props.history) {
        this.props.history.push?.('/sign-in');
      }

      setTimeout(() => {
        if(isSelfLogout) {
          AlertMessage.alertMessage("success", "로그아웃 되었습니다.");
        } else {
          AlertMessage.alertMessage("error", <div>
            <div style={{marginBottom: 5}}>사용중인 아이디가 다른 환경에서 로그인되어,</div>
            <div>현재 사용중인 환경에서 로그아웃되었습니다.</div>
          </div>);
        }
      }, 300);

    } catch (e) {
      console.error(e);
    }
  };

  render() {
    return null;
  }
}

export default withRouter(WSHandler);
