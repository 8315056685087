import React from 'react';
import styles from './DeleteBtn.module.css';

const DeleteBtn = (onClick) => {
  return (
    <button className={styles.button} onClick={onClick}>
      삭제
    </button>
  );
};

export default DeleteBtn;
