import React from 'react';
import { ReactComponent as NOTFOUND } from '../icons/notFound.svg';
import { ReactComponent as NOTFOUND_EMPTY } from '../icons/s-notFound.svg';

const NoDataFound = (props) => {
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  };
  const headerStyle = {
    fontSize: 18,
    fontWeight: 'bold',
    color: props.variant === 'bright' ? 'gray' : 'white',
    marginTop: 20,
    marginBottom: 10,
  };
  const labelStyle = {
    fontSize: 20,
    color: '#777777',
  };
  const emptyLabel = {
    marginTop: 20,
  };

  if (props.emptyList) {
    return (
      <div style={{ ...containerStyle, height: '100%' }}>
        <NOTFOUND_EMPTY />
        <div style={emptyLabel}>목록이 비었습니다</div>
      </div>
    );
  } else {
    return (
      <div style={{ ...containerStyle, height: '100%' }}>
        <NOTFOUND />
        <div style={headerStyle}>NO DATA FOUND</div>
        <div style={labelStyle}>찾으시는 결과가 없습니다</div>
        <div style={labelStyle}>다시 시도해 주세요</div>
      </div>
    );
  }
};

export default NoDataFound;
