import moment from "moment";

export default class ImageUtils {

  // !!! dataURL을 FIle객체로 변환
  static dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename || `${moment().unix()}.png`, {type:mime});
  }
}
