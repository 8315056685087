import { useLayoutEffect, useState } from 'react';
import '../css/myPage/facilityManagement.css';
import FacilityDetailModal from '../components/facilityManage/modals/FacilityDetailModal';
import RegiFacilityModal from '../components/facilityManage/modals/RegiFacilityModal';
import AlertMessage from '../components/utilities/AlertMessage';
import HeaderComponent from '../components/HeaderComponent';
import { statusOptions } from '../constants/SelectOptions';
import APIUtils, { fetchApi } from '../utils/APIUtils';
import Container from '../components/Container';
import SelectBox from '../components/SelectBox';
import { inject, observer } from 'mobx-react';
import useSelect from '../hooks/useSelect';
import useInput from '../hooks/useInput';
import Table from '../components/Table/Table';
import Input from '../components/Input';
import { FacilityColumns } from '../components/Table/TableColumns';
import DeleteBtn from '../components/Table/DeleteBtn/DeleteBtn';
import RightBtn from '../components/Table/RightBtn/RightBtn';

const apiUtils = new APIUtils();
const PAGE_LIMIT = 6;

const FacilityManage = inject('cctvStore')(
  observer(({ cctvStore }) => {
    const [facilityRegiOpen, setFacilityRegiOpen] = useState(false);
    const [facilityDetailOpen, setFacilityDetailOpen] = useState(false);

    const [selectedRowData, setSelectedRowData] = useState('');

    const [totalCount, setTotalCount] = useState(0);
    const [rows, setRows] = useState([]);
    const [curPage, setCurPage] = useState(1);

    const [checkedRows, setCheckedRows] = useState([]);

    const searchInput = useInput('');
    const statusSelection = useSelect([]);
    const typeSelection = useSelect([]);

    const fetchedData = rows.find((row) => row.uuid === selectedRowData?.uuid);

    const RegiOpen = () => {
      setFacilityRegiOpen(true);
    };

    const DeleteOpen = async () => {
      if (checkedRows.length < 1) return AlertMessage.alertMessage('error', '삭제하실 CCTV를 선택해주세요');
      const result = await AlertMessage.confirmMessage(`삭제한 후에는 되돌릴 수 없습니다. \n 선택한 CCTV를 정말 삭제하시겠습니까?`);
      if (!result) return;

      checkedRows.map((e, idx) => {
        return fetchApi(`/v1/me/organization/facility/${e.uuid}`, `delete`).then(() => {
          if (checkedRows.length - 1 === idx) {
            AlertMessage.alertMessage('success', '삭제가 성공적으로 완료되었습니다');
            fetchTable();
          }
        });
      });
    };

    const DetailOpen = (params) => {
      setFacilityDetailOpen(true);
      setSelectedRowData(params.row);
    };

    const fetchTable = (e = null, p = curPage) => {
      setCurPage(p);
      apiUtils
        .fetchApi(`/v1/me/organization/facility`, 'get', {
          q: searchInput.value,
          limit: PAGE_LIMIT,
          offset: p ? (p - 1) * PAGE_LIMIT : 0,
          ob: 'id',
          od: 'DESC',
          active: statusSelection.value,
          organizationFacilityTypeId: typeSelection.value,
        })
        .then((tableData) => {
          setRows(tableData.rows);
          setTotalCount(tableData.count);
        })
        .catch((err) => console.log('[Line:122 err]', err));
    };

    useLayoutEffect(() => {
      fetchTable();
      setCurPage(1);
    }, [searchInput.value, statusSelection.value, typeSelection.value]);

    return (
      <Container>
        <HeaderComponent pageTitle={''} />
        <div className='facility-container'>
          <div className='content'>
            <div className='right'>
              <h3 className={'header'}>CCTV 관리</h3>
              <div className='search-title'>CCTV 조회</div>
              <div className='search-container'>
                <div className='left'>
                  <SelectBox isClearable onChange={statusSelection.onChange} variant={'primary'} options={statusOptions} placeholder={'상태'}></SelectBox>
                  <Input
                    type={'text'}
                    variant={'primary'}
                    value={searchInput.value}
                    onChange={(e) => {
                      if (e.target.value.length > 100) {
                        return AlertMessage.alertMessage('error', '최대 100자까지 입력할 수 있습니다.');
                      }
                      searchInput.onChange(e);
                    }}
                    placeholder={'CCTV 이름 검색'}
                    width={300}
                  />
                </div>
                <RightBtn onClick={RegiOpen}>CCTV 등록</RightBtn>
              </div>
              <div style={{ width: '100%', height: '75%', position: 'relative' }}>
                <Table
                  columns={FacilityColumns}
                  rows={rows}
                  checkbox
                  leftBtn={DeleteBtn(DeleteOpen)}
                  onRowClick={DetailOpen}
                  pageSize={PAGE_LIMIT}
                  rowCount={totalCount}
                  curPage={curPage}
                  onCustomPaginationChange={fetchTable}
                  disableSelectionOnClick
                  onCellKeyDown={(params, event) => {
                    if (event.code === 'Enter') {
                      DetailOpen(params);
                    }
                  }}
                  componentsProps={{
                    row: {
                      style: {
                        background: 'linear-gradient(90.12deg, #313644 0.02%, #1E2530 79.25%)',
                        fontWeight: 'bold',
                        borderRadius: 10,
                        marginBottom: 10,
                      },
                    },
                  }}
                  onSelectionModelChange={(ids) => {
                    const selectedIDs = new Set(ids);
                    const selectedRows = rows.filter((row) => selectedIDs.has(row.id));
                    setCheckedRows(selectedRows);
                  }}
                />
                {apiUtils.renderLoading()}
              </div>
            </div>
          </div>
          {/* 등록 - C */}
          {facilityRegiOpen && <RegiFacilityModal open={facilityRegiOpen} setOpen={setFacilityRegiOpen} fetchTable={fetchTable} />}
          {/* 조회 - R */}
          {facilityDetailOpen && <FacilityDetailModal open={facilityDetailOpen} setOpen={setFacilityDetailOpen} rowInfo={fetchedData} fetchTable={fetchTable} />}
        </div>
      </Container>
    );
  }),
);

export default FacilityManage;
