import React from "react";
import Lottie from "react-lottie";
import loading from "../../icons/animations/loading.json";

const styles = {
  loadingContainer: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    width: "100%",
    height: "100%",
    zIndex: 1000,
    transform: 'translate(-50%, -50%)',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};
export default class LoadingComponent extends React.Component {

  state = {
    isLoading: false
  }

  changeStatus = (isLoading) => this.setState({isLoading});

  defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loading,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  render() {
    if (!this.state.isLoading) return null;
    return (
      <div style={{...styles.loadingContainer, backgroundColor: this.props.withBg ? "#FFFFFF90" : "transparent", borderRadius: 10}}>
        <Lottie options={this.defaultOptions} width={100} height={100} />
      </div>
    );
  }
}
