import React from 'react';

import marker_w from '../../icons/marker/marker-w.png';
import marker_g from '../../icons/marker/marker-g.png';
import EventEmitter from '../../utils/EventEmitter';
import { EVENT_DEFAULT_ICON, EVENT_PROGRESS_CODE, EVENT_PROPERTY, EVENT_STATUS } from '../../constants/Event';
import moment from 'moment';
import EventDetailModal from '../event/EventDetailModal';
import { ReactComponent as SIREN2_WHITE } from '../../icons/siren2_white.svg';
import { ReactComponent as SIREN2_RED } from '../../icons/siren2_red.svg';

import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im';
import { SERVICE, SocketParser } from 'anuro-platform-core';

export default class EventItemComponent extends React.Component {
  emitter = EventEmitter.getInstance();

  state = {
    result: [],

    // modal 관련 변수
    detailOpen: false,
  };

  componentDidMount() {
    this.emitter.on('handleEventDetailModal', ({ uuid, value }) => this.handleEventDetailModal(uuid, value));
  }

  componentWillUnmount() {
    this.emitter.off('handleEventDetailModal', this.handleEventDetailModal);
  }

  handleEventDetailModal = (uuid, value) => {
    if (uuid === this.props.data.uuid) {
      this.handleModal('detailOpen', value);
    }
  };

  handleModal = (key, value, data, isEventOccur) => {
    this.setState({ [key]: value });
    data &&
      data.status !== EVENT_STATUS.TERMINATED.code &&
      this.emitter.emit('moveTo', {
        lat: data?.latitude,
        lng: data?.longitude,
        withMarker: false,
      });
  };

  render() {
    const { data, fetchData } = this.props;

    const parsingData = data.raw ? SocketParser.parse(SERVICE[data?.organizationEventCategory?.eventCategory?.service?.code], data.raw) : '';
    const progressCode = data.raw ? parsingData.header.PROGRESS_CODE : '';
    const isEventOccur = data.status === EVENT_STATUS.STANDBY.code && progressCode != EVENT_PROGRESS_CODE.RELEASE.code;
    const isEventDone = data.raw && progressCode == EVENT_PROGRESS_CODE.TERMINATED.code ? true : data.status === EVENT_STATUS.TERMINATED.code ? true : false;

    // // raw 있을 때
    // if (!this.props.isShowTerminatedEvent) {
    //   if (isEventDone) {
    //     return null;
    //   } else if (data.raw && progressCode == EVENT_PROGRESS_CODE.TERMINATED.code) {
    //     return null;
    //   } else if (data.raw && progressCode == EVENT_PROGRESS_CODE.RELEASE.code) {
    //     // return null;
    //   } else {
    //
    //   }
    // } else {
    //   if(!isEventDone) return null;
    //   // if(progressCode == EVENT_PROGRESS_CODE.RELEASE.code) return null;
    // }

    return (
      <>
        <div className={`${!isEventDone && isEventOccur ? 'item red' : 'item'} hover-transform-3`} onClick={() => this.handleModal('detailOpen', true, data, isEventOccur)}>
          <div className={'basic'}>
            <img className={'img'} src={data.organizationEventCategory?.organizationService?.iconUrl || EVENT_DEFAULT_ICON[data?.organizationEventCategory?.eventCategory?.service?.code]} />
            {isEventDone && <span className={'img'} style={{ position: 'absolute', backgroundColor: '#99999990' }}></span>}
            <div style={{ width: 'calc(100% - 60px)' }}>
              <div className={'place'}>
                <div>
                  <div className={'place-name'} style={{ color: isEventDone ? '#999999' : '#FFFFFF' }}>
                    {data.organizationEventCategory?.organizationService?.name}
                  </div>
                  <div className={'status'}>
                    {data.reported === null ? !isEventDone && isEventOccur ? <SIREN2_RED /> : <SIREN2_WHITE /> : data.reported ? <ImCheckboxChecked color={'green'} /> : <ImCheckboxUnchecked color={'gray'} />}
                    <span
                      style={{
                        color: !isEventDone && isEventOccur ? '#FF0000' : isEventDone ? '#999999' : '#FFFFFF',
                        marginLeft: 5,
                      }}>
                      {data.raw ? (isEventDone ? '상황종료' : progressCode == EVENT_PROGRESS_CODE.RELEASE.code ? '처리중' : EVENT_STATUS[data.status].name) : isEventDone ? '상황종료' : EVENT_STATUS[data.status].name}
                    </span>
                  </div>
                </div>
                <div className={'place-location'}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      width: "100%"
                    }}>
                    <img src={!isEventDone && isEventOccur ? marker_w : marker_g} style={{ marginRight: 5 }} />
                    <div
                      className={'address'}
                      style={{ color: isEventDone ? '#999999' : '#FFFFFF' }}
                      onClick={(e) => {
                        const parsingData = data.raw ? SocketParser.parse(SERVICE[data?.organizationEventCategory?.eventCategory?.service?.code], data.raw) : '';
                        const progressCode = data.raw ? parsingData.header.PROGRESS_CODE : '';

                        if ((progressCode == EVENT_PROGRESS_CODE.TERMINATED.code || data.status === EVENT_STATUS.TERMINATED.code)) return;
                        e.stopPropagation();
                        this.emitter.emit('moveTo', { lat: data.latitude, lng: data.longitude, withMarker: false });
                      }}>
                      {data.addressStreet}
                    </div>
                  </div>
                </div>
                <div style={{fontSize: 12, marginTop: 8}}>{moment(data.issuedAt).format('YY년 MM월 DD일 HH시 mm분 ss초')}</div>
              </div>
            </div>
          </div>
        </div>

        {/* !!! 이벤트 관련 모달들 */}
        {this.state.detailOpen && (
          <EventDetailModal
            detailOpen={true}
            isDetailOpen={(e) => this.handleModal('detailOpen', e)}
            isDeleteOpen={(e) => this.handleModal('deleteOpen', e)}
            rowInfo={data}
            fetchData={fetchData}
            setEventQuiteOpen={(e) => this.handleModal('eventQuiteOpen', e)}
            setEditOpen={(e) => this.handleModal('eventEditOpen', e)}
            setDeleteData={(e) => this.handleModal('deleteOpen', e)}
          />
        )}
      </>
    );
  }
}
