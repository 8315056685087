import React from "react";
import {Button, Modal, Paper} from "@mui/material";
import "./timeoutScreen.css";
import {withRouter} from "react-router-dom";
import moment from "moment";

export enum TIMEOUT_TYPE {
  NONE, WARNING, TIMEOUT
}

interface Props {
  history: any;
  type: TIMEOUT_TYPE;
  onClose: () => {};
  logout: () => {};
  leftTime: number;
}
interface States {}

class TimeoutScreen extends React.Component<Props, States> {

  goToSignIn = () => {
    this.props.onClose?.();
    this.props.history.push('/sign-in');
  }

  render() {
    const {type} = this.props;
    return (
      <Modal open={true} onClose={() => {}} disableEscapeKeyDown>
        <Paper className="timeout-screen-container" >
          <div className={"header"}>로그아웃 안내</div>
          <div style={{marginBottom: 40}}>
            {
              type === TIMEOUT_TYPE.WARNING ?
                <div>
                  <div style={{marginBottom: 8}}>장시간 이용이 없어</div>
                  <div>로그인 유지 시간이 만료될 예정입니다.
                    <span className={"left-time"}>{moment.utc(this.props.leftTime).format('mm:ss')}</span>
                  </div>
                </div> : type === TIMEOUT_TYPE.TIMEOUT ?
                <div>
                  장시간 이용이 없어 자동으로 로그아웃되었습니다.
                </div> : null
            }
          </div>

          <span style={{textAlign: "right"}}>
            {
              type === TIMEOUT_TYPE.TIMEOUT ?
                <Button variant={"contained"} tabIndex={0} onClick={() => this.goToSignIn()}>확인</Button> :
                type === TIMEOUT_TYPE.WARNING ?
                  <>
                    <Button variant={"contained"} tabIndex={0} style={{marginRight: 10}} onClick={() => this.props.onClose()}>연장하기</Button>
                    <Button variant={"outlined"} tabIndex={0} onClick={() => {
                      this.props.logout();
                      this.goToSignIn();
                    }}>로그아웃</Button>
                  </> : null
            }
          </span>
        </Paper>
      </Modal>
    );
  }
}

export default withRouter(TimeoutScreen);
