import { action, observable, runInAction } from 'mobx';
import jwt_decode from 'jwt-decode';
import { StorageUtils } from '../utils/StorageUtils';
import { api_tta } from '../api/api_tta';
import { IUser } from 'anuro-platform-core';
import { IOrganizationService } from 'anuro-platform-core';

export default class SessionStore {
  token: string;
  @observable.deep user: any;
  @observable userPermission: {};

  constructor() {
    // Initialize
    if (!this.user) {
      const authorization = StorageUtils.getAuthorization();
      if (authorization) {
        try {
          const payload = jwt_decode(authorization);
          this.setUser({ ...payload });
        } catch (e) {
          console.error(e);
        }
      }
    }
  }

  @action
  reset() {
    this.user = null;
    this.token = null;
  }

  @action
  setToken(token: string) {
    runInAction(() => {
      this.token = token;
    });
  }

  @action
  setUser(user: IUser) {
    this.user = user;
  }

  @action
  setUserPermission(userPermission: {}) {
    this.userPermission = userPermission;
  }

  @action
  signIn(username: string, password: string, keepSession: boolean): Promise {
    return new Promise((resolve, reject) => {
      api_tta
        .post('/v1/auth/sign-in', { username, password })
        .then((r) => {
          if (!r.ok) {
            console.warn(r);
            reject(r);
            return;
          }

          const token = r.data.token;
          if (!token) {
            console.error('Token is not exists', r);
            reject('TOKEN_IS_NOT_EXISTS');
            return;
          }

          const payload = jwt_decode(token);
          console.log('Decoded JWT', payload);

          // Save JWT to LocalStorage
          StorageUtils.setAuthorization(token, keepSession);

          // 저장된 JWT 체크
          if (token === StorageUtils.getAuthorization()) {
            console.log('Sign-in success', token);

            runInAction(() => {
              this.setUser({ ...payload });
            });

            api_tta.setHeader('Authorization', token);
            resolve(r);
          } else {
            console.warn('An error occurred while save token to local');
            reject(r);
          }
        })
        .catch((e) => {
          console.error(e);
          return reject(e);
        });
    });
  }
}
