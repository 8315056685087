import React, {CSSProperties} from "react";
import Lottie from "react-lottie";
import animationData from "./not-found.json";
import {Button, Paper} from "@mui/material";
import {withRouter} from "react-router-dom";

const styles = {
  container: {
    width: "100%",
    height: "100%",
    position: 'absolute',
    left: '50%',
    top: '50%',
    zIndex: 1000,
    boxSizing: "border-box",
    transform: 'translate(-50%, -50%)',
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    // borderRadius: 10
  } as CSSProperties,
};

class NotFoundScreen extends React.Component<any, any> {

  defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  // this.props.history.push('/sign-in');

  render() {
    return (
      <Paper style={{...styles.container }}>
        <Lottie isClickToPauseDisabled options={this.defaultOptions} width={"auto"} height={350}/>
        <span style={{color: "#1C1E4D", fontWeight: "bold", fontSize: 18}}>페이지를 찾을 수 없습니다.</span>

        <Button variant={"contained"} sx={{marginTop: 5}} onClick={() => this.props.history?.push?.('/')}>메인으로 이동</Button>
      </Paper>
    );
  }
}

export default withRouter(NotFoundScreen);
