import React from "react";
import "../css/headerComponent.css";
import CurrentTime from "./dateAndTime/CurrentTime";
import {ReactComponent as PROFILE} from '../icons/header/profile_icon.svg'
import {inject} from "mobx-react";

const HeaderComponent = (props) => {
	return (
			<header className="common-header-container">
				<section className={"left"}>
					<div className={`title  ${props.isStrong ? 'strong' : null}`}>
						{props.pageTitle}
					</div>
				</section>
				<section className={`middle`}>
					{props.middle || props.children}
				</section>
				<section className="right">
					<div className={`time`}>
						<CurrentTime/>
					</div>
					<div className="user-info-container" >
						<PROFILE/>
					</div>
				</section>
			</header>
	);
};

export default inject("sessionStore")(HeaderComponent);
