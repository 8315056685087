import React from 'react';
import '../../css/setting.css';
import { ReactComponent as PROFILE } from '../../icons/header/profile_icon.svg';
import SettingBaseModal from './SettingBaseModal';
import SettingProfilePanelComponent from './SettingProfilePanelComponent';
import APIUtils from '../../utils/APIUtils';
import { IUser } from 'anuro-platform-core';
import LoginHistoryPanelComponent from "./LoginHistoryPanelComponent";
import ModifyInformationPanelComponent from "./ModifyInformationPanelComponent";

export const SETTING_PANEL = {
  PROFILE: 'profile',
  PROFILE_LOGIN_HISTORY: 'login_history',
  PROFILE_MODIFY_INFORMATION: 'modify_information',
};

export const SETTING_TAB = {
  PROFILE: "profile",
}

export default class SettingModal extends React.Component {
  apiUtils = new APIUtils();
  state = {
    mode: SETTING_PANEL.PROFILE,
    selectedItem: {},
    user: {},
    currentTab: SETTING_TAB.PROFILE,
  };

  componentDidMount() {
    this.fetchUserInformation();
  }

  handleData = (mode, currentTab, selectedItem) => {
    this.setState({
      mode,
      currentTab,
      selectedItem: selectedItem || {}
    });
  }

  // !!! 버튼 클릭 시에 대한 오른쪽 패널 렌더링
  renderPanel = () => {
    const mode = this.state.mode;
    if (mode === SETTING_PANEL.PROFILE) {
      /* 설정 메뉴 */
      return <SettingProfilePanelComponent fetchUserInformation={this.fetchUserInformation} userProfile={this.state.user} handleData={this.handleData}/>;
    } else if (mode === SETTING_PANEL.PROFILE_LOGIN_HISTORY) {
      /* 로그인 기록 */
      return <LoginHistoryPanelComponent handleData={this.handleData}/>
    } else if (mode === SETTING_PANEL.PROFILE_MODIFY_INFORMATION) {
      /* 정보 수정 */
      return <ModifyInformationPanelComponent handleData={this.handleData} user={this.state.user} fetchUserInformation={this.fetchUserInformation} updateUserInfo={this.updateUserInfo}/>
    }
  };

  // !!! 사용자 계정 정보 가져오기
  fetchUserInformation = () => {
    this.apiUtils.fetchApi('/v1/me', 'get').then((user: IUser) => {
      this.setState({ user });
    });
  };

  updateUserInfo = (user: IUser) => {
    this.setState({user: JSON.parse(JSON.stringify(user))});
  }

  //!!! 왼쪽 상단에 사용자 프로필 렌더링
  renderUserProfile = () => {
    const { user } = this.state;
    return (
      <div
        className={`profile`}
        style={{backgroundColor: this.state.currentTab === SETTING_TAB.PROFILE ? "#1B264918" : "transparent"}}
      >
          <PROFILE className={'profile-img'} />
          <div style={{flex: 1}}>
            <div style={{maxHeight: 100, overflow: "auto", whiteSpace: "pre-wrap", wordBreak: "break-all"}}>{user?.profile?.name || ""} ({user?.username || ""})</div>
            <div className={'department'}>{user?.organization?.name || ""}</div>
          </div>
      </div>
    );
  };

  render() {
    return (
      <SettingBaseModal open={true} onClose={this.props.modalHandler} title={'설정'} width={760} height={620} withBackground>
        {/* 왼쪽 패널  */}
        <div className={'content2'} style={{ position: 'relative' }}>
          <div className={'left'}>
            {this.renderUserProfile()}
          </div>
          {this.renderPanel()}
          {this.apiUtils.renderLoading(true)}
        </div>
      </SettingBaseModal>
    );
  }
}
