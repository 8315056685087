import { useEffect, useState } from 'react';
import '../../../css/facilities/facilityDetailModal.css';
import { ReactComponent as CCTV } from '../../../icons/cctv_logo.svg';
import BaseModal from '../../BaseModal';
import EditFacilityModal from './EditFacilityModal';
import Divider from '../../Divider';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import AlertMessage from '../../utilities/AlertMessage';
import { fetchApi } from '../../../utils/APIUtils';

const FacilityDetail = ({ title, detail }) => {
  const margin = 15;
  const color = '#cccccc';

  return (
    <>
      <Divider marginTop={margin} marginBottom={margin} color={color} />
      <div className='each-detail'>
        <div className='title'>{title}</div>
        <div style={{ wordBreak: 'break-word', maxHeight: '60px', overflow: 'auto', minHeight: '25px' }}>{detail}</div>
      </div>
    </>
  );
};

const FacilityDetailModal = inject('cctvStore')(
  observer(({ cctvStore, open, setOpen, rowInfo, fetchTable }) => {
    const [facilityEditOpen, setFacilityEditOpen] = useState(false);
    const margin = 15;
    const color = '#cccccc';

    const handleClose = () => {
      setOpen(false);
    };
    const handleDelBtn = async () => {
      const result = await AlertMessage.confirmMessage(`삭제한 후에는 되돌릴 수 없습니다. \n 선택한 CCTV를 정말 삭제하시겠습니까?`);
      if (!result) return;

      fetchApi(`/v1/me/organization/facility/${rowInfo.uuid}`, `delete`).then(() => {
        setOpen(false);
        AlertMessage.alertMessage('success', '삭제되었습니다');
        fetchTable();
      });
    };

    const handleEditBtn = () => {
      setFacilityEditOpen(true);
    };

    useEffect(() => {
      setTimeout(() => {
        document.getElementsByClassName('MuiBox-root')?.[0]?.focus?.();
      }, 300);
    }, []);

    return (
      <BaseModal width={590} height={800} title={''} open={open} rightBtnLabel={'정보 수정'} leftBtnLabel={'삭제'} closeIcon handleClose={handleClose} handleRightBtn={handleEditBtn} handleLeftBtn={handleDelBtn}>
        <div className='facility-detail-modal-container'>
          <div className='header-container'>
            <CCTV className='icon' />
            <div className='name'>{rowInfo?.name}</div>
            <div className='sub-header-container'>
              <div className='facility-type'>{rowInfo?.organizationFacilityType?.facilityType?.name}</div>
              <div className='facility-id'>(ID: {rowInfo?.id})</div>
            </div>
          </div>
          <div className='detail-container'>
            <FacilityDetail title={'UUID'} detail={rowInfo?.uuid} />
            <FacilityDetail title={'코드'} detail={rowInfo?.code ? rowInfo.code : '미지정'} />
            <FacilityDetail title={'주소'} detail={rowInfo?.addressStreet + ' / ' + rowInfo?.addressStreetDetail} />
            {/*<FacilityDetail title={'위도/경도'} detail={rowInfo?.latitude + ' / ' + rowInfo?.longitude} />*/}
            <FacilityDetail title={'활성 상태'} detail={rowInfo?.active === 1 ? '활성' : '비활성'} />
            <FacilityDetail title={'동작 상태'} detail={rowInfo?.active === 1 && cctvStore.workingCCTVList.includes(rowInfo?.uuid) ? '정상' : '이상'} />
            <FacilityDetail title={'등록일'} detail={moment(rowInfo?.createdAt).format('YY년 MM월 DD일 HH시 mm분 ss초')} />
            <FacilityDetail title={'비고'} detail={rowInfo?.detail} />
            <Divider marginTop={margin} marginBottom={margin} color={color} />
          </div>
        </div>

        {/* 수정 - U */}
        {facilityEditOpen && <EditFacilityModal open={facilityEditOpen} setOpen={setFacilityEditOpen} rowInfo={rowInfo} fetchTable={fetchTable} />}
      </BaseModal>
    );
  }),
);

export default FacilityDetailModal;
