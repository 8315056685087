export const statusOptions = [
  { value: '1', label: '활성' },
  { value: '0', label: '비활성' },
];

export const defaultCheckboxList = [
  { name: 'R', label: '조회' },
  { name: 'C', label: '생성' },
  { name: 'U', label: '처리' },
  { name: 'D', label: '삭제' },
];

export const accAccessOptions = [
  { value: 'ACCOUNT_HOLDER', label: '마스터' },
  { value: 'NORMAL', label: '일반' },
];

export const eventStatusOptions = [
  { value: 'STANDBY', label: '상황발생' },
  { value: 'IN_PROGRESS', label: '처리중' },
  { value: 'TERMINATED', label: '상황종료' },
];
