import { useEffect, useRef, useState } from 'react';
import AlertMessage from '../../utilities/AlertMessage';
import { Controller, useForm } from 'react-hook-form';
import { fetchApi } from '../../../utils/APIUtils';
import Input from '../../Input';
import moment from 'moment';
import StringUtils from '../../../utils/StringUtils';

const SmsSendComponent = ({ rowInfo, setTabValue }) => {
  const { handleSubmit, setValue, reset, control, register, watch } = useForm();
  const messageContent = watch('message');
  const [numberLists, setNumberLists] = useState([]);

  useEffect(() => {
    const status = (() => {
      switch (rowInfo[0]?.status) {
        case 'STANDBY':
          return '상황발생';
        case 'IN_PROGRESS':
          return '처리중';
        case 'TERMINATED':
          return '상황종료';
        default:
          return '';
      }
    })();

    reset({
      message: rowInfo[0]?.organizationEventCategory.eventCategory.service.name ? rowInfo[0]?.organizationEventCategory.eventCategory.service.name + '(' + rowInfo[0]?.code + ')' + ' ' + status + ' 상태' : null,
    });
  }, [rowInfo]);

  const clickSendBtn = (data) => {
    if (numberLists.length === 0) {
      return AlertMessage.alertMessage('error', '최소 1명 이상의 수신자를 입력해주세요');
    } else if (data.message === null || data.message === undefined || data.message === '') {
      return AlertMessage.alertMessage('error', '전달내용을 입력해주세요');
    } else if (data.message.length > 2000) {
      return AlertMessage.alertMessage('error', '내용을 2000자 이내로 입력해주세요');
    }
    fetchApi(`/v1/message-service`, `post`, {
      temp: 0,
      message: data.message,
      to: numberLists.map((e) => e),
      eventUuid: rowInfo[0] ? rowInfo[0].uuid : undefined,
    })
      .then((r) => {
        if (r !== 'Created') return;

        AlertMessage.alertMessage('success', '전송이 성공적으로 완료되었습니다');
        setTabValue('2');
      })
      .catch((err) => console.log('[Line:27 err]', err));
  };

  const clickTempSaveBtn = (data) => {
    if (numberLists.length === 0) {
      return AlertMessage.alertMessage('error', '최소 1명 이상의 수신자를 입력해주세요');
    } else if (data.message === null || data.message === undefined || data.message === '') {
      return AlertMessage.alertMessage('error', '전달내용을 입력해주세요');
    } else if (data.message.length > 2000) {
      return AlertMessage.alertMessage('error', '내용을 2000자 이내로 입력해주세요');
    }
    fetchApi(`/v1/message-service`, `post`, {
      temp: 1,
      message: data.message,
      to: numberLists.map((e) => e),
      eventUuid: rowInfo[0] ? rowInfo[0].uuid : undefined,
    })
      .then((res) => {
        AlertMessage.alertMessage('success', '임시저장이 성공적으로 완료되었습니다');
        setTabValue('3');
      })
      .catch((err) => console.log('[Line:27 err]', err));
  };

  const ReceiverOptions = () => {
    const numberAddOnClick = ({ field }) => {
      const containsNonNumeric = /[a-zA-Zㄱ-ㅎ가-힣]/.test(field.value);
      const containsSpecialCharacters = /[^0-9-]/.test(field.value);
      const sanitizedValue = field.value.replace(/[^0-9]/g, '');
      const isNumericLengthValid = StringUtils.formatPhoneNumber(sanitizedValue);
      const isValueAlreadyExists = numberLists.includes(sanitizedValue);
      if (field.value === undefined || field.value === '') {
        return AlertMessage.alertMessage('error', '전화번호 입력 후 추가해주세요');
      } else if (containsNonNumeric) {
        return AlertMessage.alertMessage('error', '전화번호에 한글 또는 영문은 입력하실 수 없습니다');
      } else if (containsSpecialCharacters) {
        return AlertMessage.alertMessage('error', '- 를 제외한 특수문자는 들어갈 수 없습니다');
      } else if (!isNumericLengthValid) {
        return AlertMessage.alertMessage('error', '유효한 전화번호 포멧이 아닙니다');
      } else if (isValueAlreadyExists) {
        return AlertMessage.alertMessage('error', '해당 번호는 이미 존재합니다');
      } else if (numberLists.length >= 10) {
        return AlertMessage.alertMessage('error', '최대 10명의 수신자를 입력할 수 있습니다');
      } else {
        setNumberLists((prevLists) => [...prevLists, sanitizedValue]);
      }
      reset({});
    };
    const buttonRef = useRef(null);
    return (
      <Controller
        name='to'
        control={control}
        defaultValue=''
        render={({ field, fieldState, setValue }) => {
          if (field.value.length > 13) {
            field.onChange((field.value = StringUtils.formatPhoneNumber(field.value.replaceAll(/[-\s]/g, '').slice(0, 13)) || field.value.slice(0, 13)));
            AlertMessage.alertMessage('error', '전화번호는 -(대시)를 포함하여 13자리까지 입력할 수 있습니다.');
          }
          return (
            <div style={{ width: '600px', border: '1px solid #ddd', borderRadius: '5px', backgroundColor: '#FFF' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  style={{ borderRadius: 5, padding: 10 }}
                  value={field.value || ''}
                  className='select'
                  name={'to'}
                  placeholder={'전화번호를 입력해주세요'}
                  onChange={(field.value = StringUtils.formatPhoneNumber(field.value.replaceAll(/[-\s]/g, '').slice(0, 13)) || field.value.slice(0, 13))}
                  {...field}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      buttonRef.current.click();
                    }
                  }}
                />
                <button
                  ref={buttonRef}
                  style={{
                    backgroundColor: '#1b2649',
                    color: 'white',
                    padding: 10,
                    borderRadius: 5,
                    width: '10%',
                  }}
                  onClick={() => numberAddOnClick({ field })}>
                  +
                </button>
              </div>
              {numberLists.length >= 1 && (
                <div
                  style={{
                    width: '100%',
                    backgroundColor: 'white',
                    borderTop: '1px solid #ddd',
                    borderBottomLeftRadius: 5,
                    borderBottomRightRadius: 5,
                    padding: 5,
                  }}>
                  <div style={{ display: 'flex', flexWrap: 'wrap', rowGap: 10, width: '100%' }}>
                    {numberLists.map((e, idx) => {
                      const handleRemove = () => {
                        const updatedList = numberLists.filter((_, index) => index !== idx);
                        setNumberLists(updatedList);
                      };
                      return (
                        <div
                          key={idx}
                          style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            border: '1px solid #ddd',
                            borderRadius: 50,
                            padding: '3px 5px',
                            marginRight: 10,
                          }}>
                          <div style={{ fontSize: 12 }}>{StringUtils.formatPhoneNumber(e.replaceAll(/[-\s]/g, '').slice(0, 13)) || e.slice(0, 13)}</div>
                          <button style={{ backgroundColor: 'white' }} onClick={handleRemove}>
                            x
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          );
        }}
      />
    );
  };

  return (
    <div className='sms-send-container'>
      <div className='each-row'>
        <div className='title'>이벤트</div>
        {rowInfo[0]?.organizationEventCategory.eventCategory.service.name ? (
          <div>
            <div style={{ fontSize: 18, fontWeight: 'bold', marginBottom: 6 }}>
              {rowInfo[0]?.organizationEventCategory.eventCategory.service.name}({rowInfo[0]?.organizationEventCategory.eventCategory.name})
            </div>
            <div style={{ fontSize: 14, color: '#666666', marginBottom: 4 }}>
              발생위치: {rowInfo[0]?.addressStreet} {rowInfo[0]?.addressStreetDetail}
            </div>
            <div style={{ fontSize: 14, color: '#666666', marginBottom: 4 }}>
              <span style={{ marginRight: 10 }}>이벤트 아이디: {rowInfo[0]?.code}</span>
            </div>
            <div style={{ fontSize: 14, color: '#666666' }}>
              <span>발생일시: {moment(rowInfo[0]?.issuedAt).format('YY년 MM월 DD일 HH시 mm분 ss초')}</span>
            </div>
          </div>
        ) : (
          '미지정'
        )}
      </div>
      <div className='each-row'>
        <div className='title'>수신자*</div>
        <ReceiverOptions />
      </div>
      <Input
        width={580}
        textarea
        height={120}
        label={'내용*'}
        placeholder='전달내용을 입력해주세요'
        {...register('message')}
        onChange={(e) => {
          if (e.target.value.length > 2000) {
            setValue('message', e.target.value.slice(0, 2000));
            return AlertMessage.alertMessage('error', '내용을 2000자 이내로 입력해주세요');
          }
        }}
      />
      <div className='btn-container'>
        <button className='btn-save btn' onClick={handleSubmit(clickTempSaveBtn)}>
          임시저장
        </button>
        <button style={{ color: 'white' }} className='btn-send btn' onClick={handleSubmit(clickSendBtn)}>
          전송
        </button>
      </div>
    </div>
  );
};

export default SmsSendComponent;
