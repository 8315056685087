import React from 'react';
import {SETTING_PANEL, SETTING_TAB} from './SettingModal';
import APIUtils from '../../utils/APIUtils';
import { IUser } from 'anuro-platform-core';
import AlertMessage from '../utilities/AlertMessage';
import ObjectUtils from "../../utils/ObjectUtils";
import {inject} from "mobx-react";
import SessionStore from "../../stores/SessionStore";
import {ReactComponent as PROFILE} from '../../icons/header/profile_icon.svg'
import StringUtils from "../../utils/StringUtils";
import Input from "../input/Input";

const REG_PHONE = /^(01)[0-9]{1}[0-9]{3,4}[0-9]{4}/;

export enum EDIT_MODE {
  NORMAL,
  CREATE,
  EDIT,
  DELETE,
}

type Props = {
  user: IUser;
  handleData: Function;
  fetchUserInformation: Function;
  sessionStore: SessionStore;
  updateUserInfo: Function;
};

type States = {
  user: IUser;
  mode: EDIT_MODE,
};

const styles = {
  item: {
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: "space-between",
      margin: "2px 0 20px 2px",
      width: "calc(100% - 4px)",
      borderRadius: 10,
      boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.35)',
      backgroundColor: "#FFFFFF",
      padding: "15px 20px",
    } as React.CSSProperties,
    input: {
      width: "calc(100% - 80px)",
      borderLeft: "1px solid #ccc",
      textAlign: "right",
      backgroundColor: "#FFF",
      paddingLeft: 10,
      maxHeight: 50,
      overflow: "auto"
    } as React.CSSProperties
  },
};

@inject("sessionStore")
export default class ModifyInformationPanelComponent extends React.Component<Props, States> {
  apiUtils = new APIUtils();
  state = {
    user: {...this.props.user},
    mode: EDIT_MODE.NORMAL,
  };

  goBack = async () => {
    if(!ObjectUtils.areObjectsSame(this.state.user, this.props.sessionStore?.user || {})) {
      const result = await AlertMessage.confirmMessage("바뀐 정보가 있습니다. 그래도 뒤로 가시겠습니까?");
      if(result) {
        this.props.updateUserInfo(JSON.parse(JSON.stringify(this.props.sessionStore.user)));
        this.props.handleData(SETTING_PANEL.PROFILE, SETTING_TAB.PROFILE);
      }
    } else {
      this.props.handleData(SETTING_PANEL.PROFILE, SETTING_TAB.PROFILE);
    }
  }

  // !!! 사용자 이미지 변경
  updateUserProfile = (file: any) => {
    let formData = new FormData();
    formData.append('image', file);
    this.apiUtils.fetchApi('/v1/me', 'patch', { formData }).then((r) => {
      AlertMessage.alertMessage('success', '프로필 사진이 수정되었습니다');
      let user: IUser = { ...this.state.user };
      user.profile && (user.profile.imageUrl = URL.createObjectURL(file));
      this.setState({ user });
      this.props.fetchUserInformation();
    });
  };

  onChange = (key: string, value: any, inputType: "text" | "number" | "phone" = "text") => {
    let _value = value;
    if(inputType === "number" || inputType === "phone") {
      _value = _value.replace(/[^0-9]/g, '').slice(0, 11);
    }

    this.setState((prevState): any => {
      // @ts-ignore
      const nestedObject = { ...prevState };
      const keys = key.split('.');
      let object = nestedObject;
      for (let i = 0; i < keys.length - 1; i++) {
        object = object[keys[i]];
      }
      object[keys[keys.length - 1]] = _value;
      return { key: nestedObject };
    });
  }

  getValue = (item: IUser, key: string, inputType: "text" | "number" | "phone" = "text"): string | ReadonlyArray<string> | number | undefined => {
    let value = ObjectUtils.getObjectByString(item, key);
    if (inputType === "phone" && value) {
      value = StringUtils.formatPhoneNumber(value?.slice(0, 13)) || value.replace(/[^0-9]/g, '').slice(0, 11);
    }
    return value || "";
  }

  renderProfileItem = (item: IUser, label: string, maxLength: number, key: string, inputType: "text" | "phone" | "number" = "text", errorMessage?: string) => {
    return (
      <div style={styles.item.container}>
        <span>{label.substring(0, label.length - 1)}</span>
        {
          this.state.mode !== EDIT_MODE.NORMAL ?
            <Input id={key} maxLength={maxLength > 0 ? maxLength : undefined} errorMessage={errorMessage} label={label} style={styles.item.input} value={this.getValue(item,key, inputType)} onChange={(e) => {this.onChange("user." + key, e.target.value, inputType)}}
                   onKeyDown={(e) => {
                    if(key === "profile.phoneNumber" && e.code === "Enter") {
                      this.onSubmit();
                    }
            }}/>
            :
            <div style={{...styles.item.input, fontSize: 14}}>{this.getValue(item,key, inputType)}</div>
        }
      </div>
    )
  }

  onSubmit = () => {
    if(this.state.mode === EDIT_MODE.EDIT && !ObjectUtils.areObjectsSame(this.state.user, this.props.sessionStore?.user || {})) {
      if(this.state.user.profile?.name?.length === 0) return AlertMessage.alertMessage("error", "이름을 입력해주세요");
      if(this.state.user.profile?.phoneNumber && this.state.user.profile.phoneNumber.length < 8) return AlertMessage.alertMessage("error", "유효한 전화번호 포멧이 아닙니다.");

      this.apiUtils.fetchApi("/v1/me", "patch", this.state.user.profile).then(r => {
        AlertMessage.alertMessage('success', "정보가 성공적으로 수정되었습니다");

        this.props.sessionStore.setUser(JSON.parse(JSON.stringify(this.state.user)));
        this.props.updateUserInfo(JSON.parse(JSON.stringify(this.state.user)));
      });
    }
    this.setState({"mode": this.state.mode === EDIT_MODE.NORMAL ? EDIT_MODE.EDIT : EDIT_MODE.NORMAL});
  }

  render() {
    const { user } = this.state;
    return (
      <div className={'right'} style={{ position: 'relative' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 20,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <div className={'cursor'} onClick={this.goBack}>
            <span style={{ marginRight: 10 }}>&#60;</span>
            <span id="edit-profile">정보수정</span>
          </div>
          <div className={`txt-btn ${!ObjectUtils.areObjectsSame(this.state.user, this.props.sessionStore?.user || {}) && "active"}`} onClick={this.onSubmit}>{this.state.mode === EDIT_MODE.NORMAL ? "수정" : "저장"}</div>
        </div>
        <div style={{ position: 'relative', height: 'calc(100% - 36px)', overflowY: 'auto' }}>
          {/*<input type={'file'} id={'file'} style={{ display: 'none' }} />*/}
          <div className={'service-icon-container'}>
            <label htmlFor='file'>
              <div className={'no-img'}>
                <PROFILE />
              </div>
            </label>
            <div style={{ marginTop: 50 }}>
              {this.renderProfileItem(user, "이름은", 255, "profile.name")}
              {this.renderProfileItem(user, "전화번호는", 13, "profile.phoneNumber", "phone", "전화번호는 -(대시)를 포함하여 13자리까지 입력할 수 있습니다." )}
              {/*{this.renderProfileItem(user, "직통번호는", 13, "profile.directNumber", "phone", "직통번호는 -(대시)를 포함하여 13자리까지 입력할 수 있습니다.")}*/}
              {/*{this.renderProfileItem(user, "내선번호는", 13, "profile.extensionNumber", "phone", "내선번호는 -(대시)를 포함하여 13자리까지 입력할 수 있습니다.")}*/}
              {/*{this.renderProfileItem(user, "이메일은", 320, "profile.email")}*/}
              {/*{this.renderProfileItem(user, "주소는", 128, "profile.addressStreet")}*/}
              {/*{this.renderProfileItem(user, "상세주소는", 64, "profile.addressStreetDetail")}*/}
              {/*{this.renderProfileItem(user, "자기소개는", 2049, "profile.introduce")}*/}
            </div>
          </div>
        </div>
        {this.apiUtils.renderLoading()}
      </div>
    );
  }
}
