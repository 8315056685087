const RadioGroup = ({ label, children, ...rest }) => {
  const containerStyle = {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: 30,
  };
  const labelStyle = {
    fontSize: 16,
    width: rest.labelwidth ? rest.labelwidth : 110,
    textAlign: 'left',
  };
  const radioContainerStyle = {
    display: 'flex',
    width: '100%',
  };
  return (
    <fieldset style={containerStyle} {...rest}>
      <label style={labelStyle}>{label}</label>
      <div style={radioContainerStyle}>{children}</div>
    </fieldset>
  );
};

export default RadioGroup;
