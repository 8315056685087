import React, { useEffect, useState } from 'react';
import '../../../css/event/smsModal.css';
import DeleteSms from '../../eventManage/modals/DeleteSms';
import APIUtils, { fetchApi } from '../../../utils/APIUtils';
import AlertMessage from '../../utilities/AlertMessage';
import SmsEditModal from './SmsEditModal';
import moment from 'moment/moment';
import Table from '../../Table/Table';
import { SmsTempSavedListColumns } from '../../Table/TableColumns';

const apiUtils = new APIUtils();
const PAGE_LIMIT = 6;

const SmsTempSaveComponent = ({ setTabValue }) => {
  const [curPage, setCurPage] = useState(1);
  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [checkedRows, setCheckedRows] = useState([]);
  const [q, setQ] = useState('');
  const [selectedRowData, setSelectedRowData] = useState();
  const [smsDeleteOpen, setSmsDeleteOpen] = useState(false);

  let intervalTimer = null;

  const [smsEditOpen, setSmsEditOpen] = useState(false);

  const fetchTempSmsSendList = (q = '', p = 1) => {
    setCurPage(p);
    apiUtils
      .fetchApi('/v1/message-service', 'get', {
        limit: PAGE_LIMIT,
        offset: p ? (p - 1) * PAGE_LIMIT : 0,
        ob: 'id',
        od: 'DESC',
        temp: 1,
        q,
      })
      .then((res) => {
        setRows(res.rows);
        setTotalCount(res.count);
      });
  };

  useEffect(() => {
    fetchTempSmsSendList();
  }, []);

  const handleDeleteBtn = async () => {
    if (checkedRows.length < 1) return AlertMessage.alertMessage('error', '삭제할 메세지를 선택해 주세요');
    const result = await AlertMessage.confirmMessage(`삭제한 후에는 되돌릴 수 없습니다. \n 선택한 SMS를 정말 삭제하시겠습니까?`);
    if (!result) return;

    const deleteEventList = rows?.map((i) => {
      if (checkedRows.includes(i.id)) return i;
    });

    checkedRows.map((i, idx) => {
      apiUtils.fetchApi(`/v1/message-service/${i.uuid}`, 'delete');
      if (checkedRows.length - 1 === idx) {
        setTimeout(() => {
          fetchTempSmsSendList();
          AlertMessage.alertMessage('success', '삭제가 성공적으로 완료되었습니다');
        }, 1000);
      }
    });
    setSelectedRowData(deleteEventList);
  };

  const leftBtn = () => {
    return (
      <button className='btn-del' onClick={handleDeleteBtn}>
        선택 삭제
      </button>
    );
  };

  const rightBtn = () => {
    return <div style={{ width: 134 }}></div>;
  };

  return (
    <div className='sms-list-container'>
      <div className='sms-list-header'>
        <div className='title'>보관 {totalCount}건</div>
        <div className='search-container'>
          <input
            type='text'
            className='search'
            placeholder='수신자 전화번호 또는 내용 검색'
            width={'100%'}
            value={q}
            onChange={(e) => {
              if (e.target.value.length > 100) {
                return AlertMessage.alertMessage('error', '최대 100자까지 입력할 수 있습니다.');
              }
              setQ(e.target.value);
              clearTimeout(intervalTimer);
              fetchTempSmsSendList(e.target.value);
            }}></input>
        </div>
      </div>
      <div className='table-container'>
        <div style={{ width: '100%', height: '500px', position: 'relative' }}>
          <Table
            columns={SmsTempSavedListColumns}
            rows={rows || []}
            variant={'bright'}
            checkbox
            leftBtn={leftBtn()}
            rightBtn={rightBtn()}
            pageSize={PAGE_LIMIT}
            rowCount={totalCount}
            curPage={curPage}
            disableSelectionOnClick
            sx={{
              '& .MuiSvgIcon-root': {
                color: 'grey',
              },
              '& .Mui-selected .MuiSvgIcon-root': {
                color: '#496DED',
              },
            }}
            onCustomPaginationChange={fetchTempSmsSendList}
            onRowClick={(params) => {
              setSmsEditOpen(true);
              setSelectedRowData(params.row);
            }}
            onCellKeyDown={(params, event) => {
              if (event.code === 'Enter') {
                setSmsEditOpen(true);
                setSelectedRowData(params.row);
              }
            }}
            componentsProps={{
              row: {
                style: {
                  borderRadius: 10,
                  marginBottom: 10,
                  backgroundColor: '#fff',
                  boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.15)',
                },
              },
            }}
            onSelectionModelChange={(ids) => {
              let checkedData = [];
              rows.map((i) => {
                if (ids.includes(i.id)) return checkedData.push(i);
              });
              setCheckedRows(checkedData);
            }}
          />
          {apiUtils.renderLoading()}
        </div>
      </div>
      {smsEditOpen && <SmsEditModal open={smsEditOpen} setOpen={setSmsEditOpen} rowInfo={selectedRowData} setTabValue={setTabValue} />}
      {/*{smsDeleteOpen && <DeleteSms open={smsDeleteOpen} setOpen={setSmsDeleteOpen} deleteData={checkedRows} fetchData={fetchTempSmsSendList} />}*/}
    </div>
  );
};

export default SmsTempSaveComponent;
