import React from "react";
import MessageBoxContainer from "./MessageBoxContainer";

export default class MessageBox extends React.Component {
  timer = null;
  state = {
    show: false,
  };
  componentDidMount() {
    if (this.props.message.length < 1) return null;
    this.setState({ show: true });
    this.timer = setTimeout(() => {
      this.setState({
        show: false,
      }, () => {
        this.props.setMessageBox("");
      });
    }, this.props.duration || 3000);
  }

  componentWillUnmount() {
		clearTimeout(this.timer);
		this.timer = null;
	}

  render() {
    if (!this.state.show || this.props.message.length < 1) return null;
    return <MessageBoxContainer message={this.props.message} duration={this.props.duration || 3000} />;
  }
}
