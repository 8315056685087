import React from 'react';
import SideNavigationComponent from './SideNavigationComponent';
import '../css/nav.css';
import { io } from 'socket.io-client';
import AlertMessage from './utilities/AlertMessage';
import APIUtils from '../utils/APIUtils';
import { IOrganizationService, IUser } from 'anuro-platform-core';
import { inject } from 'mobx-react';
import Footer from "./Footer";

const styles = {
  notification: {
    wrapper: {
      backgroundColor: '#03A8A8',
      borderRadius: 10,
      padding: '15px 23px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    imgAlertType: {
      marginRight: 15,
    },
    txtEventName: {
      fontSize: 16,
      fontWeight: 700,
      color: '#FFFFFF',
    },
    txtEventLocation: {
      fontSize: 12,
      fontWeight: 400,
      color: '#FFFFFF',
      marginTop: 5,
    },
  },
};

@inject('sessionStore')
export default class Container extends React.Component {
  apiUtils = new APIUtils();
  state = {
    isSuperUser: false,
  };

  componentDidMount() {
    this.fetchUserProfile();
  }

  fetchUserProfile = () => {
    this.apiUtils.fetchApi('/v1/me', 'get').then((r: IUser) => {
      if (r.type === 'SUPER_USER') {
        this.setState({
          isSuperUser: true,
        });
      }

      this.apiUtils.fetchApi(`/v1/me/organization/user/${r.uuid}/permissions`, 'get').then((r2) => {
        this.props.sessionStore.setUser(r);
        let tempPermission = {
          C: [],
          U: [],
          R: [],
          D: [],
        };
        r2?.map((i) => {
          i.allowedActions &&
            i.allowedActions?.map((i2) => {
              tempPermission[i2.type].push(i.code);
            });
        });
        this.props.sessionStore.setUserPermission(tempPermission);
      });
    });
  };

  render() {
    return (
      <div className='nav-container'>
        <SideNavigationComponent isSuperUser={this.state.isSuperUser} />
        <div style={{width: "100%", height: "100%"}}>
          <div className={'content'}>
            {this.props.children}
          </div>
          <Footer />
        </div>

        {AlertMessage.renderNotification()}
      </div>
    );
  }
}
