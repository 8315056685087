import React, { useEffect, useState } from 'react';
import '../../../css/groupManagement/user/userInfoEditModal.css';
import AlertMessage from '../../utilities/AlertMessage';
import { FormProvider, useForm } from 'react-hook-form';
import APIUtils, { fetchApi } from '../../../utils/APIUtils';
import BaseModal from '../../BaseModal';
import Divider from '../../Divider';
import Input from '../../Input';
import PasswordPolicyComponent from '../../settings/PasswordPolicyComponent';
import { Validator } from 'anuro-platform-core';
import StringUtils from '../../../utils/StringUtils';

const apiUtils = new APIUtils();

const EditInfoModal = ({ open, setOpen, rowInfo, fetchTable, setIsPermissionFetched }) => {
  const { register, setValue, watch, handleSubmit, reset, control, ...rest } = useForm();
  const [pwd, setPwd] = useState('');

  const refPasswordPolicy = React.useRef(null);
  const password = watch('password');

  const selectDefault = rowInfo?.groups
    ? {
        label: rowInfo.groups && rowInfo.groups[0]?.name,
        value: rowInfo.groups && rowInfo.groups[0]?.uuid,
      }
    : null;

  useEffect(() => {
    reset({
      groupUuid: selectDefault,
      name: rowInfo?.profile?.name,
      userId: rowInfo?.username,
      phoneNum: rowInfo?.profile?.phoneNumber ? StringUtils.formatPhoneNumber(rowInfo.profile.phoneNumber) : '',
    });
  }, [rowInfo]);

  const onSubmit = (data) => {
    const permissions = [];
    for (const [key, value] of Object.entries(data)) {
      if (key.includes('_permission_')) {
        const [organizationServiceId, type] = key.split('_permission_');
        value &&
          permissions.push({
            organizationServiceId,
            type,
          });
      }
    }

    const containsNonNumeric = /[a-zA-Zㄱ-ㅎ가-힣]/.test(data.phoneNum);
    const containsSpecialCharacters = /[^0-9-]/.test(data.phoneNum);
    const sanitizedValue = (data.phoneNum || '').replace(/[^0-9]/g, '');
    const isNumericLengthValid = sanitizedValue.length >= 8;

    // 이름 예외처리
    if (data.name.length === 0) {
      return AlertMessage.alertMessage('error', '사용자 이름을 입력해주세요');
    } else if (data.name.length >= 256) {
      return AlertMessage.alertMessage('error', '사용자 이름은 최대 255자입니다');
    }

    // 연락처 예외처리
    if (data.phoneNum === undefined || data.phoneNum === '') {
      return AlertMessage.alertMessage('error', '전화번호를 입력해주세요');
    } else if (containsNonNumeric) {
      return AlertMessage.alertMessage('error', '전화번호에 한글 또는 영문은 입력하실 수 없습니다');
    } else if (containsSpecialCharacters) {
      return AlertMessage.alertMessage('error', '- 를 제외한 특수문자는 들어갈 수 없습니다');
    } else if (!isNumericLengthValid) {
      return AlertMessage.alertMessage('error', '유효한 전화번호 포멧이 아닙니다');
    }

    // 비밀번호 예외처리
    if (!refPasswordPolicy.current?.isAvailable && data.password) {
      const validateResult = Validator.validatePassword(pwd);
      if (validateResult.errors.length > 0) {
        const message = refPasswordPolicy.current?.getValidationText?.(validateResult.errors[0]) || validateResult.errors[0].toString();
        AlertMessage.alertMessage('error', message.includes('공백불가') ? '공백을 입력할 수 없습니다.' : `비밀번호는 ${message} 입력해야합니다`);
      } else {
        AlertMessage.alertMessage('error', '새로운 비밀번호가 올바르지 않습니다');
      }
      return;
    }

    fetchApi(`/v1/me/organization/user/${rowInfo?.uuid}`, `patch`, {
      name: data.name,
      permissions: permissions,
      password: data.password ? data.password : undefined,
      groupUuid: data?.groupUuid?.value,
      phoneNumber: sanitizedValue,
      shouldChangePassword: data.password ? 1 : undefined,
    })
      .then((res) => {
        AlertMessage.alertMessage('success', '수정이 완료되었습니다');
        setIsPermissionFetched(true);
        fetchTable();
        setOpen(false);
      })
      .finally(() => {
        setIsPermissionFetched(false);
      })
      .catch((err) => console.log('[Line:75 err]', err));
  };

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  return (
    <BaseModal title={'사용자 정보 수정'} width={670} height={420} open={open} leftBtnLabel={'취소'} rightBtnLabel={'수정'} closeIcon handleClose={handleClose} handleLeftBtn={handleClose} handleRightBtn={handleSubmit(onSubmit)}>
      <Divider marginBottom={20} color={'#999999'} />
      <div className='user-edit-container'>
        <FormProvider register={register} handleSubmit={handleSubmit} reset={reset} {...rest}>
          {apiUtils.renderLoading()}
          <Input
            width={'100%'}
            name={'name'}
            {...register('name')}
            label={'이름*'}
            placeholder={'이름을 입력해주세요'}
            onChange={(e) => {
              if (e.target.value.length > 255) {
                setValue('name', e.target.value.slice(0, 255));
                AlertMessage.alertMessage('error', '사용자 이름은 최대 255자입니다');
              }
            }}
          />
          <Input
            width={'100%'}
            name={'phoneNum'}
            {...register('phoneNum', {
              onChange: (event) => {
                const checkIsKoreanIncluded = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
                const checkIsEngIncluded = /[a-zA-Z]/;
                const checkSpecialCharIncluded = /[!@#$%^&*()_+=\[\]{};':"\\|,.<>\/?]+/;
                if (checkIsEngIncluded.test(event.target.value)) {
                  setValue('phoneNum', event.target.value.slice(0, event.target.value.length - 1));
                  return AlertMessage.alertMessage('error', '전화번호에 한글 또는 영문은 입력하실 수 없습니다.');
                }
                if (checkIsKoreanIncluded.test(event.target.value)) {
                  setValue('phoneNum', event.target.value.slice(0, event.target.value.length - 1));
                  return AlertMessage.alertMessage('error', '전화번호에 한글 또는 영문은 입력하실 수 없습니다.');
                }
                if (checkSpecialCharIncluded.test(event.target.value)) {
                  setValue('phoneNum', event.target.value.slice(0, event.target.value.length - 1));
                  return AlertMessage.alertMessage('error', '-를 제외한 특수문자는 들어갈 수 없습니다.');
                }

                if (event.target.value.length > 13) AlertMessage.alertMessage('error', '전화번호는 -(대시)를 포함하여 13자리까지 입력할 수 있습니다.');
                event.target.value = StringUtils.formatPhoneNumber(event.target.value.replaceAll(/[-\s]/g, '').slice(0, 13)) || event.target.value.slice(0, 13);
              },
            })}
            label={'전화번호*'}
            placeholder={'ex) 01012341234'}
          />
          <Input
            width={'100%'}
            name={'userId'}
            {...register('userId')}
            label={'아이디*'}
            placeholder={'아이디를 입력해주세요'}
            disabled
            onChange={(e) => {
              if (e.target.value.length > 30) {
                setValue('userId', e.target.value.slice(0, 30));
                AlertMessage.alertMessage('error', '아이디는 최대 30자 입니다');
              }
            }}
          />
          <Input
            width={'100%'}
            name={'password'}
            type={'password'}
            {...register('password')}
            label={'비밀번호'}
            placeholder={'비밀번호를 입력해주세요'}
            onChange={(e) => {
              setPwd(e.target.value);
              if (e.target.value.length > 100) {
                setValue('password', e.target.value.slice(0, 100));
                return AlertMessage.alertMessage('error', '비밀번호는 최대 100자 입니다');
              }
            }}
          />
          <div
            style={{
              marginTop: 20,
              marginBottom: 20,
              position: 'relative',
              borderRadius: 4,
              border: '1px solid #ccc',
              padding: '25px 20px 15px 20px',
            }}>
            <PasswordPolicyComponent ref={refPasswordPolicy} password={pwd} />
          </div>
        </FormProvider>
      </div>
    </BaseModal>
  );
};

export default EditInfoModal;
