import React from 'react';
import { useHistory } from 'react-router-dom';
import '../../css/groupManagement/headerComponent.css';

const HeaderComponent = (props) => {
  const history = useHistory();

  return (
    <div className='group-header-container'>
      <div
        className='btn btn-user'
        style={props.page === 'user' ? {} : { color: '#5F6780', fontSize: '28px' }}
        onClick={() => {
          history.push('/user-management');
        }}>
        사용자 관리
      </div>
    </div>
  );
};

export default HeaderComponent;
