import React from "react";
import NotificationSystem from "react-notification-system";
import {IEvent, SERVICE, SocketParser} from "anuro-platform-core";
import { ReactComponent as ALERT } from "../../icons/messageIcons/alert.svg";
import { ReactComponent as REPORTED } from "../../icons/messageIcons/reported.svg";
import { ReactComponent as IN_PROGRESS } from "../../icons/messageIcons/in_progress.svg";
import { ReactComponent as TERMINATE } from "../../icons/messageIcons/terminate.svg";
import { ReactComponent as BEFORE_REPORT } from "../../icons/messageIcons/before_report.svg";
import { ReactComponent as CANCEL } from "../../icons/messageIcons/cancel.svg";
import { ReactComponent as MODIFY } from "../../icons/messageIcons/modify.svg";
import { ReactComponent as OCCUR } from "../../icons/messageIcons/occur.svg";
import { ReactComponent as RELEASE } from "../../icons/messageIcons/release.svg";

import {EVENT_PROGRESS_CODE, EVENT_STATUS} from "../../constants/Event";
import {EXECUTE_TYPE} from "./HandlingSocketData";
import ReactDOM from "react-dom";

export const NotificationMessage = {
	Server: {
		shutdown: "현재 서버가 종료되었습니다.",
		restart: "서버가 재시작 되었습니다",
	}
}

const styles = {
	notification: {
		DefaultStyle: {
			padding: 0,
			height: "auto",
		},
		container: {
			padding: 20,
			borderRadius: 10,
			fontWeight: "bold",
			color: "#FFFFFF",
			boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)"
		},
		error: {
			backgroundColor: "#e74c3c",
		},
		success: {
			backgroundColor: "#07bc0c",
		},
		info: {
			backgroundColor: "#3498db",
		},
		warning: {
			backgroundColor: "#F99702",
			// color: "#333",
		},
		confirm: {
			container: {
				backgroundColor: "#FFFFFF",
				color: "#000000",
				textAlign: "center"
			},
			messageContainer: {
				margin: "10px 0",
				overflow: "hidden",
				wordBreak: "break-all",
				whiteSpace: "break-spaces",
				lineHeight: 1.3
			},
			buttonContainer: {
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-around",
				marginTop: 30,
			},
			button: {
				cursor: "pointer"
			}
		},
	},
	custom_notification: {
		wrapper: {
			backgroundColor: "#03A8A8",
			borderRadius: 10,
			padding: "15px 23px",
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
		},
		txtEventName: {
			fontSize: 16,
			fontWeight: 700,
			color: "#FFFFFF"
		},
		txtEventLocation: {
			fontSize: 12,
			fontWeight: 400,
			color: "#FFFFFF",
			marginTop: 5,
		},
		serverShutdown: {
			wrapper: {
				backgroundColor: "#FFFFFF",
				borderRadius: 10,
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				border: "5px solid transparent",
				// borderRadius: 10,
				backgroundImage: "linear-gradient(white, white),\n" +
					"  linear-gradient(45deg, rgba(255, 0, 0, 1) 0%, rgba(255, 154, 0, 1) 10%, rgba(208, 222, 33, 1) 20%, rgba(79, 220, 74, 1) 30%, rgba(63, 218, 216, 1) 40%, rgba(47, 201, 226, 1) 50%, rgba(28, 127, 238, 1) 60%, rgba(95, 21, 242, 1) 70%, rgba(186, 12, 248, 1) 80%, rgba(251, 7, 217, 1) 90%, rgba(255, 0, 0, 1) 100%)",
				backgroundOrigin: "border-box",
				backgroundClip: "content-box, border-box",
			},
			content: {
				padding: "20px 23px",
				width: "100%",
				height: "100%",
				fontWeight: "bold",
				fontSize: 14,
				background: "linear-gradient(45deg, rgb(255, 0, 0, 0.5) 0%, rgb(255, 154, 0, 0.5) 10%, rgb(208, 222, 33, 0.5) 20%, rgb(79, 220, 74, 0.5) 30%, rgb(63, 218, 216, 0.5) 40%, rgb(47, 201, 226, 0.5) 50%, rgb(28, 127, 238, 0.5) 60%, rgb(95, 21, 242, 0.5) 70%, rgb(186, 12, 248, 0.5) 80%, rgb(251, 7, 217, 0.5) 90%, rgb(255, 0, 0, 0.5) 100%)",
			}
		}
	}
}
export default class AlertMessage extends React.Component {
	static notificationSystem = React.createRef();

	// type = error, success, info, warning
	static alertMessage = (type: "error" | "success" | "info" | "warning", message, position) => {
		const notification = this.notificationSystem.current;
		notification?.addNotification({
			level: type,
			position: position || "tc",
			autoDismiss: 3,
			children: (
				<div style={{...styles.notification.container, ...styles.notification[type]}}>
					{message}
				</div>
			)
		})
	}

	static renderIcon = (item: IEvent, status?: string) => {
		const parsingData = item.raw ? SocketParser.parse(SERVICE[item?.organizationEventCategory?.eventCategory?.service?.code], item.raw) : "";
		const progressCode = item.raw ? parsingData.header.PROGRESS_CODE : "";

		if(item?.reported === null) {
			if(item.raw) {
				if (status === EXECUTE_TYPE.IN_PROGRESS) {
					return <IN_PROGRESS />
				} else if (status === EXECUTE_TYPE.TERMINATED) {
					return <TERMINATE />;
				} else if (progressCode == EVENT_PROGRESS_CODE.OCCUR.code) {
					return <OCCUR />;
				} else if (progressCode == EVENT_PROGRESS_CODE.MODIFY.code) {
					return <MODIFY />;
				} else if (progressCode == EVENT_PROGRESS_CODE.RELEASE.code) {
					return <IN_PROGRESS />;
				} else if (progressCode == EVENT_PROGRESS_CODE.CANCEL.code) {
					return <CANCEL />;
				} else if (progressCode == EVENT_PROGRESS_CODE.TERMINATED.code) {
					return <TERMINATE />;
				} else {
					return <MODIFY />;
				}
			} else {
				if (status === EXECUTE_TYPE.IN_PROGRESS) {
					return <IN_PROGRESS />
				} else if (status === EXECUTE_TYPE.TERMINATED) {
					return <TERMINATE />;
				} else if(status === EXECUTE_TYPE.MOD) {
					return <MODIFY />;
				} else if (item.status === EVENT_STATUS.STANDBY.code) {
					return <OCCUR />;
				} else if(item.status === EVENT_STATUS.TERMINATED.code) {
					return <TERMINATE />
				} else {
					return <MODIFY />
				}
			}
		} else {
			if(item.reported) {
				return <REPORTED />
			} else {
				return <BEFORE_REPORT />
			}
		}
	}

	static eventResponseMessage = (item: IEvent, status?: string) => {
		// event.preventDefault();
		const notification = this.notificationSystem.current;
		notification?.addNotification({
			level: 'success',
			autoDismiss: 10,
			children: (
				<div style={{...styles.custom_notification.wrapper}}>
					<div>
						{this.renderIcon(item, status)}
					</div>
					<div style={{marginLeft: 15}}>
						<div style={{...styles.custom_notification.txtEventName}}>{item.organizationEventCategory?.eventCategory?.service.name}</div>
						<div style={{...styles.custom_notification.txtEventLocation}}>{item.addressStreet}</div>
					</div>
				</div>
			)
		})
	}

	static serverMessage = (msg: string) => {
		// event.preventDefault();
		const notification = this.notificationSystem.current;
		notification?.addNotification({
			level: 'success',
			autoDismiss: 10,
			children: (
				<div style={{...styles.custom_notification.serverShutdown.wrapper}}>
					<div style={{...styles.custom_notification.serverShutdown.content}}>
						{msg}
					</div>
				</div>
			)
		})
	}

	static confirmMessage = (message) => {
		const notification = this.notificationSystem.current;
		const uid = notification.uid;
		this.addBackground();
		return new Promise((res, rej) => {
			notification?.addNotification({
				level: 'success',
				autoDismiss: 0,
				position: "tc",
				dismissible: false,
				onAdd: () => {
					setTimeout(() => {
						document.getElementById("confirm-container").tabIndex = -1;
						document.getElementById("confirm-container").focus();
					}, 300);
				},
				children: (
					<div id="confirm-container" style={{...styles.notification.container, ...styles.notification.confirm.container}} onKeyDown={(e) => {
						if (e.key === 'Escape') {
							this.removeBackground(uid);
							document.getElementsByClassName("MuiBox-root")?.[0]?.focus?.();
							return res(false);
						}
						if (e.key === 'Enter') {
							this.removeBackground(uid);
							return res(true);
						}
					}}>
						<div style={styles.notification.confirm.messageContainer}>{message}</div>
						<div style={styles.notification.confirm.buttonContainer}>
							<div className="" style={styles.notification.confirm.button} onClick={() => {
								this.removeBackground(uid);
								res(false);
							}}>취소</div>
							<div style={styles.notification.confirm.button} onClick={() => {
								this.removeBackground(uid);
								res(true);
							}}>확인</div>
						</div>
					</div>
				)
			});
		})
	}

	static addBackground = () => {
		let notificationElement = document.getElementsByClassName("notifications-wrapper");
		notificationElement.item(0).classList.add("withBg");
	}
	static removeBackground = (uid) => {
		const notification = this.notificationSystem.current;
		let notificationElement = document.getElementsByClassName("notifications-wrapper");
		notificationElement.item(0).classList.remove("withBg");
		notification.removeNotification(uid);
	}
	// tpm, 에러 복구 시간

	static renderNotification = () => {
			// return (
			// 	ReactDOM.createPortal(
			// 		<NotificationSystem ref={this.notificationSystem} style={styles.notification.DefaultStyle} tabIndex={0} />,
			// 		document.body
			// 	)
			// )
			return <NotificationSystem autoFocus={true} ref={this.notificationSystem} style={styles.notification.DefaultStyle} tabIndex={0} />
	}
}
