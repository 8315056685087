import React, {CSSProperties} from "react";

interface Props {
  styles?: CSSProperties;
}
interface States {}

export default class Footer extends React.Component<Props, States> {
  render() {
    return (
      <div style={{color: "#CCCCCC", textAlign: "right", fontSize: 14, paddingTop: 5, paddingRight: 30, ...this.props.styles}}> SmartCity Metasafe Platform v1.0</div>
    );
  }
}
