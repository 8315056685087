import React from 'react';
import {api_tta} from '../../api/api_tta';
import {withRouter} from 'react-router-dom';
import {logWithTs, USER} from 'anuro-platform-core';
import moment from 'moment';
import ChangePasswordModal from './ChangePasswordModal';
import {SETTING_PANEL, SETTING_TAB} from './SettingModal';
import {ReactComponent as PROFILE} from "../../icons/setting/profile.svg";
import {ReactComponent as PASSWORD_CHANGE} from "../../icons/setting/password-change.svg";
import {ReactComponent as LAST_LOG} from "../../icons/setting/last-log.svg";
import {ReactComponent as PERMISSION} from "../../icons/setting/permission.svg";
import {ReactComponent as LOGOUT} from "../../icons/setting/logout.svg";
import AlertMessage from "../utilities/AlertMessage";
import {Button, Tooltip} from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import {inject} from "mobx-react";
import APIUtils from "../../utils/APIUtils";

@inject("sessionStore")
class SettingProfilePanelComponent extends React.Component {

  apiUtils = new APIUtils();
  state = {
    isPasswordChangeModalOpen: false,
    isTransferAccountModalOpen: false,
  };
  // !!! 로그아웃
  logout = async () => {
    const result = await AlertMessage.confirmMessage("로그아웃 하시겠습니까?");
    if(!result) return null;
    try {
      const res = await api_tta.delete('/v1/auth/session');
      if (res.ok) {
        logWithTs('세션삭제 성공');
      } else {
        logWithTs('세션삭제 실패', res);
      }
    } catch (err) {
      logWithTs(err);
    }
  };

  handleModal = (modal, value) => this.setState({ [modal]: value });

  downloadLog = (type: "pm" | "service") => {
    this.apiUtils.fetchApi(`/v1/log/${type}`, 'get', {

    }).then((r) => {
      const blob = new Blob([r]);
      const fileObjectUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = fileObjectUrl;
      link.style.display = "none";

      link.download = `log-${moment().format("YYYYMMDDHHmmss")}.txt`;

      document.body.appendChild(link);
      link.click();
      link.remove();

      URL.revokeObjectURL(fileObjectUrl);
    }).catch(err => {
      console.log("[err log]", err);
    })
  }

  render() {
    const { userProfile, handleData } = this.props;
    return (
      <div className={'right profile'} style={{ position: 'absolute' }}>
        <div>
          <div className={'item cursor hover-opacity hover-shadow'} onClick={() => handleData(SETTING_PANEL.PROFILE_MODIFY_INFORMATION, SETTING_TAB.PROFILE)}>
            <PROFILE />
            <div className={'text-container'}>
              <div>정보수정</div>
            </div>
          </div>
          <div className={'item cursor hover-opacity hover-shadow'} onClick={() => this.handleModal('isPasswordChangeModalOpen', true)}>
            <PASSWORD_CHANGE />
            <div className={'text-container'}>
              <div>비밀번호 변경</div>
              <div className={'small-txt'}>마지막으로 변경한 날짜: {userProfile?.passwordHistories ? moment(userProfile.passwordHistories[0]?.createdAt).format('YY년 MM월 DD일 HH시 mm분 ss초') : ''}</div>
            </div>
          </div>
          <div className={'item cursor hover-opacity hover-shadow'} onClick={() => handleData(SETTING_PANEL.PROFILE_LOGIN_HISTORY, SETTING_TAB.PROFILE)}>
            <LAST_LOG />
            <div className={'text-container'}>
              <div>로그인 기록</div>
              <div className={'small-txt'}>마지막으로 로그인한 날짜: {userProfile?.authenticationLogs ? moment(userProfile.authenticationLogs[0]?.createdAt).format('YY년 MM월 DD일 HH시 mm분 ss초') : ''}</div>
            </div>
          </div>
          {userProfile.type === USER.TYPE.ACCOUNT_HOLDER && (
            <div className={'item cursor hover-opacity hover-shadow'} onClick={() => this.handleModal('isTransferAccountModalOpen', true)}>
              <PERMISSION />
              <div className={'text-container'}>
                <div>소유자 계정 이전</div>
                <div className={'small-txt'}>조직 내 다른 계정으로 소유자 권한을 이전합니다</div>
              </div>
            </div>
          )}
          <div className={'item cursor hover-opacity hover-shadow'} onClick={this.logout}>
            <LOGOUT />
            <div className={'text-container'}>
              <div>로그아웃</div>
            </div>
          </div>
        </div>

        {
          <div style={{textAlign: "right"}}>
            {/*<Tooltip title={"PM 로그 다운로드"}>*/}
            {/*  <SaveIcon style={{marginRight: 10, cursor: "pointer"}} onClick={() => this.downloadLog('pm')}/>*/}
            {/*</Tooltip>*/}
            <Tooltip title={"로그 다운로드"}>
              <SaveIcon style={{cursor: "pointer"}} onClick={() => this.downloadLog('service')} />
            </Tooltip>
          </div>
        }
        {this.state.isPasswordChangeModalOpen && <ChangePasswordModal title='비밀번호 변경' onClose={() => this.handleModal('isPasswordChangeModalOpen', false)} />}
        {this.apiUtils.renderLoading(true)}
      </div>
    );
  }
}

export default withRouter(SettingProfilePanelComponent);
