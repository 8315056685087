import React from "react";

const Divider = (props) => {
	const { marginTop, marginBottom, width, height, color } = props;
	return (
		<div
			style={{
				width: width ? width : "100%",
				marginTop: marginTop,
				marginBottom: marginBottom,
				height: height && typeof height === "number" ? height : 1,
				backgroundColor: color ? color : "#E2E2E2",
			}}
		></div>
	);
};

export default Divider;
