import React, { useState } from "react";
import moment from "moment";
import { useInterval } from "react-use";

const CurrentTime = () => {
	const [realTime, setRealTime] = useState(Date.now());
	const currentTime = moment(realTime).format(`HH:mm`);
	const Meridiem = moment().format(`A`);

	// useInterval
	useInterval(() => {
		setRealTime(Date.now());
	}, 1000);

	return (
		<div className="current-time-container">
			<div className="current-time">{currentTime}</div>
			<div className="meridiem">{Meridiem}</div>
		</div>
	);
};

export default CurrentTime;
