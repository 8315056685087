import './App.css';
import Router from './routes';
import { Provider } from 'mobx-react';
import RootStore from './stores/RootStore';
import { api_tta } from './api/api_tta';
import { StorageUtils } from './utils/StorageUtils';
import { WSC } from './service/WSC';
import { JWTUtils } from './utils/JWTUtils';
import { BaseProvider } from './hooks/useBase';

const rootStore = new RootStore();

if (JWTUtils.isAuthenticated()) {
  const authorization = StorageUtils.getAuthorization();

  // API
  authorization && api_tta.setHeader('Authorization', authorization);

  // Web Socket Client
  WSC.getInstance().start();
}

function App() {
  return (
    <BaseProvider>
      <Provider {...rootStore}>
        <Router />
      </Provider>
    </BaseProvider>
  );
}

export default App;
