import React from 'react';

export default class SettingBaseModal extends React.Component {

  render() {
    const { title, open, onClose, children, width, height, className, withBackground } = this.props;
    if (!open) return;
    return (
      <div
        id={"setting-modal-container"}
        tabIndex={0}
        key={`setting-modal ${className}`}
        style={{
          position: 'fixed',
          left: '50%',
          top: '50%',
          width: withBackground ? '100%' : width || 'auto',
          height: withBackground ? '100%' : height || 'auto',
          transform: 'translate(-50%, -50%)',
          zIndex: 150,
        }}>
        <div style={{borderRadius: 10, width: "100%", height: "100%", backgroundColor: withBackground ? "#33333390" : "transparent"}}
             onClick={() => onClose(false)}/>
        <div style={{ width: withBackground ? width : '100%', height: withBackground ? height : '100%', ...(withBackground && {
            position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)",
          })}} tabIndex={0} onKeyDown={(e) => {
            if(e.key === "Escape" && open) onClose(false);
          }}
        >
          <div className={'setting-modal-container'}>
            <div className={'header'}>
              <div className={'title'}>{title}</div>
              <div className={'tab-container'}>
                <div className={'close'} style={{ margin: '10px 0', fontSize: 35 }} onClick={() => onClose(false)}>
                  &times;
                </div>
              </div>
            </div>
            {children}
          </div>
        </div>
      </div>
    );
  }
}
