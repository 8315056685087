import React, { createRef } from 'react';
import EventEmitter from '../utils/EventEmitter';
import $ from 'jquery';
import AddressInput from './AddressInput';
import NaverMapUtils from '../utils/NaverMapUtils';
import {inject, observer} from 'mobx-react';
import { CCTV_STATUS_AVAILABLE } from '../stores/CCTVStore';
import {IEvent, SERVICE, SocketParser} from "anuro-platform-core";
import APIUtils from "../utils/APIUtils";
import {IService} from "anuro-platform-core";
import {EVENT_DEFAULT_ICON, EVENT_PROGRESS_CODE, EVENT_STATUS} from "../constants/Event";
import {IFacilityType} from "anuro-platform-core";
import {IFacility} from "anuro-platform-core";
import cctvIcon from "../icons/marker/cctv-icon.png";
import cctvErrorIcon from "../icons/marker/cctv-error-icon.png";

const MAP_STATUS_UNAVAILABLE = -1;
const MAP_STATUS_LOADING = 0;
const MAP_STATUS_AVAILABLE = 1;

@inject('cctvStore')
@observer
export default class MapNaver extends React.Component {
  emitter = EventEmitter.getInstance();
	apiUtils = new APIUtils();
  mapRef = createRef();
  state = {
    eventList: [],
    cctvList: [],
		serviceList: [],
		facilityList: [],
    mapTool: {
      distance: false,
      radius: false,
      area: false,
      layer: false,
    },
    mapLayer: {},
    zoom: 17,
    polyDraw: [],
    polyText: [],
    isMapAvailable: MAP_STATUS_LOADING,
    cnt: 0,
  };

  map = null;
  Measure = null;

  // !!! markers
  cctvMarkers = [];
  eventMarkers = [];
  markerClustering = null;
  // 주소 검색 시 마커
  searchMarker = null;

  infoWindows = null;
  monitoringMarkers = [];

  componentDidMount() {
    this.emitter.on('onChangeMarkerData', ({key, value}) => this.onChangeMarkerData(key, value));
    this.emitter.on('removeMarker', (markerType) => this.removeMarker(markerType));
    this.emitter.on('moveTo', (lat, lng, withMarker) => this.moveTo(lat, lng, withMarker));
    this.emitter.on("layer_fetchServiceList", this.fetchServiceList);
    this.emitter.on("layer_fetchFacilityList", this.fetchFacilityList);
    this.emitter.on("addEventMarker", this.addEventMarker);
    this.emitter.on("addCCTVMarker", this.addCCTVMarker);
    this.emitter.on('resizeMap', this.handleResize);

    try {
      this.map = new window.naver.maps.Map('map', {
        center: new window.naver.maps.LatLng(35.172771695080264, 129.12572467858556),
        zoom: this.props.zoom || 17,
        zoomControl: true,
        zoomControlOptions: {
          position: 6, // left_bottom
        },
      });

      this.measures = new NaverMapUtils({
        distance: $('#distance'),
        area: $('#area'),
        clear: $('#clear'),
        layer: $('#layer'),
        radius: $('#radius'),
        this: this,
      });

      this.measures.setMap(this.map);
    } catch (err) {
      this.setState({ isMapAvailable: MAP_STATUS_UNAVAILABLE });
      console.log(err);
    }

    setTimeout(() => {
      if (!this.mapRef.current?.style?.background?.toString()?.includes('auth_fail.png') && this.state.isMapAvailable !== MAP_STATUS_UNAVAILABLE) {
        this.setState({ isMapAvailable: MAP_STATUS_AVAILABLE });
				this.fetchServiceList();
				this.fetchFacilityList();
      } else {
        this.map.destroy();
        this.setState({ isMapAvailable: MAP_STATUS_UNAVAILABLE });
      }
    }, 1000);
  }

	// !!! 등록된 emitter 해제
  componentWillUnmount() {
    this.emitter.off('onChangeMarkerData', this.onChangeMarkerData);
    this.emitter.off('removeMarker', this.removeMarker);
    this.emitter.off('moveTo', this.moveTo);
    this.emitter.off("layer_fetchServiceList", this.fetchServiceList);
    this.emitter.off("layer_fetchFacilityList", this.fetchFacilityList);
    this.emitter.off("addEventMarker", this.addEventMarker);
    this.emitter.off("addCCTVMarker", this.addCCTVMarker);
    this.emitter.off('resizeMap', this.handleResize);
  }

  handleResize = () => {
    setTimeout(() => {
      window.naver.maps.Event.trigger(this.map, 'resize');
    }, 300);
  }

	// !!! 연계서비스 목록 호출
	fetchServiceList = () => {
		this.apiUtils.fetchApi('/v1/me/organization/service', 'get').then((r) => {
			this.setState({ serviceList: r.map((i: IService) => {return {...i, isShow: true}}) });
		});
	};

	// !!! 시설물 목록 호출
	fetchFacilityList = () => {
		this.apiUtils.fetchApi('/v1/me/organization/facility-type', 'get').then((r) => {
			this.setState({ facilityList: r.map((i: IFacilityType) => {return {...i, isShow: true}}) });
		});
	};

  // !!! 맵에 CCTV Marker 추가
  addCCTVMarker = () => {
    if (this.state.isMapAvailable === MAP_STATUS_UNAVAILABLE) return ;
    if (this.state.cctvList.length === 0) return ;
    this.removeMarker({markerType: "cctv"});

    const available = this.state.facilityList.map((i) => {
      if(!i.isShow) return i.organizationFacilityType.facilityUuid;
    });

    this.cctvMarkers = [];
    this.infoWindows = [];

    this.state.cctvList.map((i: IFacility, index) => {
      if(available.includes(i.organizationFacilityType.facilityUuid)) return ;
      let spot = i;
      let latlng = new window.naver.maps.LatLng(spot.latitude, spot.longitude);

      let marker = new window.naver.maps.Marker({
          position: latlng,
          draggable: false,
          // TODO 클러스터링 시 해당 마커 맵에 등록하는거 제거하기
          map: this.map,
          icon: {
            content: `
                <div style="width: 42px; height: 42px; position: relative; display: flex; align-items: center; justify-content: center;">
                    <img style="max-width: 42px; max-height: 42px;" src="${i.active === 1 ? !this.props.cctvStore.workingCCTVList.includes(i.uuid) ? cctvErrorIcon : cctvIcon : cctvErrorIcon}"/>
                </div>`,
            size: new window.naver.maps.Size(42, 42),
            origin: new window.naver.maps.Point(0, 0),
            anchor: new window.naver.maps.Point(21, 21),
          },
        });

      let infoWindow = new window.naver.maps.InfoWindow({
        content: [
          `<div class="map-cctv-infowindow" id="cctv-infoWindow-${index}">`,
          `   	<span style="font-size: 14px; font-weight: 700; color: #FFFFFF;">${i.name}</span>`,
          `			${(i.active === CCTV_STATUS_AVAILABLE && this.props.cctvStore.workingCCTVList.includes(i.uuid))? "<span style='color: #78D700; font-size: 11px; margin-left: 5px;'>●</span>" : ''}`,
          `</div>`,
        ].join(''),
        borderWidth: 0,
        backgroundColor: '#ff000030',
        disableAnchor: true,
        anchorSkew: true,
        anchorColor: 'blue',
        pixelOffset: new window.naver.maps.Point(0, -5),
      });
      this.infoWindows?.push(infoWindow);
      this.cctvMarkers?.push(marker);
      window.naver.maps.Event.addListener(marker, 'mouseover', (e) => this.infoWindows[index]?.open(this.map, marker));
      window.naver.maps.Event.addListener(marker, 'mouseout', (e) => this.infoWindows[index]?.close());
    });
  };

  // !!! 맵에 Event 마커 추가
  addEventMarker = () => {
    if (this.state.isMapAvailable === MAP_STATUS_UNAVAILABLE) return;
    if (this.state.eventList?.length === 0) return;
    this.removeMarker({markerType: "event"});

    const available = this.state.serviceList.map(i => {
      if(!i.isShow) return i.code
    });

    this.eventMarkers = [];
    this.state.eventList?.map((i: IEvent, idx) => {
      const parsingData = i.raw ? SocketParser.parse(SERVICE[i?.organizationEventCategory?.eventCategory?.service?.code], i.raw) : "";
      const progressCode = i.raw ? parsingData.header.PROGRESS_CODE : "";

      if((progressCode == EVENT_PROGRESS_CODE.TERMINATED.code || i.status === EVENT_STATUS.TERMINATED.code)) return ;
      if(available.includes(i.organizationEventCategory?.eventCategory?.service.code)) return ;

      let spot = i;
      let isEventProcessing = progressCode == EVENT_PROGRESS_CODE.RELEASE.code || spot.status === EVENT_STATUS.IN_PROGRESS.code;
      let latlng = new window.naver.maps.LatLng(spot?.latitude, spot?.longitude);
      let marker = new window.naver.maps.Marker({
        position: latlng,
        draggable: false,
        map: this.map,
        icon: {
          ...(!isEventProcessing && {
            content:
              progressCode != EVENT_PROGRESS_CODE.RELEASE.code ?
                `<div style="width: 56px; height: 56px; border-radius: 100px; position: relative">
                 <img style="max-width: 56px; max-height: 56px; border-radius: 100px;" src="${i.organizationEventCategory?.organizationService?.iconUrl || EVENT_DEFAULT_ICON[i?.organizationEventCategory?.eventCategory?.service?.code] || ""}"/>
               </div>` :
                `<div style="width: 56px; height: 56px; border-radius: 100px; position: relative">
                    <img style="max-width: 56px; max-height: 56px; border-radius: 100px;" src="${i.organizationEventCategory?.organizationService?.iconUrl || EVENT_DEFAULT_ICON[i?.organizationEventCategory?.eventCategory?.service?.code] || ""}"/>
                    <div style="position: absolute; left: 50%; top: 50%; width: 56px; height: 56px; transform: translate(-50%, -50%); border-radius: 100px; background-color: #99999990;" />
                </div>`
          }),
          ...(isEventProcessing && {
            content: `<div class="event-occur-marker">
								<img src="/image/event_icon.png"/>
								<div class="circle wave1"></div>
								<div class="circle wave2"></div>
								<div class="circle wave3"></div>
								<div class="circle wave4"></div>
							</div>`,
          }),
          size: isEventProcessing ? new window.naver.maps.Size(36, 36) : new window.naver.maps.Size(56, 56),
          origin: new window.naver.maps.Point(0, 0),
          anchor: isEventProcessing ? new window.naver.maps.Point(18, 18) : new window.naver.maps.Point(28, 28),
        },
      });

      this.eventMarkers.push(marker);
    });
  };

  // !!! marker 삭제 함수 => markerType = "all" | "cctv" | "event" | "search"
  removeMarker = ({ markerType }) => {
    if (this.state.isMapAvailable === MAP_STATUS_UNAVAILABLE) return;
    if (markerType === "cctv" || markerType === "all") {

      if (this.markerClustering?._clusters?.length > 0) {
        for (let i = 0; i < this.markerClustering?._clusters?.length; i++) {
          this.markerClustering?._clusters[i]?._markerClusterer?.setMap(null);
        }
      }
      if (this.cctvMarkers.length > 0) {
        for(let i = 0; i < this.cctvMarkers?.length; i++) {
          this.cctvMarkers[i].setMap(null);
        }
      }
        this.cctvMarkers = [];
      if (this.monitoringMarkers.length > 0) {
        for (let i = 0; i < this.monitoringMarkers.length; i++) {
          this.monitoringMarkers[i] = null;
        }
        this.monitoringMarkers = [];
      }
    }
    if (markerType === 'search' || markerType === "all") {
      this.searchMarker?.setMap(null);
    }
    if(markerType === "event" || markerType === "all") {
      for (let i = 0; i < this.eventMarkers.length; i++) {
        this.eventMarkers[i].setMap(null);
      }
      this.eventMarkers = [];
    }
    if(markerType === "eventCircle" || markerType === "all") {
      this.eventCircle && this.eventCircle.setMap(null);
      this.eventCircle = null;
    }
  };

  onChangeMarkerData = (key, value) => {
    this.setState({[key]: value}, () => {
      if (key === "eventList") {
        this.addEventMarker();
      } else if(key === "cctvList") {
        this.addCCTVMarker();
      }
    });
  }

	// !!! 맵을 해당 좌표로 이동하는 함수
  moveTo = ({ lat, lng, withMarker }) => {
    if (this.state.isMapAvailable === MAP_STATUS_UNAVAILABLE) return;

    let pos = new window.naver.maps.LatLng(lat, lng);
    this.map.panTo(pos, 400);
    setTimeout(() => {
      this.setState(
        {
          zoom: 16,
        },
        () => {
          this.map.setZoom(16, true);
        },
      );
    }, 1000);

    if (withMarker) {
      if (this.searchMarker) {
        this.searchMarker.setMap(null);
      }
      this.searchMarker = null;
      this.searchMarker = new window.naver.maps.Marker({
        position: new window.naver.maps.LatLng(lat, lng),
        map: this.map,
        icon: {
          url: require('../icons/marker/marker4-blue.png'),
          size: new window.naver.maps.Size(38, 49),
          origin: new window.naver.maps.Point(0, 0),
          anchor: new window.naver.maps.Point(19, 49),
        },
      });

      window.naver.maps.Event.addListener(this.searchMarker, 'click', () => {
        this.searchMarker.setMap(null);
      });
    }
  };

	// !!! 맵 로드에 실패 했을 때
  renderDisabledMap = () => {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          border: '1px solid #656565',
          borderRadius: 5,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <span style={{ color: '#FFFFFF', fontWeight: 'bold' }}>맵을 로드하는데 실패했습니다</span>
      </div>
    );
  };

  // !!! 맵 레이어 핸들러 함수 (레이어 버튼에 있는 항목을 눌렀을 때)
  onChangeLayer = (type, idx, value) => {
    let tempArr = [...this.state[type]];
    tempArr[idx].isShow = value;
    this.setState({ [type]: tempArr }, () => {
      if (type === "serviceList") {
        this.addEventMarker();
      } else if (type === "facilityList") {
        this.addCCTVMarker();
      }
    });
  }

  render() {
    if (this.state.isMapAvailable === MAP_STATUS_UNAVAILABLE) return this.renderDisabledMap();
    return (
      <div key='map' ref={this.mapRef} id={'map'} style={{ width: '100%', height: '100%' }} onContextMenu={this.offMapTool}>
        {this.props.maptool && (
          <>
            <div style={{ position: 'absolute', zIndex: 2, left: 30, top: 20, transition: 'left 0.5s' }}>
              <AddressInput placeholder={'위치(장소명, 주소) 검색'} searchIcon cn={'search'} radius={10} width={300} height={45} clearMarker={this.clearMarker} />
            </div>
          </>
        )}
      </div>
    );
  }
}
