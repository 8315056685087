import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import '../../css/authentication/signin.css';
import { Button, Container, TextField } from '@material-ui/core';
import Grid from '@mui/material/Grid';
import { inject, observer } from 'mobx-react';
import { WSC } from '../../service/WSC';
import { useForm } from 'react-hook-form';
import AlertMessage from '../../components/utilities/AlertMessage';
import CircularProgress from '@mui/material/CircularProgress';
import Footer from '../../components/Footer';
import { fetchApi } from '../../utils/APIUtils';
import moment from 'moment';

interface IData {
  username: string | null;
  password: string;
  saveId: boolean;
}

const SignIn = (props) => {
  const [loading, setLoading] = useState(false);
  const form = useForm<IData>({
    defaultValues: {
      username: '',
      password: '',
      saveId: false,
    },
  });
  const history = useHistory();

  const pwdHistory = async () => {
    const PWD_EXPIRED_DATE = 180;
    try {
      await fetchApi(`/v1/me/password-history`, 'get', { ob: 'id', od: 'DESC' }).then((r) => {
        const a = moment(Date.now());
        const b = moment(r.rows[0].createdAt);

        // const startDate = '23.06.2024';
        // const new_date = moment(startDate, 'DD-MM-YYYY').add('days', 5);

        const c = a.diff(b, 'days');

        console.log(`오늘 날짜: ${a}`);
        console.log(`최근 변경일: ${b}`);
        console.log(`비밀번호를 변경한지 ${c}일 경과됐습니다`, c);
        console.log(`비밀번호를 변경한지 ${PWD_EXPIRED_DATE}일이 지나면 비밀번호 변경창이 뜹니다`);

        if (c >= PWD_EXPIRED_DATE) {
          console.log(`비밀번호를 변경한지 ${PWD_EXPIRED_DATE}일이 지나서 비밀번호 변경창으로 이동합니다`);
          return history.replace('/change-old-password');
        } else {
          AlertMessage.alertMessage('success', 'success');
          history.replace('/gis');
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleSubmit = form.handleSubmit((data) => {
    if (loading) return;
    setLoading(true);

    props.sessionStore
      .signIn(data.username, data.password, false)
      .then(async () => {
        WSC.getInstance().start();

        if (props.sessionStore.user.shouldChangePassword) {
          history.push('/change-password');
          return;
        }
        await pwdHistory();
      })
      .catch((e) => {
        console.log(e.data);
        if (e.data === 'TOKEN_NOT_EXISTS') {
          AlertMessage.alertMessage('error', '로그인 실패(T404)');
        } else if (e.data?.code === 'FAILED_COUNT_LIMIT_REACHED') {
          AlertMessage.alertMessage(
            'error',
            <div>
              <div style={{ marginBottom: 5 }}>5분내에 5번의 로그인 실패 시도 후,</div>
              <div style={{ marginBottom: 5 }}>계정이 임시 보호 조치 되었습니다.</div>
              <div>5분 후 다시 시도해 주세요.</div>
            </div>,
          );
        } else {
          AlertMessage.alertMessage('error', '아이디와 비밀번호를 확인해주세요');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  });

  return (
    <Container maxWidth='md'>
      <form onSubmit={handleSubmit}>
        <Grid container style={{ height: '100vh', padding: '0 1.5rem' }} alignItems='center' justifyContent='center' columnSpacing={{ xs: 0, md: 6 }}>
          <Grid item md={6}>
            <div className='native-signin'>
              <p className='header'>일반 로그인</p>
              <TextField autoFocus type={'text'} label={'아이디를 입력해주세요'} variant={'outlined'} className='signin-input' {...form.register('username', { required: '아이디를 입력해주세요' })} helperText={form.formState.errors.username?.message} error={!!form.formState.errors.username} />
              <TextField type={'password'} label={'비밀번호를 입력해주세요'} variant={'outlined'} className='signin-input' {...form.register('password', { required: '비밀번호를 입력해주세요' })} helperText={form.formState.errors.password?.message} error={!!form.formState.errors.password} />
              <Button type={'submit'} disabled={loading} onClick={handleSubmit} className='signin-btn' variant='outlined' style={{ marginTop: '5rem' }}>
                {loading && <CircularProgress size={'20px'} sx={{ mr: '10px' }} />}
                로그인
              </Button>
            </div>
          </Grid>
        </Grid>
      </form>
      <Footer styles={{ position: 'absolute', bottom: 20, right: 0, color: '#000' }} />
      {AlertMessage.renderNotification()}
    </Container>
  );
};

export default inject('sessionStore')(observer(SignIn));
