import React, { forwardRef } from 'react';

const Radio = forwardRef(({ children, value, name, defaultChecked, disabled, ...props }, ref) => {
	const containerStyle = {
		display: 'flex',
		alignItems: 'center',
		marginRight: 14,
	};
	const radioStyle = {};

	return (
		<label style={containerStyle}>
			<input style={radioStyle} type={'radio'} value={value} name={name} {...props} ref={ref} />
			{children}
		</label>
	);
});

export default Radio;
