import {action, makeAutoObservable, observable, toJS} from 'mobx';
import APIUtils from "../utils/APIUtils";

export const CCTV_STATUS_AVAILABLE = 1;
export const CCTV_STATUS_UNAVAILABLE = 0;

export default class CCTVStore {
	apiUtils = new APIUtils();

	isCalledApi = false;
	@observable cctvList = [];
	@observable workingCCTVList = [];
	@observable capturedImageList = [];

	constructor() {
		makeAutoObservable(this);
	}

	// !!! 캡처된 이미지 목록 호출 API
	fetchCapturedImage = () => {
		if(this.isCalledApi) return ;
		this.apiUtils.fetchApi(`/v1/me/organization/facility/cctv/snapshot-all`, "get", {ob: "id", od: "DESC"}).then(r => {
			this.setCapturedImageList = r.rows.map(i => {return {...i, checked: false}});
			this.isCalledApi = true;
		})
	}

	goToTopInPanel = (item) => {
		const index = this.cctvList.findIndex((i) => i.uuid === item.uuid);
		if (index > -1) {
			let temp = [...this.cctvList];
			temp[index] = {
				...temp[index],
				click: temp[index].click ? '' : new Date(),
			}

			this.cctvList = [...temp];

			this.cctvList.sort((a, b) => {
				if (a.click < b.click) return 1;
				if (a.click > b.click) return -1;
				if (a.uuid > b.uuid) return 1;
				if (a.uuid < b.uuid) return -1;
			});
			return temp[index];
		}
	};

	set setCCTVList(cctvList) {
		this.cctvList = [...cctvList];
	}

	get getCCTVList() {
		return this.cctvList;
	}

	set setWorkingCCTVList(workingCCTVList) {
		this.workingCCTVList = workingCCTVList;
	}

	set setCapturedImageList(capturedImageList) {
		this.capturedImageList = [...capturedImageList];
	};

	getCapturedImagesByCCTVId = (cctvId) => {
		if(this.capturedImageList.length === 0) return ;
		return this.capturedImageList.filter((i) => {
			if(i?.cctvId === cctvId) {
				return {
					...i, checked: false
				}
			}
		});
	};

	@action.bound
	addCaptureImageList = (capturedImage) => {
		this.capturedImageList.push(capturedImage);
	};

	updateCaptureImageList = (imageId, updatedData) => {
		const index = this.capturedImageList.findIndex((i) => i.id === imageId);
		if (index > -1) {
			this.capturedImageList[index] = updatedData;
		}
	};

	@action.bound
	deleteCaptureImage = (id) => {
		const index = this.capturedImageList.findIndex((i) => i.id === id);
		if (index > -1) {
			this.capturedImageList.splice(index, 1);
		}
	};
}
