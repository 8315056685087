export default class ObjectUtils {
  /* object 를 문자열로 찾기
      ex)
      const object = {
        a: {
          b: "test"
        }
      }
      getObjectByString(object, "a.b") => "test"
  */
  public static getObjectByString = (o: any, s: string) => {
    s = s.replace(/\[(\w+)\]/g, '.$1');
    s = s.replace(/^\./, '');
    let a = s.split('.');
    for (let i = 0, n = a.length; i < n; ++i) {
      let k = a[i];
      if (k in o) {
        o = o[k];
      } else {
        return;
      }
    }
    return o;
  }

  public static areObjectsSame = (obj1: object, obj2: object): boolean => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }
}
