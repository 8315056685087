import {logWithTs, WSCEvent, WSSEvent} from 'anuro-platform-core';
import {io} from 'socket.io-client';
import {baseURL} from "../api/api_tta";
import {StorageUtils} from "../utils/StorageUtils";
import {ManagerOptions} from "socket.io-client/build/esm/manager";
import {SocketOptions} from "socket.io-client/build/esm/socket";

const LOG_PREFIX = '[WSC]';

const ioOptions: Partial<ManagerOptions & SocketOptions> = {
  path: '/socket.io',
  auth: (cb) => {
    cb({
      token: StorageUtils.getAuthorization(),
    });
  },
  timeout: 5000,
  autoConnect: false,
  reconnection: true,
  reconnectionDelay: 500,
  // transports: ['websocket'],
};

export class WSC {
  private static instance: WSC;
  private status: WSC.Status = WSC.Status.STOPPED;

  public readonly socket = io(baseURL, ioOptions);

  // Lock
  private isLockedStart = false;
  private isLockedStop = false;

  private constructor() {
    console.log("WSC():initialized at", Date.now());
  }

  public static getInstance(): WSC {
    return this.instance || (this.instance = new this());
  }

  public start = () => {
    if (this.isLockedStart) return;
    this.isLockedStart = true;

    const release = () => {
      this.isLockedStart = false;
    };

    if (this.status === WSC.Status.RUNNING) {
      logWithTs(LOG_PREFIX, 'WSC:start() Already running');
      release();
      return;
    }

    this.socket.onAny(this.onAny);
    this.socket.on('connect', this.onConnect);
    this.socket.on("connect_error", this.onConnectError);
    this.socket.on('disconnect', this.onDisconnect);
    this.socket.on(WSSEvent[WSSEvent.PING], this.ping);
    this.socket.connect();

    this.status = WSC.Status.RUNNING;
    release();
  };

  public stop = (disconnect: boolean = true) => {
    if (this.isLockedStop) return;
    this.isLockedStop = true;

    const release = () => {
      this.isLockedStop = false;
    };

    if (this.status === WSC.Status.STOPPED) {
      logWithTs(LOG_PREFIX, 'WSC:stop() Already stopped');
      release();
      return;
    }

    if (disconnect) {
      this.socket.disconnect();
    }

    // Release listener
    this.socket.offAny(this.onAny);
    this.socket.off('connect', this.onConnect);
    this.socket.off("connect_error", this.onConnectError);
    this.socket.off('disconnect', this.onDisconnect);

    this.status = WSC.Status.STOPPED;
    release();
  };

  onAny = (...args: any) => {
    logWithTs(LOG_PREFIX, ...args);
  };

  onConnect = () => {
    logWithTs(LOG_PREFIX, 'connected', this.socket.id);
  };

  onDisconnect = () => {
    logWithTs(LOG_PREFIX, 'disconnected');
  };

  onConnectError = (err: Error) => {
    logWithTs(LOG_PREFIX, 'onConnectError', err);
  };

  ping = () => {
    this.socket.emit(WSCEvent[WSCEvent.PONG]);
  };
}

export namespace WSC {
  export enum Status {
    STOPPED,
    RUNNING,
  }
}
