import React, { useEffect, useState } from 'react';
import '../../css/event/smsModal.css';
import SmsTempSaveComponent from './sms/SmsTempSaveComponent';
import SmsListComponent from './sms/SmsListComponent';
import SmsSendComponent from './sms/SmsSendComponent';
import { ReactComponent as CLOSE } from '../../icons/close.svg';
import { TabList, TabPanel, TabContext } from '@mui/lab';
import { Box, Dialog, Tab } from '@mui/material';
import APIUtils from '../../utils/APIUtils';
import Grid from '@mui/material/Grid';

const apiUtils = new APIUtils();

const SMSModal = ({ smsOpen, setSmsOpen, setSmsCompleteOpen, setSmsDeleteOpen, smsDeleteOpen, rowInfo }) => {
  const [totalCtn, setTotalCtn] = useState(0);
  const [tabValue, setTabValue] = useState('1');

  const handleClose = () => {
    setSmsOpen(false);
    setTabValue('1');
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const fetchTempMessageCnt = () => {
    apiUtils
      .fetchApi('/v1/message-service', 'get', {
        temp: 1,
      })
      .then((res) => {
        setTotalCtn(res.count);
      });
  };

  useEffect(() => {
    fetchTempMessageCnt();
  }, []);

  return (
    <Dialog open={smsOpen} onClose={handleClose} maxWidth={'760'} disableEnforceFocus>
      <TabContext value={tabValue}>
        <Grid container flexDirection={'column'}>
          <Grid item>
            <Box className='title-container' sx={{ px: '30px', py: '18px', width: '760px', marginBottom: 0 }}>
              <div className='left'>
                <h2 className='modal-sms-title'>SMS 관리</h2>
              </div>
              <div className='right'>
                <TabList onChange={handleTabChange}>
                  <Tab label='SMS 전송' value='1' />
                  <Tab label='전송 목록' value='2' />
                  <Tab label={'임시보관함'} value='3' />
                </TabList>
                <CLOSE onClick={handleClose} style={{ cursor: 'pointer', marginLeft: 30 }} />
              </div>
            </Box>
          </Grid>
          <Grid item>
            <Box sx={{ backgroundColor: '#F8F8F9' }}>
              <TabPanel value='1' className='sms-row-container'>
                <SmsSendComponent rowInfo={rowInfo} setSmsOpen={setSmsOpen} setSmsCompleteOpen={setSmsCompleteOpen} setTabValue={setTabValue} fetchTempMessageCnt={fetchTempMessageCnt} />
              </TabPanel>
              <TabPanel value='2'>
                <SmsListComponent smsDeleteOpen={smsDeleteOpen} setSmsDeleteOpen={setSmsDeleteOpen} />
              </TabPanel>
              <TabPanel value='3'>
                <SmsTempSaveComponent smsDeleteOpen={smsDeleteOpen} setSmsDeleteOpen={setSmsDeleteOpen} setTabValue={setTabValue} fetchTempMessageCnt={fetchTempMessageCnt} />
              </TabPanel>
            </Box>
          </Grid>
        </Grid>
      </TabContext>
    </Dialog>
  );
};

export default SMSModal;
