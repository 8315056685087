import { useEffect, useState } from 'react';
import '../css/event.css';
import EventDetailModal from '../components/event/EventDetailModal';
import { WSSEvent } from 'anuro-platform-core';
import { ReactComponent as SMS_SEND } from '../icons/sms_blue.svg';
import ConfirmDialogModal from '../components/ConfirmDialogModal';
import EventEditModal from '../components/event/EventEditModal';
import AlertMessage from '../components/utilities/AlertMessage';
import { eventStatusOptions } from '../constants/SelectOptions';
import CommonHeader from '../components/HeaderComponent';
import APIUtils, { fetchApi } from '../utils/APIUtils';
import SMSModal from '../components/event/SMSModal';
import 'react-datepicker/dist/react-datepicker.css';
import Container from '../components/Container';
import SelectBox from '../components/SelectBox';
import useSelect from '../hooks/useSelect';
import DatePicker from 'react-datepicker';
import useInput from '../hooks/useInput';
import Table from '../components/Table/Table';
import Input from '../components/Input';
import { WSC } from '../service/WSC';
import moment from 'moment';
import DeleteBtn from '../components/Table/DeleteBtn/DeleteBtn';
import { EventColumns } from '../components/Table/TableColumns';
import EventEmitter from '../utils/EventEmitter';
import RightBtn from '../components/Table/RightBtn/RightBtn';

const apiUtils = new APIUtils();
const PAGE_LIMIT = 6;

const Event = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [curPage, setCurPage] = useState(1);

  const [detailOpen, setDetailOpen] = useState(false);
  const [smsOpen, setSmsOpen] = useState(false);
  const [smsCompleteOpen, setSmsCompleteOpen] = useState(false);
  const [smsDeleteOpen, setSmsDeleteOpen] = useState(false);
  const [eventQuiteOpen, setEventQuiteOpen] = useState(false);
  const [eventEditOpen, setEventEditOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedRowData, setSelectedRowData] = useState();

  // !!! Filtering
  const statusSelection = useSelect(undefined);
  const typeSelection = useSelect([]);
  const searchInput = useInput('');

  // !!! table handle
  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [checkedRows, setCheckedRows] = useState([]);

  const fetchedData = rows.find((row) => row.uuid === selectedRowData?.uuid);

  const emitter = EventEmitter.getInstance();

  // !!! 맵이 렌더링 되고 처음 시작
  useEffect(() => {
    fetchEventList();
    setCurPage(1);
    WSC.getInstance().socket.on(WSSEvent[WSSEvent.EVENT_ADD], onEventAdd);
    return () => {
      WSC.getInstance().socket.off(WSSEvent[WSSEvent.EVENT_ADD], onEventAdd);
    };
  }, [statusSelection.value, typeSelection.value, startDate, endDate, searchInput.value]);

  const onEventAdd = (event) => {
    AlertMessage.eventResponseMessage(event);
    setRows((pre) => {
      return [...pre, event].sort((a, b) => b.id - a.id);
    });
  };

  const handleRowClick = (params) => {
    setDetailOpen(true);
    setSelectedRowData(params.row);
  };

  // !!! 이벤트 목록 호출 함수
  const fetchEventList = (e = null, p = 1) => {
    setCurPage(p);
    apiUtils
      .fetchApi('/v1/me/organization/event', 'get', {
        limit: PAGE_LIMIT,
        offset: p ? (p - 1) * PAGE_LIMIT : 0,
        ob: 'id',
        od: 'DESC',
        status: statusSelection.value ? [statusSelection.value] : statusSelection.value,
        startTs: moment(startDate)?.startOf('day')?.unix() || undefined,
        endTs: moment(endDate)?.endOf('day')?.unix() || undefined,
        organizationServiceId: typeSelection.value,
        q: searchInput.value,
      })
      .then((r) => {
        setRows(r.rows);
        setTotalCount(r.count);
      });
  };

  const handleDeleteBtn = async () => {
    setIsLoading(true);
    if (checkedRows.length < 1) return AlertMessage.alertMessage('error', '삭제하실 이벤트를 선택해주세요');
    const result = await AlertMessage.confirmMessage(`삭제한 후에는 되돌릴 수 없습니다. \n 선택한 이벤트를 정말 삭제하시겠습니까?`);
    if (!result) return;

    checkedRows.map((e, idx) => {
      return fetchApi(`/v1/me/organization/event/${e.organizationEventCategory.organizationServiceId}/${e.uuid}`, `delete`)
        .then(() => {
          if (checkedRows.length - 1 === idx) {
            emitter.emit('removeMarker', { markerType: 'event' });
            setTimeout(() => {
              AlertMessage.alertMessage('success', '삭제가 성공적으로 완료되었습니다');
              fetchEventList();
            }, 1000);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  };

  const handleSmsSendBtn = () => {
    if (checkedRows.length > 1) return AlertMessage.alertMessage('error', '두개 이상의 이벤트는 선택하실 수 없습니다');
    setSmsOpen(true);
  };

  const eventTypeOptions = () => {
    const typesArr = [];
    fetchApi(`/v1/me/organization/service`, `get`).then((res) => {
      res.map((e) => {
        if (e.code !== 'SD332') {
          return typesArr.push({
            value: e?.organizationService.id,
            label: e?.name,
          });
        }
      });
    });
    return typesArr;
  };

  return (
    <Container>
      <CommonHeader pageTitle={''} />
      <div className='event-container'>
        <div className='content'>
          <div className='right'>
            <h3 className='header'>이벤트 내역</h3>
            <div className='search-title'>이벤트 조회</div>
            <div className='function-container'>
              <div className={'left-side'}>
                <div className='date-container'>
                  <div>
                    <DatePicker
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      placeholderText='시작일'
                      isClearable
                      className='date-picker'
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                    />
                  </div>
                  <div className='wave'>~</div>
                  <div>
                    <DatePicker
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      placeholderText='종료일'
                      isClearable
                      className='date-picker'
                      selected={endDate}
                      onChange={(date) => {
                        setEndDate(date);
                      }}
                      selectsEnd
                      endDate={endDate}
                      minDate={startDate}
                    />
                  </div>
                </div>
                <div className='search-container'>
                  <SelectBox width={200} isClearable onChange={typeSelection.onChange} variant={'primary'} options={eventTypeOptions()} placeholder={'이벤트 종류'}></SelectBox>
                  <SelectBox isClearable onChange={statusSelection.onChange} variant={'primary'} options={eventStatusOptions} placeholder={'상태'}></SelectBox>
                  <Input
                    value={searchInput.value}
                    onChange={(e) => {
                      if (e.target.value.length > 100) {
                        return AlertMessage.alertMessage('error', '최대 100자까지 입력할 수 있습니다.');
                      }
                      searchInput.onChange(e);
                    }}
                    type={'text'}
                    variant={'primary'}
                    width={300}
                    placeholder={'이벤트 코드 검색'}
                  />
                </div>
              </div>
              <RightBtn onClick={handleSmsSendBtn} className={''}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <SMS_SEND style={{ marginRight: 6 }} />
                  <span style={{ fontWeight: 'bold', fontSize: 14 }}>SMS 전송</span>
                </div>
              </RightBtn>
            </div>
            <div style={{ width: '100%', height: '75%', position: 'relative' }}>
              <Table
                columns={EventColumns}
                rows={rows}
                checkbox
                onRowClick={handleRowClick}
                leftBtn={DeleteBtn(handleDeleteBtn)}
                pageSize={PAGE_LIMIT}
                rowCount={totalCount}
                curPage={curPage}
                onCustomPaginationChange={fetchEventList}
                disableSelectionOnClick
                onCellKeyDown={(params, event) => {
                  if (event.code === 'Enter') {
                    handleRowClick(params);
                  }
                }}
                componentsProps={{
                  row: {
                    style: {
                      background: 'linear-gradient(90.12deg, #313644 0.02%, #1E2530 79.25%)',
                      fontWeight: 'bold',
                      borderRadius: 10,
                      marginBottom: 10,
                    },
                  },
                }}
                onSelectionModelChange={(ids) => {
                  let checkedData = [];
                  rows.map((i) => {
                    if (ids.includes(i.id)) return checkedData.push(i);
                  });
                  setCheckedRows(checkedData);
                }}
              />
              {apiUtils.renderLoading()}
            </div>
          </div>
        </div>
      </div>

      {/* 조회 - R */}
      {detailOpen && <EventDetailModal detailOpen={detailOpen} isDetailOpen={setDetailOpen} rowInfo={fetchedData} setEventQuiteOpen={setEventQuiteOpen} setEditOpen={setEventEditOpen} setDeleteData={setCheckedRows} fetchData={fetchEventList} />}
      {/* 수정 - U */}
      {eventEditOpen && <EventEditModal open={eventEditOpen} setOpen={setEventEditOpen} rowInfo={fetchedData} fetchData={fetchEventList} />}
      {/* 이벤트 종료 */}
      {eventQuiteOpen && <ConfirmDialogModal confirmDialogOpen={eventQuiteOpen} isConfirmDialogOpen={setEventQuiteOpen} setPrevModal={setDetailOpen} title={'이벤트 상황종료'} detail={'현재 처리중인 이벤트를 종료하시겠습니까?'} />}
      {/* SMS */}
      {smsOpen && <SMSModal smsOpen={smsOpen} setSmsOpen={setSmsOpen} setSmsCompleteOpen={setSmsCompleteOpen} smsDeleteOpen={smsDeleteOpen} setSmsDeleteOpen={setSmsDeleteOpen} rowInfo={checkedRows} />}
    </Container>
  );
};

export default Event;
