import $ from "jquery";
import EventEmitter from "./EventEmitter";

const MODE = {
	distance: "distance",
	radius: "radius",
	area: "area",
	clear: "clear"
}

const toolProperty = {
	distance: {
		strokeColor: '#f00',
	},
	area: {
		strokeColor: '#00f',
	},
	radius: {
		strokeColor: '#34a113',
	}
}

export default class NaverMapUtils {

	emitter = EventEmitter.getInstance();
	constructor(buttons) {
		this.$btnDistance = buttons.distance;
		this.$btnArea = buttons.area;
		this.$btnClear = buttons.clear;
		this.$btnRadius = buttons.radius;
		this._mode = null;
		this._bindDOMEvents();
		this.t = buttons.this;
	}

	setMap = function(map) {
		this.map = map;
	};

	startMode = function(mode) {
		if (!mode) return;
		if (mode === MODE.clear) {
			this._startClear();
		} else {
			this._start(mode);
		}
	};

	_startClear = function() {
		this.t.state.polyDraw.map(i => {
			i?.setMap(null);
		})
		this.t.state.polyText.map(i => {
			i?.setMap(null);
		})
		this.t.setState({
			polyDraw: [],
			polyText: []
		});

		this.emitter.emit('removeMarker', { markerType: 'eventCircle' });
	};

	_start = function(mode) {
		let map = this.map;
		this._distanceListeners = [
			window.naver.maps.Event.addListener(map, 'click', this._onClick.bind(this)),
			window.naver.maps.Event.addListener(map, 'rightclick', this._finish.bind(this))
		];

		if(mode === MODE.distance) {
			$(document).on('mousemove.measure', this._onMouseMoveDistance.bind(this));
		} else if (mode === MODE.area) {
			$(document).on('mousemove.measure', this._onMouseMoveArea.bind(this));
		} else if (mode === MODE.radius) {
			$(document).on('mousemove.measure', this._onMouseMoveRadius.bind(this));
		}
	};

	_fromMetersToText = function(meters) {
		meters = meters || 0;

		let km = 1000,
				text = meters;

		if (meters >= km) {
			text = parseFloat((meters / km).toFixed(1)) +'km';
		} else {
			text = parseFloat(meters.toFixed(1)) +'m';
		}

		return text;
	};

	_fromSquareMetersToText = function(squarMeters) {
		squarMeters = squarMeters || 0;

		let squarKm = 1000 * 1000,
				text = squarMeters;

		if (squarMeters >= squarKm) {
			text = parseFloat((squarMeters / squarKm).toFixed(1)) + 'km<sup>2</sup>';
		} else {
			text = parseFloat(squarMeters.toFixed(1)) + 'm<sup>2</sup>';
		}

		return text;
	};

	_addMileStone = function(coord, text, css) {
		if (!this._ms) this._ms = [];

		let ms = new window.naver.maps.Marker({
			position: coord,
			icon: {
				content: '<div style="display:inline-block;padding:5px;text-align:center;background-color:#fff;border:1px solid #000;"><span>'+ text +'</span></div>',
				anchor: new window.naver.maps.Point(-5, -5)
			},
			map: this.map
		});

		let msElement = $(ms.getElement());

		if (css) {
			msElement.css(css);
		} else {
			msElement.css('font-size', '11px');
		}
		this._ms.push(ms);
		this.t.setState({
			polyText: this.t.state?.polyText.length > 0 ? [...this.t.state.polyText, ms] : [ms]
		});
	};

	_onClick = function(e) {
		let map = this.map,
				coord = e.coord;

		// 면적을 구할 때
		if(this._mode === MODE.area) {
			if (!this._polygon) {
				this._polygon = new window.naver.maps.Polygon({
					strokeColor: '#00f',
					strokeOpacity: 0.6,
					strokeWeight: 5,
					fillColor: '#00f',
					fillOpacity: 0.3,
					paths: [coord],
					map: this.map
				});
			} else {
				this._polygon.getPath().push(coord);
			}
		} else {
			// 거리나 반경을 구할 때
			if (!this._polyline) {
				console.log(this._mode);
				// 임시로 보여줄 점선 폴리라인을 생성합니다.
				this._guideline = new window.naver.maps.Polyline({
					strokeColor: toolProperty[this._mode].strokeColor,
					strokeWeight: 5,
					strokeStyle: [4, 4],
					strokeOpacity: 0.6,
					path: [coord],
					map: map
				});

				//실제 거리재기에 사용되는 폴리라인을 생성합니다.
				this._polyline = new window.naver.maps.Polyline({
					strokeColor: toolProperty[this._mode].strokeColor,
					strokeWeight: 5,
					strokeOpacity: 0.8,
					path: [coord],
					map: map
				});

				if(this._mode === MODE.radius) {
					this._radius = new window.naver.maps.Circle({
						map: map,
						center: coord,
						radius: 3,
						strokeColor: toolProperty[this._mode].strokeColor,
						strokeOpacity: 0.6,
						strokeWeight: 5,
						fillColor: toolProperty[this._mode].strokeColor,
						fillOpacity: 0.3,
					});
				}
				// 폴리라인의 거리를 미터 단위로 반환합니다.
				this._lastDistance = this._polyline.getDistance();
			} else {
				this._guideline.setPath([e.coord]);
				this._polyline.getPath().push(coord);

				// 폴리라인의 거리를 미터 단위로 반환합니다.
				let distance = this._polyline.getDistance();

				this._addMileStone(coord, this._fromMetersToText(distance - this._lastDistance));
				this._lastDistance = distance;

				if(this._mode === MODE.radius) {
					EventEmitter.getInstance().emit("toolHandler", {key: "mapTool", option: "radius"});
					this._finish();
				}
			}
		}
	};

	_onMouseMoveDistance = function(e) {
		if (!this._guideline) return;
		let proj = this.map.getProjection(), coord = proj.fromPageXYToCoord(new window.naver.maps.Point(e.pageX, e.pageY));
		this.path = this._guideline.getPath();

		if (this.path.getLength() === 2) {
			this.path.pop();
		}

		this.path.push(coord);
	};

	_onMouseMoveArea = function(e) {
		if (!this._polygon) return;

		let map = this.map,
				proj = this.map.getProjection(),
				coord = proj.fromPageXYToCoord(new window.naver.maps.Point(e.pageX, e.pageY)),
				path = this._polygon.getPath();

		if (path.getLength() >= 2) {
			path.pop();
		}

		path.push(coord);
	};

	_onMouseMoveRadius = function(e) {
		if(!this._guideline) return;
		let proj = this.map.getProjection(), coord = proj.fromPageXYToCoord(new window.naver.maps.Point(e.pageX, e.pageY));
		this.path = this._guideline.getPath();

		if (this.path.getLength() === 2) {
			this.path.pop();
		}

		this.path.push(coord);
		this._radius.setRadius(this._guideline.getDistance());
	};

	_bindDOMEvents = function() {
		this.$btnDistance.on('click.measure', this._onClickButton.bind(this, 'distance'));
		this.$btnArea.on('click.measure', this._onClickButton.bind(this, 'area'));
		this.$btnClear.on('click.measure', this._onClickButton.bind(this, 'clear'));
		this.$btnRadius.on('click.measure', this._onClickButton.bind(this, 'radius'));
	};

	_onClickButton = function(newMode, e) {
		e.preventDefault();

		let btn = $(e.target),
				mode = this._mode;

		this._finish();

		if (mode === newMode) {
			this._mode = null;
			return;
		}

		this._mode = newMode;
		this.startMode(newMode);
	};

	_finish = function() {
		this.t.setState({
			polyDraw: this.t.state?.polyDraw.length > 0 ? [...this.t.state.polyDraw, this._polyline, this._radius, this._polygon] : [this._polyline, this._radius, this._polygon]
		});

		window.naver.maps.Event.removeListener(this._distanceListeners);
		delete this._distanceListeners;

		$(document).off('mousemove.measure');

		if (this._guideline && this._mode !== MODE.area) {
			this._guideline.setMap(null);
			delete this._guideline;
		}

		if (this._polyline || this._polygon) {
			let path = this._mode === MODE.area ? this._polygon.getPath() : this._polyline.getPath();
			if(this._mode === MODE.area) path.pop();

			let lastCoord = path.getAt(path.getLength() - 1);
			let distance = this._mode === MODE.area ? this._polygon.getAreaSize() : this._polyline.getDistance();

			this._mode === MODE.area ? delete this._polygon : delete this._polyline;

			if (lastCoord) {
				this._addMileStone(lastCoord, this._fromMetersToText(distance), {
					'font-size': '14px',
					'font-weight': 'bold',
					'color': '#f00'
				});
			}
		}

		this.map.setCursor('auto');
		if(this._mode !== MODE.area) delete this._lastDistance;
		this._mode = null;
	};
}
