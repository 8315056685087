import { useEffect, useState } from 'react';
import '../../../css/event/sms/smsListDetailModal.css';
import { Box, Modal } from '@mui/material';
import ModalStyle from '../../ModalStyle';
import Divider from '../../Divider';
import APIUtils from '../../../utils/APIUtils';
import moment from 'moment';

const apiUtils = new APIUtils();

const SmsListDetailModal = ({ rowInfo, smsListDetailOpen, setSmsListDetailOpen }) => {
  const [eventInfo, setEventInfo] = useState({});
  const handleClose = () => {
    setSmsListDetailOpen(false);
    setEventInfo({});
  };

  useEffect(() => {
    const fetchEventInfo = () => {
      apiUtils
        .fetchApi(`/v1/me/organization/event/${rowInfo?.eventUuid}`, 'get')
        .then((res) => {
          setEventInfo(res);
        })
        .catch((err) => console.log('[Line:24 err]', err));
    };

    rowInfo?.eventUuid && fetchEventInfo();
  }, [rowInfo?.eventUuid, smsListDetailOpen]);

  return (
    <Modal open={smsListDetailOpen} onClose={handleClose} className='sms-modal-container'>
      <Box sx={ModalStyle(1000, 550, 5)}>
        <div className='sms-send-detail-container'>
          <div className='header'>상세내용</div>
          <Divider marginTop={15} marginBottom={20} color={'#999999'} />
          <div className='each-row'>
            <div className='title'>이벤트</div>
            {eventInfo !== {} && eventInfo?.organizationEventCategory?.organizationService?.service?.name ? (
              <div className='detail'>
                <div className='main'>
                  {eventInfo?.organizationEventCategory?.organizationService?.service?.name} ({eventInfo?.organizationEventCategory?.eventCategory?.name})
                </div>
                <div className='sub'>
                  발생위치: {eventInfo?.addressStreet} {eventInfo?.addressStreetDetail}
                </div>
                <div className='sub wrapper'>
                  <div className='left'>이벤트 아이디: {eventInfo?.code}</div>
                  <div>발생일시: {eventInfo?.issuedAt ? moment(eventInfo?.issuedAt).format('YY년 MM월 DD일 HH시 mm분 ss초') : ''}</div>
                </div>
              </div>
            ) : (
              '미지정'
            )}
          </div>
          <div className='each-row'>
            <div className='title'>수신자</div>
            <div>{rowInfo?.to}</div>
          </div>
          <div className='each-row'>
            <div className='title'>내용</div>
            <div className='content'>{rowInfo?.message}</div>
          </div>
          <div className='btn-container'>
            <button className='btn-close btn' onClick={handleClose}>
              닫기
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default SmsListDetailModal;
