import React, { useEffect, useState } from 'react';
import '../../css/event/eventEditModal.css';
import { Controller, useForm } from 'react-hook-form';
import AlertMessage from '../utilities/AlertMessage';
import EventEmitter from '../../utils/EventEmitter';
import { fetchApi } from '../../utils/APIUtils';
import AddressInput from '../AddressInput';
import SelectBox from '../SelectBox';
import BaseModal from '../BaseModal';
import Divider from '../Divider';
import Input from '../Input';
import moment from 'moment';

const EventEditModal = ({ open, setOpen, rowInfo, fetchData }) => {
  const emitter = EventEmitter.getInstance();
  const CURRENT_TIME = moment().format('YY년 MM월 DD일 HH시 mm분 ss초');
  const { register, setValue, handleSubmit, reset, control, watch } = useForm();
  const [address, setAddress] = useState();

  const watchType = watch('eventService');
  const serviceId = watchType?.id;

  const eventDefault = rowInfo?.organizationEventCategory
    ? {
        label: rowInfo?.organizationEventCategory.eventCategory.service.name,
        value: rowInfo?.organizationEventCategory?.organizationServiceId,
      }
    : null;

  const categoryDefault = rowInfo?.organizationEventCategory
    ? {
        label: rowInfo.organizationEventCategory.eventCategory.name,
        value: rowInfo.organizationEventCategory.id,
      }
    : null;

  const chargedPersonDefault = rowInfo?.chargeUser
    ? {
        label: rowInfo?.chargeUser.username,
        value: rowInfo?.chargeUser.uuid,
      }
    : null;

  useEffect(() => {
    reset({
      address: rowInfo?.addressStreet,
      longitude: rowInfo?.longitude,
      latitude: rowInfo?.latitude,
      eventService: eventDefault,
      category: categoryDefault,
      chargeUserUuid: chargedPersonDefault,
      username: rowInfo?.chargeUser?.username,
      message: rowInfo?.message,
      addressStreetDetail: rowInfo?.addressStreetDetail,
      title: rowInfo?.title,
    });
  }, [rowInfo]);

  if (!rowInfo) return null;

  const onSubmit = (data) => {
    if (!data.title) return AlertMessage.alertMessage('error', '이벤트 제목을 입력해주세요.');
    if (data.title?.length > 100) return AlertMessage.alertMessage('error', '이벤트 제목을 100자 이내로 입력해주세요.');
    if (!data.address && !address?.roadAddr) return AlertMessage.alertMessage('error', '주소를 검색해주세요.');
    if (data.addressStreet?.length > 100) return AlertMessage.alertMessage('error', '주소를 100자 이내로 입력해주세요.');
    if (data.addressStreetDetail?.length > 50) return AlertMessage.alertMessage('error', '상세주소를 50자 이내로 입력해주세요.');
    if (data.message?.length > 1000) return AlertMessage.alertMessage('error', '상황내용을 1000자 이내로 입력해주세요');

    fetchApi(`/v1/me/organization/event/${eventDefault.value || data.eventService.id}/${rowInfo.uuid}`, `patch`, {
      organizationEventCategoryId: data?.category?.value,
      addressStreet: address?.roadAddr || data.address,
      latitude: address?.latitude || data.latitude,
      longitude: address?.longitude || data.longitude,
      chargeUserUuid: data?.chargeUserUuid?.value,
      message: data?.message,
      addressStreetDetail: data?.addressStreetDetail,
      title: data?.title,
    })
      .then((r) => {
        AlertMessage.alertMessage('success', '이벤트 수정이 완료되었습니다');
        fetchData();
        setOpen(false);
        emitter.emit('removeMarker', { markerType: 'search' });
      })
      .catch((err) => console.log('[Line:32 err]', err));
  };
  const handleClose = () => {
    setOpen(false);
  };

  const EventOptions = () => {
    const eventsArr = [];
    fetchApi(`/v1/me/organization/service`, `get`).then((res) => {
      res.map((e) => {
        if (e.code !== 'SD332') {
          return eventsArr.push({
            value: e?.uuid,
            label: e?.name,
            id: e?.organizationService.id,
          });
        }
      });
    });

    return (
      <Controller
        name='eventService'
        control={control}
        render={({ field }) => {
          return <Input width={'100%'} label={'이벤트'} disabled defaultValue={eventDefault.label} />;
        }}
      />
    );
  };

  // const CategoryOptions = () => {
  //   const categoryArr = [];
  //   if (serviceId) {
  //     fetchApi(`/v1/me/organization/service/${serviceId}/event-category`, `get`).then((res) => {
  //       res.map((e) => {
  //         console.log('[Line:121 e]', e);
  //         return categoryArr.push({
  //           value: e?.organizationEventCategory.id,
  //           label: e?.name,
  //         });
  //       });
  //     });
  //   }
  //   return (
  //     <Controller
  //       name='category'
  //       control={control}
  //       render={({ field }) => {
  //         return <SelectBox defaultValue={categoryDefault} containerWidth={'100%'} label={'유형'} name={'category'} width={'100%'} options={categoryArr} placeholder={'유형을 선택해주세요'} {...field} />;
  //       }}
  //     />
  //   );
  // };

  const CategoryOptions = () => {
    const [categoryArr, setCategoryArr] = React.useState([]); // Use React state to manage the category array

    React.useEffect(() => {
      if (rowInfo?.organizationEventCategory?.organizationServiceId) {
        fetchApi(`/v1/me/organization/service/${rowInfo?.organizationEventCategory?.organizationServiceId}/event-category`, 'get')
          .then((res) => {
            const categories = res.map((e) => ({
              value: e?.organizationEventCategory.id,
              label: e?.name,
            }));
            setCategoryArr(categories); // Update the categoryArr state with the fetched data
          })
          .catch((error) => {
            console.error('Error fetching categories:', error);
          });
      }
    }, []);

    return <Controller name='category' control={control} render={({ field }) => <SelectBox defaultValue={categoryDefault} containerWidth='100%' label='유형*' name='category' width='100%' options={categoryArr} placeholder='유형을 선택해주세요' {...field} />} />;
  };

  const UserListOptions = () => {
    const userListArr = [];
    fetchApi(`/v1/me/organization/user`, `get`).then((res) => {
      res.rows.map((e) => {
        return userListArr.push({
          value: e?.uuid,
          label: `${e?.profile?.name}(${e?.username})`,
        });
      });
    });
    return (
      <Controller
        name='chargeUserUuid'
        control={control}
        render={({ field }) => {
          return <SelectBox defaultValue={chargedPersonDefault} containerWidth={'100%'} label={'담당자'} name={'chargeUserUuid'} width={'100%'} options={userListArr} placeholder={'담당자를 선택해주세요'} {...field} />;
        }}
      />
    );
  };

  return (
    <BaseModal width={550} height={730} title={'이벤트 정보 수정'} open={open} rightBtnLabel={'수정'} closeIcon handleClose={handleClose} handleRightBtn={handleSubmit(onSubmit)}>
      <Divider marginBottom={20} color={'#999999'} />
      <div className={'event-edit-container'}>
        <Input width={'100%'} label={'일시'} {...register('date')} disabled defaultValue={CURRENT_TIME} />
        <Input
          width={'100%'}
          name={'title'}
          {...register('title')}
          label={'이벤트 제목*'}
          placeholder={'이벤트 제목을 입력해주세요'}
          onChange={(e) => {
            if (e.target.value.length > 100) {
              setValue('title', e.target.value.slice(0, 100));
              AlertMessage.alertMessage('error', '이벤트 제목을 100자 이내로 입력해주세요.');
            }
          }}
        />
        <EventOptions />
        <CategoryOptions />
        <div className='each-row'>
          <div className='title'>주소*</div>
          <AddressInput {...register('address')} defaultValue={rowInfo.addressStreet} label={'address'} className='detail address' value={address?.roadAddr || ''} width={'100%'} placeholder='위치를 입력해주세요' radius={5} height={40} padding={10} onChange={(e) => setAddress(e)} />
        </div>
        <Input
          width={'100%'}
          name={'addressStreetDetail'}
          {...register('addressStreetDetail')}
          label={'상세주소'}
          placeholder={'상세주소를 입력해주세요'}
          onChange={(e) => {
            if (e.target.value.length > 50) {
              setValue('addressStreetDetail', e.target.value.slice(0, 50));
              AlertMessage.alertMessage('error', '상세주소를 50자 이내로 입력해주세요.');
            }
          }}
        />
        <UserListOptions />
        <Input
          width={'calc(100% - 17px)'}
          textarea
          height={120}
          label={'상세내용'}
          placeholder='상세내용을 입력해주세요'
          {...register('message')}
          onChange={(e) => {
            if (e.target.value.length > 1000) {
              setValue('message', e.target.value.slice(0, 1000));
              AlertMessage.alertMessage('error', '상세내용을 1000자 이내로 입력해주세요');
            }
          }}
        />
      </div>
    </BaseModal>
  );
};

export default EventEditModal;
