import React, { useEffect, useState } from 'react';
import '../../css/event/eventDetailModal.css';
import { EVENT_DEFAULT_ICON, EVENT_PROGRESS_CODE, EVENT_STATUS } from '../../constants/Event';
import EventDetailListComponent from './EventDetailListComponent';
import { ReactComponent as CLOSE } from '../../icons/close.svg';
import { ReactComponent as MORE } from '../../icons/more.svg';
import CompleteAndCloseModal from '../CompleteAndCloseModal';
import { SERVICE, SocketParser } from 'anuro-platform-core';
import AlertMessage from '../utilities/AlertMessage';
import EventEmitter from '../../utils/EventEmitter';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom/';
import MenuItem from '@mui/material/MenuItem';
import APIUtils, { fetchApi } from '../../utils/APIUtils';
import { Box, IconButton, Modal, Tooltip } from '@mui/material';
import { useForm } from 'react-hook-form';
import ModalStyle from '../ModalStyle';
import Menu from '@mui/material/Menu';
import RawModal from './RawModal';
import { ReactComponent as FULLTEXT } from '../../icons/full_text.svg';
import DetailList from '../eventManage/modals/eventDetailModal/DetailList';
import Button from '../Button';
import Input from '../Input';
import { IconBase } from 'react-icons';

const emitter = EventEmitter.getInstance();
const apiUtils = new APIUtils();

const EventDetailModal = ({ detailOpen, isDetailOpen, setEditOpen, rowInfo, fetchData }) => {
  const location = useLocation();

  const [closeOpen, setCloseOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [rawModalOpen, setRawModalOpen] = useState(false);

  const { register, handleSubmit, reset, setValue } = useForm();
  const history = useHistory();

  const [commentListArr, setCommentListArr] = useState([]);

  useEffect(async () => {
    setTimeout(() => {
      document.getElementsByClassName('MuiBox-root')?.[0]?.focus?.();
    }, 300);
    fetchCommentList();

    const a = await isTerminatedEvent();
    console.log(a);
  }, []);

  const fetchCommentList = () => {
    apiUtils
      .fetchApi(`/v1/me/organization/event/${rowInfo?.uuid}/comment?offset=0&limit=100&ob=id&od=DESC`, 'get')
      .then((res) => {
        setCommentListArr(res.rows);
      })
      .then(() => {
        // apiUtils.fetchApi(`/v1/me/organization/event/${rowInfo?.uuid}/history?offset=0&limit=100&ob=id&od=DESC`, 'get').then((res) => {
        //   if (res.rows.length === 0) return null;
        //   setCommentListArr((prevList) => [
        //     ...prevList.map((comment) => ({
        //       ...comment,
        //       ts: comment.createdAt,
        //     })),
        //     ...res?.rows.map((history) => ({ ...history, ts: history.createdAt })),
        //   ]);
        // });
      })
      .catch((err) => console.log('[Line:186 err]', err));
  };

  if (!rowInfo) return null;

  const parsingData = rowInfo.raw ? SocketParser.parse(SERVICE[rowInfo?.organizationEventCategory?.eventCategory?.service?.code], rowInfo.raw) : '';
  const progressCode = rowInfo.raw ? parsingData.header.PROGRESS_CODE : '';

  const isEventOccur = rowInfo.status === EVENT_STATUS.STANDBY.code;
  const terminated = rowInfo.raw && (progressCode === EVENT_PROGRESS_CODE.RELEASE.code || progressCode === EVENT_PROGRESS_CODE.TERMINATED.code) ? true : rowInfo.status === EVENT_STATUS.TERMINATED.code ? true : false;

  const onSubmit = (data) => {
    if (!data.record) return AlertMessage.alertMessage('error', '이벤트 기록 작성 내용을 입력해주세요.');
    if (data.record.length > 1000) return AlertMessage.alertMessage('error', '이벤트 기록 작성 내용을 1000자 이내로 입력해주세요.');
    apiUtils
      .fetchApi(`/v1/me/organization/event/${rowInfo.uuid}/comment/${rowInfo?.organizationEventCategory.organizationService.id}`, 'post', {
        message: data.record,
      })
      .then(() => {
        fetchCommentList();
        AlertMessage.alertMessage('success', '처리내역이 전송되었습니다');
        reset();
      });
  };

  const open = Boolean(anchorEl);

  const handleClose = () => {
    isDetailOpen(false);
    reset();
  };

  const handleMoreFunctionBtn = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const isTerminatedEvent = async () => {
    const eventData = await apiUtils.fetchApi(`/v1/me/organization/event/${rowInfo.uuid}`, 'get');
    const parsingData = eventData.raw ? SocketParser.parse(SERVICE[eventData?.organizationEventCategory?.organizationService?.service?.code], eventData.raw) : '';
    const progressCode = eventData.raw ? parsingData.header.PROGRESS_CODE : '';

    return progressCode === EVENT_PROGRESS_CODE.TERMINATED.code || eventData.status === EVENT_STATUS.TERMINATED.code;
  };

  const handleDeleteBtn = async () => {
    const result = await AlertMessage.confirmMessage(`삭제한 후에는 되돌릴 수 없습니다. \n 선택한 이벤트를 정말 삭제하시겠습니까?`);
    if (!result) return;

    fetchApi(`/v1/me/organization/event/${rowInfo.organizationEventCategory.organizationServiceId}/${rowInfo.uuid}`, `delete`).then(() => {
      setAnchorEl(null);
      AlertMessage.alertMessage('success', '삭제가 성공적으로 완료되었습니다');
      fetchData();
    });
  };

  const handleEditBtn = () => {
    emitter.emit('removeMarker', { eventType: 'search' });
    setAnchorEl(null);
    setEditOpen(true);
  };

  // !!! 이벤트 상태 변경
  const patchEventStatus = async (status) => {
    if (location.pathname === '/event' && status === EVENT_STATUS.IN_PROGRESS.code) {
      return history.push('/gis');
    }

    const result = await AlertMessage.confirmMessage(`이벤트 상태를 ${status === 'IN_PROGRESS' ? "'처리중'으로" : "'상황종료'로"} 변경하시겠습니까?`);
    if (!result) return;

    const isTerminated = await isTerminatedEvent();
    if (isTerminated) return AlertMessage.alertMessage('error', '이미 종료된 이벤트입니다.');

    apiUtils.fetchApi(`/v1/me/organization/event/${rowInfo?.organizationEventCategory.organizationService.id}/${rowInfo.uuid}`, 'patch', { status }).then((r) => {
      emitter.emit('fetchEventList');
      emitter.emit('layer_fetchServiceList');
      fetchData();
      fetchCommentList();
      setTimeout(() => {
        emitter.emit('moveTo', { lat: rowInfo?.latitude, lng: rowInfo?.longitude });
      }, 1000);
      AlertMessage.alertMessage('success', `이벤트 상태를 ${status === 'IN_PROGRESS' ? "'처리중'으로" : "'상황종료'로"} 변경했습니다`);
    });
  };

  const MainEventInfo = () => {
    const eventOccurStyle = {
      backgroundColor: '#FFE6E6',
      border: '1px solid #FF0000',
    };
    const eventEndStyle = {
      backgroundColor: '#1B2649',
    };

    return (
      <div style={terminated ? eventEndStyle : eventOccurStyle} className={`top`}>
        <div className={'left'}>
          <img className={'icon'} src={rowInfo.organizationEventCategory?.organizationService?.iconUrl || EVENT_DEFAULT_ICON[rowInfo?.organizationEventCategory?.eventCategory?.service?.code] || ''} alt={'긴급출동119'} style={{ width: 60, height: 60 }} />
          <div className={'title'}>
            <div
              style={{
                fontSize: 14,
                fontWeight: 'bold',
                color: terminated ? 'white' : '#FF0000',
                marginBottom: 4,
              }}>
              {rowInfo.raw ? (progressCode === EVENT_PROGRESS_CODE.RELEASE.code ? '상황해제' : progressCode === EVENT_PROGRESS_CODE.TERMINATED.code ? '상황종료' : EVENT_STATUS[rowInfo.status].name) : terminated ? '상황종료' : EVENT_STATUS[rowInfo.status].name}
            </div>
            <div
              style={{
                fontSize: 26,
                fontWeight: 'bold',
                color: terminated ? 'white' : '#FF0000',
                marginBottom: 8,
              }}>
              {rowInfo?.organizationEventCategory?.organizationService?.name || ''} ({rowInfo?.organizationEventCategory?.eventCategory.name})
            </div>
            <div style={{ fontSize: 14, color: terminated ? 'white' : 'black' }}>이벤트 아이디 : {rowInfo?.code}</div>
          </div>
        </div>
        {!terminated && location.pathname.includes('gis') && (
          <div className={'right'}>
            <button className={'btn btn-handle'} style={rowInfo.status === 'IN_PROGRESS' ? { display: 'none' } : undefined} onClick={() => patchEventStatus('IN_PROGRESS')}>
              처리하기
            </button>
            <button className={'btn btn-quite'} onClick={() => patchEventStatus('TERMINATED')}>
              종료하기
            </button>
          </div>
        )}
      </div>
    );
  };

  const ProcessingLists = () => {
    let status = '';
    const test = commentListArr
      ?.sort((a, b) => {
        return new Date(a.ts) - new Date(b.ts);
      })
      .map((i) => {
        if ((i.status === status && status === 'STANDBY') || (status === 'MODIFY' && i.status !== 'TERMINATED' && i.status !== 'IN_PROGRESS')) {
          console.log(i.status);
          status = 'MODIFY';
        } else if (i.status) status = i.status;
        return {
          ...i,
          status: status,
        };
      });

    return (
      <div className={'right'}>
        <div style={{ fontWeight: 'bold', fontSize: 16, marginBottom: 16 }}>처리내역</div>
        <div className={'content-container'}>
          <div className={'line'}></div>
          <div style={{ overflowY: 'auto', padding: 2 }}>
            {test
              ?.sort((a, b) => {
                return new Date(b.ts) - new Date(a.ts);
              })
              .map((e) => {
                return <EventDetailListComponent key={e.id} commentList={e} />;
              })}
          </div>
        </div>
      </div>
    );
  };

  const RecordWritingArea = () => {
    return terminated ? null : (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={'input-container'}>
          <Input
            width={'100%'}
            placeholder={'이벤트 기록 작성'}
            {...register('record')}
            onChange={(e) => {
              if (e.target.value.length > 1000) {
                setValue('record', e.target.value.slice(0, 1000));
                AlertMessage.alertMessage('error', '이벤트 기록 작성 내용을 1000자 이내로 입력해주세요.');
              } else {
                setValue('record', e.target.value);
              }
            }}
          />
          <Button style={{ marginLeft: 14 }} height={20} variant={'primary'} type={'submit'}>
            작성하기
          </Button>
        </div>
      </form>
    );
  };

  return (
    <Modal open={detailOpen} onClose={handleClose} disableEnforceFocus>
      <Box sx={{ ...ModalStyle(960, terminated ? 570 : 660, 5), height: 610, outline: 'none' }}>
        <div className='event-detail-title-container'>
          <div className={'left'}>
            <h2>이벤트 상세정보</h2>
            {(rowInfo?.raw || rowInfo?.ES_112_MTMDA_RECEPT) && (
              <div
                className={'full-text'}
                onClick={() => {
                  setRawModalOpen(true);
                }}>
                <FULLTEXT />
                <p>전문보기</p>
              </div>
            )}
          </div>
          <div className='right'>
            {location.pathname.includes('event') && (
              <>
                <Tooltip title='더보기'>
                  <IconButton onClick={handleMoreFunctionBtn} style={{ marginRight: 14 }}>
                    <MORE />
                  </IconButton>
                </Tooltip>
                <Menu
                  disableEnforceFocus
                  id='basic-menu'
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleMenuClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}>
                  <MenuItem onClick={handleEditBtn}>수정하기</MenuItem>
                  <MenuItem onClick={handleDeleteBtn}>삭제하기</MenuItem>
                </Menu>
              </>
            )}
            <IconButton onClick={handleClose}>
              <CLOSE />
            </IconButton>
          </div>
        </div>
        <div className={'event-detail-content-container'}>
          <MainEventInfo />
          <div className={'bottom'}>
            <DetailList rowInfo={rowInfo} />
            <ProcessingLists />
          </div>
          {location.pathname.includes('gis') && <RecordWritingArea />}
        </div>
        <CompleteAndCloseModal title={'수정완료'} detail={'이벤트 등록이 정상적으로 처리되었습니다'} open={closeOpen} setOpen={setCloseOpen} />
        {rawModalOpen && <RawModal open={rawModalOpen} onClose={() => setRawModalOpen(false)} rowInfo={rowInfo} />}
        {apiUtils.renderLoading()}
      </Box>
    </Modal>
  );
};

export default EventDetailModal;
