export class StorageUtils {
  static setAuthorization(token: string, keepSession: boolean) {
    try {
      sessionStorage.removeItem("Authorization");
      localStorage.removeItem("Authorization");
    } catch (e) {
      console.warn(e);
    }

    (keepSession ? localStorage : sessionStorage).setItem(
      "Authorization",
      token
    );
  }

  static getAuthorization() {
    return (
      sessionStorage.getItem("Authorization") ||
      localStorage.getItem("Authorization")
    );
  }

  static removeAuthorization() {
    try {
      sessionStorage.removeItem("Authorization");
      localStorage.removeItem("Authorization");
    } catch (e) {
      console.warn(e);
    }
  }

  static setStorage(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  static getStorage(key: string) {
    const defaults = {
      language: "kr",
    };

    const defaultValue = defaults[key] || null;

    const itemValue = localStorage.getItem(key);
    if (itemValue == null && defaultValue != null) {
      localStorage.setItem(key, defaultValue);
      return defaultValue;
    }

    return itemValue;
  }
}
