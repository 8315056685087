import React, { forwardRef, HTMLAttributes, InputHTMLAttributes } from 'react';
import '../css/components.css';

interface Props extends InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  label?: string;
  variant?: string;
  extended?: boolean;
  textarea?: boolean;
  containerWidth?: string | number;
  errortype?: 'required' | 'maxLength' | 'minLength' | 'min' | 'max' | 'pattern' | 'char';
  error?: string | React.ReactNode;
  requirement?: {
    [key: string]: number;
  };
}

const Input = forwardRef(({ label, type, name, textarea, extended, error, ...props }: Props, ref: any) => {
  const primary = props.variant === 'primary';
  const setInputBackgroundColor = () => {
    if (primary) {
      return '#313644';
    } else if (props.disabled) {
      return '#e6e6e6';
    } else {
      return 'white';
    }
  };
  const setInputFontColor = () => {
    if (primary) {
      return 'white';
    } else if (props.disabled) {
      return '#333';
    } else {
      return 'black';
    }
  };
  const containerStyle: React.CSSProperties = {
    marginBottom: primary ? 0 : 18,
    width: '100%',
  };
  const contentsStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: textarea ? 'baseline' : 'center',
    height: textarea ? props.height : 40,
    width: props.containerWidth ? props.containerWidth : '100%',
    // marginBottom: primary ? null : 18,
  };
  const labelStyle: React.CSSProperties = {
    fontSize: 16,
    width: 120,
    textAlign: 'left',
    paddingLeft: extended ? 20 : undefined,
  };

  const inputStyle = {
    padding: 10,
    color: setInputFontColor(),
    border: primary ? undefined : '1px solid #DDDDDD',
    borderRadius: 5,
    width: props.width ? props.width : 'calc(100% - 110px)',
    height: props.height ? props.height : '100%',
    backgroundColor: setInputBackgroundColor(),
    ...props.style,
  };
  const textAreaStyle: React.CSSProperties = {
    padding: 10,
    border: '1px solid #DDDDDD',
    borderRadius: 5,
    width: props.width ? props.width : 'calc(100% - 110px)',
    height: '90%',
    resize: 'none',
    marginBottom: 30,
  };
  const errorStyle: React.CSSProperties = {
    color: 'red',
    marginTop: 5,
    fontSize: 14,
    textAlign: 'right',
  };

  return (
    <div style={containerStyle}>
      <div style={contentsStyle}>
        {label ? (
          <label htmlFor={name} style={labelStyle}>
            {label}
          </label>
        ) : null}
        {textarea ? (
          <textarea className={'textarea-component'} style={textAreaStyle} name={name} {...props} ref={ref}></textarea>
        ) : (
          <input
            className={'input-component'}
            style={{
              ...inputStyle,
            }}
            name={name}
            type={type ? type : 'text'}
            {...props}
            ref={ref}
          />
        )}
      </div>
      {/* 유효성 에러 핸들링 */}
      {props.errortype === 'required' && (
        <p className={'error'} style={errorStyle}>
          *필수입력란입니다
        </p>
      )}
      {props.errortype === 'pattern' && (
        <p className={'error'} style={errorStyle}>
          {props.requirement?.pattern ? `*${props.requirement?.pattern}만 입력하실 수 있습니다` : '*비밀번호는 1자 이상의 특수문자를 포함해야 합니다'}
        </p>
      )}
      {props.errortype === 'maxLength' && (
        <p className={'error'} style={errorStyle}>
          *최대 {props.requirement?.maxLength}자 이내로 적어주세요
        </p>
      )}
      {props.errortype === 'minLength' && (
        <p className={'error'} style={errorStyle}>
          *최소 {props.requirement?.minLength}자 이상 적어주세요
        </p>
      )}
      {props.errortype === 'min' && (
        <p className={'error'} style={errorStyle}>
          *최소 {props.requirement?.min} 이상 되어야합니다
        </p>
      )}
      {props.errortype === 'max' && (
        <p className={'error'} style={errorStyle}>
          *최대 {props.requirement?.max}을 넘길 수 없습니다
        </p>
      )}

      {props.errortype === 'char' && <p className={'error'} style={errorStyle}></p>}
      {error && (
        <p className={'error'} style={errorStyle}>
          {error}
        </p>
      )}
    </div>
  );
});

export default Input;
